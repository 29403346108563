import React from 'react';
import { TFunction } from '@wix/yoshi-flow-editor';
import {
  TextButtonPriority as TEXT_BUTTON_PRIORITY,
  TextButton,
  Text,
  Accordion,
} from 'wix-ui-tpa';

import { classes } from './ProfileVisibility.st.css';
import { ProfilePrivacyModal } from '../../../../modals';
import { DataHook } from '../../../../../../../constants/DataHook';
import { ProfilePrivacyStatus, BiActionType } from '../../../../../../../types';
import { useBi } from '../../../../../../../hooks/useBi';
import { ModalContextType } from '../../../../../../../contexts/modals/Provider';

const PROFILE_VISIBILITY_ITEM_ID = 'profileVisibility';

type Props = {
  t: TFunction;
  showModal: ModalContextType['showModal'];
  privacyStatus: ProfilePrivacyStatus;
  biLogger: ReturnType<typeof useBi>;
};

export const getProfileVisibilityItem = ({
  t,
  showModal,
  privacyStatus,
  biLogger,
}: Props) => {
  const isProfilePublic = privacyStatus === ProfilePrivacyStatus.PUBLIC;

  const ctaText = isProfilePublic
    ? t('app.widget.change-privacy.make-private-cta')
    : t('app.widget.change-privacy.make-public-cta');

  const onChangeProfilePrivacyClick = () => {
    if (isProfilePublic) {
      biLogger.leaveCommunityAttempt();
    } else {
      biLogger.joinCommunityAttempt();
    }
    showModal(ProfilePrivacyModal);
  };

  const onAccordionItemClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Visibility_Settings);
  };

  return (
    <Accordion.Item
      id={PROFILE_VISIBILITY_ITEM_ID}
      title={t('app.widget.change-privacy.title')}
      onClick={onAccordionItemClick}
      data-hook={DataHook.VisibilityAccordionItem}
    >
      <Text className={classes.content} tagName="div">
        {t('app.widget.change-privacy.subtitle')}
      </Text>
      <TextButton
        className={classes.cta}
        priority={TEXT_BUTTON_PRIORITY.link}
        onClick={onChangeProfilePrivacyClick}
        data-hook={DataHook.ChangeProfilePrivacyButton}
      >
        {ctaText}
      </TextButton>
    </Accordion.Item>
  );
};
