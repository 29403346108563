import { ExtendedMap, ExtendedSet } from '.'
import { AppError } from '../logger'
import appContext from '../viewer-app-module/DataBindingAppContext'

export default class Dispatcher {
  constructor(context) {
    this.#context = context
  }

  dispatch(event, ...args) {
    return this.#eventToListener.has(event)
      ? this.#eventToListener.get(event).reduce(
          (acc, listener) => [
            ...acc,
            appContext.errorReporting(
              listener,
              AppError.with({
                message: `${event} listener failed`,
                options: { extra: this.#context },
              }),
            )(this.#context, ...args),
          ],
          [],
        )
      : undefined
  }

  subscribe(event, listener) {
    if (event.constructor === Object) {
      return Object.entries(event).reduce(
        (acc, [eventType, listener]) => [
          ...acc,
          this.subscribe(eventType, listener),
        ],
        [],
      )
    }

    this.#eventToListener.getOrDefault(event, new ExtendedSet()).add(listener)

    return () => this.unsubscribe(event, listener)
  }

  unsubscribe(event, listener) {
    return (
      this.#eventToListener.has(event) &&
      this.#eventToListener.get(event).delete(listener)
    )
  }

  #context
  #eventToListener = new ExtendedMap()
}
