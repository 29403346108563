import classNames from 'classnames'
import React from 'react'
import s from './divider.scss'
import {DividerProps} from '.'

export const Divider = ({width, height, alignmentClassName, onImage}: DividerProps) => (
  <div
    className={classNames(s[alignmentClassName], onImage ? s.onImage : s.offImage)}
    data-hook="divider"
    style={{width, height}}
  />
)
