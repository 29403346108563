import {getPricingOptionLabel} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {getFlexJustifyContentClass} from '../../../../alignment'
import {Tax} from '../../../parts/tax'
import {WixFee} from '../../../parts/wix-fee'
import {useCurrencyFormatter} from '../../../../../../../../../commons/hooks/currency'
import s from './pricing-option-price.scss'
import {PricingOptionsPriceProps} from './interfaces'

export const PricingOptionPrice = ({pricingOption, className, ticket, contentAlignment}: PricingOptionsPriceProps) => {
  const {isMobile} = useEnvironment()
  const {getFormattedMoney} = useCurrencyFormatter()
  const price = isMobile
    ? getPricingOptionLabel(ticket, pricingOption.id, getFormattedMoney)
    : getFormattedMoney(pricingOption.price)

  return (
    <div className={className} role="cell">
      <p className={s.price} data-hook="price">
        {price}
      </p>

      <div
        className={classNames({
          [s.mobileFeesContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} overridePrice={pricingOption.price.amount} />
        <WixFee ticket={ticket} overridePrice={pricingOption.price.amount} />
      </div>
    </div>
  )
}
