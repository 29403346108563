import React from 'react'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import settingsParams from '../../../../settingsParams'
import {PersonalAgendaHeader} from '../personal-agenda-header'
import {useWidgetProps} from '../../../hooks/widget-props'
import {useSettings} from '../../../../hooks/settings'
import s from './mobile-header.scss'
import {MobileHeaderProps} from '.'

export const MobileHeader = ({alignmentClass}: MobileHeaderProps) => {
  const {eventTitle, formattedEventDateAndLocation, installedApps, personalAgendaEnabled} = useWidgetProps()
  const settings = useSettings()
  const showDateAndLocation = settings.get(settingsParams.showDateAndLocation)

  return (
    <div className={alignmentClass}>
      {personalAgendaEnabled ? <PersonalAgendaHeader membersAreaInstalled={installedApps.membersArea} /> : null}
      <h1 className={s.titleMobile} data-hook={DH.SCHEDULE_TITLE}>
        {settings.get(settingsParams.scheduleTitleText)}
      </h1>
      {showDateAndLocation ? (
        <div className={s.dateLocationMobile}>
          <span data-hook={DH.EVENT_TITLE}>{`${eventTitle} | `}</span>
          <span>{formattedEventDateAndLocation}</span>
        </div>
      ) : null}
    </div>
  )
}
