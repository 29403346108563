import React from 'react'
import {Tooltip} from 'wix-ui-tpa'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import s from './limit-reached-tooltip.scss'
import {LimitReachedTooltipProps} from './interfaces'

export const LimitReachedTooltip: React.FC<LimitReachedTooltipProps> = ({children, maxTicketsPerReservation, show}) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  return (
    <Tooltip
      className={s.tooltip}
      disabled={!show}
      shown={show}
      placement={isMobile ? 'top' : 'right'}
      fluid={isMobile}
      moveBy={isMobile ? {x: 10, y: 0} : undefined}
      minWidth={isMobile ? 127 : 140}
      content={
        maxTicketsPerReservation === 1
          ? t('ticketLimitPerReservationReached_singular')
          : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
      }
    >
      {children}
    </Tooltip>
  )
}
