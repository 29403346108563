import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {getTicketDefinitionCurrency} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {InvoiceBreakdown} from '../../invoice-breakdown'
import {Modal} from '../modal'
import {Divider} from '../common/divider'
import {OrderSummaryProps} from './interfaces'
import s from './order-summary.scss'
import {Ticket} from './ticket'
import {Place} from './place'

export const OrderSummary = ({eventDate, eventLocation, eventTitle, tickets, seatingPlan}: OrderSummaryProps) => {
  const {t} = useTranslation()
  const hasSeats = tickets.some(ticket => ticket.seats?.placeIds.length)

  return (
    <Modal
      lastChildSelector={hookToAttributeSelector(DH.CLOSE_BUTTON)}
      firstChildSelector={hookToAttributeSelector(DH.CLOSE_BUTTON)}
      dataHook={DH.ORDER_SUMMARY_MODAL}
      returnFocusElement={hookToAttributeSelector(DH.SHOW_SUMMARY_BUTTON)}
    >
      <div className={s.title}>{t('mobile.couponForm.title')}</div>
      <div className={s.eventTitle}>{eventTitle}</div>
      <div className={s.secondaryText}>{eventDate}</div>
      <div className={s.secondaryText}>{eventLocation}</div>
      <Divider />
      <div>
        {hasSeats
          ? tickets.map(ticket =>
              ticket.seats.placeIds.map((seatId, seatIndex) => (
                <>
                  <Place
                    key={seatId}
                    seatId={seatId}
                    seatingPlan={seatingPlan}
                    priceOverride={ticket.seats.donations?.[seatIndex]}
                    pricingOptionId={ticket.seats.pricingOptionIds?.[seatIndex]}
                    currency={getTicketDefinitionCurrency(ticket)}
                    ticket={ticket}
                  />
                  <Divider />
                </>
              )),
            )
          : tickets.map(ticket => <Ticket key={ticket.id} ticket={ticket} t={t} />)}
      </div>
      <InvoiceBreakdown divider={<Divider />} />
    </Modal>
  )
}
