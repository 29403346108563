// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import React, {useEffect, useRef} from 'react';
import {useControllerProps} from '../../ControllerContext';

export const withResizeObserver = <T extends object>(Component: React.FunctionComponent<T>) => {
  // eslint-disable-next-line react/display-name
  return function ObservedCartApp(props: T) {
    const {updateLayout} = useControllerProps().host;
    const ref = useRef(null);
    useEffect(() => {
      const observer = new MutationObserver(() => updateLayout());
      observer.observe(ref.current, {childList: true, subtree: true});

      return () => observer.disconnect();
    }, [ref, updateLayout]);

    return (
      <div ref={ref}>
        <Component {...props} />
      </div>
    );
  };
};
