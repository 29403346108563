import {hasSeatingPlan} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isCheckoutButtonDisabled} from '../../../../selectors/event'
import {isPaidPlansVisible} from '../../../../selectors/paid-plans'
import {getButtonStyle} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {mapRuntime as ticketsPickerMapRuntime} from '../../../event-details/tickets-picker'
import {connect} from '../../../runtime-context/context'
import {MobileTicketsPickerRuntimeProps} from './interfaces'
import {MobileTicketsPicker as Presentation} from './mobile-tickets-picker'

export const mapRuntime = (appProps: DetailsPageAppProps): MobileTicketsPickerRuntimeProps => {
  const {
    state,
    actions: {selectTicket, openCheckoutUnavailable, checkout, openSeatingPlan},
  } = appProps
  const ticketPickerState = ticketsPickerMapRuntime(appProps)
  const componentSettings = state.component.settings

  return {
    ...ticketPickerState,
    showPaidPlans: isPaidPlansVisible(state),
    checkoutButtonDisabled: isCheckoutButtonDisabled(state),
    buttonStyle: getButtonStyle(componentSettings),
    withSeatingPlan: hasSeatingPlan(state.event),
    selectTicket,
    openCheckoutUnavailable,
    checkout,
    openSeatingPlan,
  }
}

export const MobileTicketsPicker = withTranslation()(
  connect<{}, MobileTicketsPickerRuntimeProps>(mapRuntime)(Presentation),
)

export * from './interfaces'
