import {getBorderWidth} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {Triangle as TrianglePresentation} from './triangle'

export interface TriangleOwnProps {
  direction: TriangleDirection
}

export interface TriangleRuntimeProps {
  borderWidth: number
}

export interface TriangleProps extends TriangleOwnProps, TriangleRuntimeProps {}

const mapRuntime = (context: AppProps) => ({
  borderWidth: getBorderWidth(context),
})

export enum TriangleDirection {
  left = 'left',
  right = 'right',
}

export const Triangle = connect<TriangleOwnProps, TriangleRuntimeProps>(mapRuntime)(TrianglePresentation)
