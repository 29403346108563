import {getRibbonData} from '../../selectors/events'
import {getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RibbonOwnProps, RibbonRuntimeProps} from './interfaces'
import {Ribbon as Presentation} from './ribbon'

const mapRuntime = ({state}: AppProps, {event}: RibbonOwnProps): RibbonRuntimeProps => ({
  ...getRibbonData(state, event),
  settings: getComponentConfig(state).settings,
})

export const Ribbon = connect<RibbonOwnProps, RibbonRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
