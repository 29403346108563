import {AnyAction} from 'redux'
import {GET_EVENTS} from '../actions/events'

const defaultState: boolean = false

export const loading = (state = defaultState, action: AnyAction): boolean => {
  switch (action.type) {
    case GET_EVENTS.REQUEST:
      return true
    case GET_EVENTS.SUCCESS:
    case GET_EVENTS.FAILURE:
      return false
    default:
      return state
  }
}
