import React from 'react'
import classNames from 'classnames'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getMoreOccurrencesCount, isLastAvailableOccurrence} from '../../../selectors/occurrences'
import cs from '../../classnames.scss'
import {usePageStateSelector} from '../../../hooks/state'
import s from './recurring-dates-count.scss'

export const RecurringDatesCount = () => {
  const count = usePageStateSelector(getMoreOccurrencesCount)
  const isLastOccurrence = usePageStateSelector(isLastAvailableOccurrence)
  const {t} = useTranslation()

  return (
    <div data-hook={DH.eventDetailsFullLocation} className={classNames(s.root, cs.evTextFont, cs.evTextColor)}>
      {isLastOccurrence ? t('recurring.lastAvailableDate') : t('recurring.moreDatesLabel', {count})}
    </div>
  )
}
