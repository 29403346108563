import { createAsyncThunk } from '@reduxjs/toolkit';

import { BlogPost, ThunkApiConfig } from '../../../types';
import { loadMoreBlogPosts } from '../../../server/profile.api';
import settingsParams from '../../../components/Profile/settingsParams';

interface FetchPostsResponse {
  pagingOffset: number;
  fetchedPostsCount: number;
  blogPosts: BlogPost[];
}

export const fetchPosts = createAsyncThunk<
  FetchPostsResponse,
  void,
  ThunkApiConfig
>('blog/fetchPosts', async (_, { getState, extra: { flowAPI } }) => {
  const { member, blogPosts } = getState();
  const { httpClient, environment, settings } = flowAPI;
  const postsPerPage = settings.get(settingsParams.postListPageSize);
  const pagingOffset = blogPosts.paging.offset + postsPerPage;

  const { data } = await httpClient.request(
    loadMoreBlogPosts({
      viewedMemberId: member.id!,
      postsPerPage,
      pagingOffset,
      language: environment.language,
      isMultilingualEnabled: environment.multilingual?.isEnabled ?? false,
    }),
  );

  return {
    pagingOffset,
    fetchedPostsCount: data.posts.length,
    blogPosts: data.posts,
  };
});
