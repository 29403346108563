import {withTranslation} from '@wix/yoshi-flow-editor'
import {getReferencedMonthName} from '../../../../selectors/calendar-layout'
import {AppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/connect'
import {MobileMonthlyCalendarOwnProps, MobileMonthlyCalendarRuntimeProps} from './interfaces'
import {MobileMonthlyCalendar as MobileMonthlyCalendarPresentation} from './mobile-monthly-calendar'

const mapRuntime = ({actions, state}: AppProps): MobileMonthlyCalendarRuntimeProps => ({
  fullMonthText: getReferencedMonthName(state),
  loading: state.calendarLayout.loading,
  resetCalendar: actions.resetCalendar,
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
})

export const MobileMonthlyCalendar = connect<MobileMonthlyCalendarOwnProps, MobileMonthlyCalendarRuntimeProps>(
  mapRuntime,
)(withTranslation()(MobileMonthlyCalendarPresentation))
export * from './interfaces'
