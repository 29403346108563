import {getExternalLink, isExternal, isRegistrationWithCustomDates} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isSecondRegistrationButtonVisible} from '../../../selectors/event'
import {getButtonStyle} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {SecondaryRsvpButtonOwnProps, SecondaryRsvpButtonRuntimeProps} from './interfaces'
import {SecondaryRsvpButton as Presentation} from './secondary-rsvp-button'

const mapRuntime = ({state}: DetailsPageAppProps): SecondaryRsvpButtonRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const visible = isSecondRegistrationButtonVisible(state)

  return {
    visible,
    componentSettings,
    buttonStyle: getButtonStyle(componentSettings),
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
    registrationWithCustomDates: isRegistrationWithCustomDates(event),
    eventId: event.id,
  }
}
export const SecondaryRsvpButton = withTranslation()(
  connect<SecondaryRsvpButtonOwnProps, SecondaryRsvpButtonRuntimeProps>(mapRuntime)(Presentation),
)

export * from './interfaces'
