import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { createStore } from '../../store';
import {
  getControllerProps,
  getInitialData,
  getSEODataWithProfileURL,
} from './controller-utils';
import {
  createSettingsListener,
  registerControllerListeners,
  registerUserListeners,
} from './controller-listeners';
import { Experiment } from '../../types';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { wixCodeApi, setProps },
}) => {
  const settingsListener = createSettingsListener();

  return {
    async pageReady() {
      const { initialState, seoData } = await getInitialData(flowAPI);
      const store = createStore({ flowAPI, wixCodeApi }, initialState);

      store.subscribe(() => setProps(store.getState()));
      setProps(getControllerProps(flowAPI, store));
      registerUserListeners(flowAPI, store);
      registerControllerListeners(flowAPI, settingsListener, store);

      const isRenderSEOTagsEnabled = flowAPI.experiments.enabled(
        Experiment.RenderSEOTags,
      );

      if (flowAPI.environment.isViewer && isRenderSEOTagsEnabled && seoData) {
        const itemData = await getSEODataWithProfileURL(wixCodeApi, seoData);
        wixCodeApi.seo.renderSEOTags({
          itemType: 'MEMBERS_AREA_PROFILE', // Take it from @wix/advanced-seo-utils when it's available
          itemData,
        });
      }
    },
    updateConfig(_, { publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});
    },
  };
};

export default createController;
