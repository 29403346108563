import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {isListLayout, isSingleEventWidget, getComponentConfig} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {EmptyState as EmptyStatePresentation} from './empty-state'

interface EmptyStateStateProps {
  singleEventWidget: boolean
  listLayout: boolean
}

export interface EmptyStateProps extends EmptyStateStateProps, WithTranslation {}

const mapRuntimeToProps = ({state}: AppProps): EmptyStateStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    singleEventWidget: isSingleEventWidget(componentConfig),
    listLayout: isListLayout(componentConfig),
  }
}

export const EmptyState = connect<{}, EmptyStateStateProps>(mapRuntimeToProps)(
  withTranslation()(EmptyStatePresentation),
)
