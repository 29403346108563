import React from 'react'
import {TextTypography, Text, Button, ButtonPriority, Dialog} from 'wix-ui-tpa'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {RsvpStatus} from '@wix/events-types'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useColors} from '../../../hooks/colors'
import s from './confirm-rsvp.scss'
import {classes} from './confirm-rsvp.st.css'
import {ConfirmRsvpProps} from './interfaces'

export const ConfirmRsvp = ({
  closeModal,
  noResponseEnabled,
  rsvpId,
  updateRsvpStatus,
  eventId,
  deleteRsvp,
  shouldNavigateToForm,
  navigateToForm,
  changeResponse,
}: ConfirmRsvpProps) => {
  const {t} = useTranslation()
  const {darkColor} = useColors()
  const {isMobile: mobile} = useEnvironment()

  const handleNoResponse = async () => {
    if (noResponseEnabled) {
      await updateRsvpStatus(eventId, rsvpId, RsvpStatus.NO)
    } else {
      await deleteRsvp(eventId, rsvpId)
    }
    closeModal()
  }

  const handleYesResponse = async () => {
    if (shouldNavigateToForm) {
      changeResponse(RsvpStatus.YES)
      navigateToForm()
    } else {
      await updateRsvpStatus(eventId, rsvpId, RsvpStatus.YES)
    }
    closeModal()
  }

  return (
    <Dialog
      data-hook={DH.CONFIRM_RSVP_MODAL}
      isOpen={true}
      onClose={() => closeModal()}
      closeButtonAriaLabel={t('a11y.close')}
      contentClassName={mobile ? s.mobile : s.desktopModal}
      notFullscreenOnMobile
    >
      <div className={s.content}>
        <Text typography={TextTypography.largeTitle} className={classes.titleText} data-hook={DH.MODAL_TITLE}>
          {t('viewer_modal.confirm-rsvp.title')}
        </Text>
        <div className={s.buttons}>
          <span className={s.secondaryButton}>
            <Button
              data-hook={DH.SECONDARY_BUTTON}
              upgrade={true}
              priority={ButtonPriority.secondary}
              fullWidth={mobile}
              className={classNames(
                darkColor ? classes.secondaryActionColors : classes.secondaryFallbackColors,
                s.secondaryButton,
              )}
              onClick={handleNoResponse}
            >
              {t('viewer_modal.confirm-rsvp.cancelButton')}
            </Button>
          </span>
          <span className={s.primaryButton}>
            <Button
              data-hook={DH.PRIMARY_BUTTON}
              upgrade={true}
              priority={ButtonPriority.primary}
              fullWidth={mobile}
              className={classNames(
                darkColor ? classes.primaryActionColors : classes.primaryFallbackColors,
                s.primaryButton,
              )}
              onClick={handleYesResponse}
            >
              {t('viewer_modal.confirm-rsvp.confirm')}
            </Button>
          </span>
        </div>
      </div>
    </Dialog>
  )
}
