import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {ShippingSectionText} from './ShippingSectionText';
import {getFormattedAddressTranslation} from '../../../addressUtils';
import {useLocaleKeys} from '../../../LocaleKeysProvider';

export enum PickupColumnDataHook {
  root = 'PickupColumnColumnDataHook.root',
  title = 'PickupColumnColumnDataHook.title',
  fullName = 'PickupColumnColumnDataHook.fullName',
  address = 'PickupColumnColumnDataHook.address',
  phone = 'PickupColumnColumnDataHook.phone',
}

export const PickupColumn = () => {
  const localeKeys = useLocaleKeys();
  const {pickupAddress, billingAddress, pickupTitle} = useControllerProps().thankYouPageStore;

  const {addressLine, city, country, zipCode} = pickupAddress;

  const addressData = getFormattedAddressTranslation({addressLine1: addressLine, country, city, zipCode}, localeKeys);

  return (
    <div data-hook={PickupColumnDataHook.root}>
      <ShippingSectionText dataHook={PickupColumnDataHook.title} text={pickupTitle} isTitle={true} />
      <ShippingSectionText dataHook={PickupColumnDataHook.address} text={addressData} />
      <ShippingSectionText dataHook={PickupColumnDataHook.phone} text={billingAddress?.phoneNumber} />
    </div>
  );
};
