import {createReducer} from '@reduxjs/toolkit'
import {checkMembersAreaInstallation} from '../actions/installed-apps'

export interface InstalledAppsState {
  checked: boolean
  membersArea: boolean
}

const initialState: InstalledAppsState = {
  checked: false,
  membersArea: false,
}

export const installedApps = createReducer(initialState, builder => {
  builder.addCase(checkMembersAreaInstallation.fulfilled, (state, {payload: membersAreaInstalled}) => {
    state.membersArea = membersAreaInstalled
    state.checked = true
  })
})
