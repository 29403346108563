import { TFunction } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Accordion, Text, TextButton } from 'wix-ui-tpa';

import { DataHook } from '../../../../../../../constants/DataHook';
import { useBi } from '../../../../../../../hooks';
import {
  BiActionType,
  MemberListItem,
  MY_ACCOUNT_REFERRAL,
  ProfilePrivacyStatus,
} from '../../../../../../../types';
import { BlockedMembersModal } from '../../../../modals/BlockedMembersModal/BlockedMembersModal';

import { classes } from './BlockedMembers.st.css';
import { ModalContextType } from '../../../../../../../contexts/modals/Provider';

const BLOCKED_MEMBERS_ITEM_ID = 'blockedMembers';

type Props = {
  t: TFunction;
  showModal: ModalContextType['showModal'];
  privacyStatus: ProfilePrivacyStatus;
  biLogger: ReturnType<typeof useBi>;
  blockedMembers: MemberListItem[];
};

export const getBlockedMembersItem = ({
  t,
  showModal,
  blockedMembers,
  biLogger,
}: Props) => {
  const onCtaClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Unblock_Selected_Members);
    showModal(BlockedMembersModal);
    biLogger.blockedMembersListOpen(blockedMembers.length);
  };

  return (
    <Accordion.Item
      id={BLOCKED_MEMBERS_ITEM_ID}
      data-hook={DataHook.BlockedMemberAccordionItem}
      title={t('app.widget.blocked-members.title')}
      onClick={() => {
        biLogger.ctaButtonClickedWithProps(
          BiActionType.Blocked_Members_Drawer,
          { formOrigin: MY_ACCOUNT_REFERRAL },
        );
      }}
    >
      {!!blockedMembers?.length && (
        <>
          <Text className={classes.content}>
            {t('app.widget.blocked-members.subtitle')}
          </Text>{' '}
          <TextButton
            onClick={onCtaClick}
            className={classes.content}
            data-hook={DataHook.BlockedMembersSeeAllButton}
          >
            {t('app.widget.blocked-members.unblock-list-cta')}
          </TextButton>
        </>
      )}
      {!blockedMembers?.length && (
        <Text className={classes.content}>
          {t('app.widget.blocked-members.empty-state')}
        </Text>
      )}
    </Accordion.Item>
  );
};
