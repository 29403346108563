import { WIX_BLOG } from '@wix/app-definition-ids';

import {
  ControllerProps,
  Experiment,
  FlowApi,
  Toast,
  WarmupDataKey,
  WixCodeApi,
} from '../../types';
import { RootState } from '../../store/root-reducer';
import { getActionHandlers, Store } from '../../store';
import * as profileApi from '../../server/profile.api';
import settingsParams from './settingsParams';
import { memberDataToSeoData } from '../../server/services/seo';

interface PublicRouterData {
  viewedUser?: { id: string };
}

const withLocaleHeadersFactory = (flowAPI: FlowApi) => {
  return <T>(requestOptions: T) => {
    const { multilingual } = flowAPI.environment;
    if (multilingual?.isEnabled) {
      const currentLanguage = multilingual.siteLanguages.find(
        (lang) => lang.languageCode === multilingual.currentLanguage,
      );
      if (currentLanguage) {
        const wixLinguistHeader = {
          'x-wix-linguist': `${currentLanguage.languageCode}|${currentLanguage.locale}|${currentLanguage.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`,
        };

        return {
          ...requestOptions,
          headers: { ...requestOptions, ...wixLinguistHeader },
        };
      }
    }

    return requestOptions;
  };
};

const getMemberIds = (flowApi: FlowApi) => {
  const { window, user } = flowApi.controllerConfig.wixCodeApi;
  const routerData = window.getRouterPublicData<PublicRouterData>();

  const currentMemberId = user.currentUser.loggedIn
    ? user.currentUser.id
    : null;

  const viewedMemberId = routerData?.viewedUser?.id ?? currentMemberId;

  return { currentMemberId, viewedMemberId };
};

const getCreateBlogPostUrl = async (flowAPI: FlowApi) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { url, relativeUrl } = await wixCodeApi.site.getSectionUrl({
    appDefinitionId: WIX_BLOG,
    sectionId: 'post',
  });

  return {
    url: `${url}/create-post`,
    relativeUrl: `${relativeUrl}/create-post`,
  };
};

const getSectionVisibilityProps = ({ settings }: FlowApi) => ({
  showAboutSection: settings.get(settingsParams.showAboutSection),
  showJoinDate: settings.get(settingsParams.showJoinDate),
  showBadgesSection: settings.get(settingsParams.showBadgesSection),
  showOverviewSection: settings.get(settingsParams.showOverviewSection),
  showBlogPostsSection: settings.get(settingsParams.showBlogPostsSection),
  showCommentsStats: settings.get(settingsParams.showCommentsStats),
  showLikesReceivedStats: settings.get(settingsParams.showLikesReceivedStats),
  showTopCommentsStats: settings.get(settingsParams.showTopCommentsStats),
});

const fetchInitialState = async (flowAPI: FlowApi) => {
  const withLocaleHeaders = withLocaleHeadersFactory(flowAPI);
  const { experiments } = flowAPI;
  const { language, multilingual, isRTL, isViewer } = flowAPI.environment;
  const { currentMemberId, viewedMemberId } = getMemberIds(flowAPI);
  const isRenderSEOTagsEnabled = experiments.enabled(Experiment.RenderSEOTags);
  const isGeneralSettingsEnabled =
    experiments.enabled(Experiment.EnableGeneralSettings) ||
    experiments.enabled(Experiment.EnableGeneralSettingsViewer);

  if (viewedMemberId) {
    const { data } = await flowAPI.httpClient.request(
      withLocaleHeaders(
        profileApi.getInitialData({
          ...(isViewer && getSectionVisibilityProps(flowAPI)),
          currentMemberId,
          viewedMemberId,
          postsPerPage: flowAPI.settings.get(settingsParams.postListPageSize),
          language,
          isRTL,
          isMultilingualEnabled: multilingual?.isEnabled ?? false,
          isRenderSEOTagsEnabled,
          isMemberPrivacySettingsEnabled: isGeneralSettingsEnabled,
        }),
      ),
    );

    return data;
  }

  return {
    member: {} as any,
    badges: [],
    site: {
      sameSessionMember: false,
      isForumInstalled: false,
      isBlogInstalled: false,
    },
    blogPosts: { paging: { count: 0, offset: 0, total: 0 } },
    seoData: null,
  };
};

export const getInitialData = async (
  flowAPI: FlowApi,
): Promise<{
  initialState: RootState;
  seoData: ReturnType<typeof memberDataToSeoData> | null;
}> => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { experiments } = flowAPI;

  let data;
  if (
    flowAPI.environment.isSSR &&
    experiments.enabled(Experiment.UseWarmupData)
  ) {
    data = await fetchInitialState(flowAPI);
    wixCodeApi.window.warmupData.set(WarmupDataKey.InitialData, data);
  } else {
    data =
      wixCodeApi.window.warmupData.get(WarmupDataKey.InitialData) ??
      (await fetchInitialState(flowAPI));
  }

  const createBlogPostUrl = data.site.isBlogInstalled
    ? await getCreateBlogPostUrl(flowAPI)
    : { url: '', relativeUrl: '' };

  return {
    initialState: {
      member: data.member,
      badges: data.badges,
      site: data.site,
      blogPosts: {
        ...data.blogPosts,
        createBlogPostUrl,
        arePostsLoading: false,
      },
      ui: {
        isPublicProfilePreview: false,
        toast: {
          type: Toast.None,
          isVisible: false,
        },
      },
      appSettings: {
        tabOpened: null,
        sectionState: null,
        sectionOpened: null,
      },
    },
    seoData: data.seoData,
  };
};

/**
 * Copy-pasted code from the @wix/ricos-common
 * Not used package by itself because it adds 30 kb to the bundle
 * https://github.com/wix-private/wix-ricos/blob/master/wix-ricos-common/src/utils/biDefaultParams.ts#L89
 */
const extractUoUBIParams = ({
  controllerConfig: { appParams, platformAPIs },
}: FlowApi) => {
  const loggerFactory = platformAPIs.biLoggerFactory?.() as any;
  const defaults = loggerFactory?.loggerClientFactory?._defaults;

  return {
    appId: appParams.appDefinitionId,
    bsi: defaults?.bsi?.(),
    instanceId: appParams.instanceId,
    metaSiteId: platformAPIs.bi?.metaSiteId,
    siteOwnerId: platformAPIs.bi?.ownerId,
    visitorId: platformAPIs.bi?.visitorId,
  };
};

export const getControllerProps = (
  flowAPI: FlowApi,
  store: Store,
): ControllerProps => ({
  ...store.getState(),
  ...getActionHandlers(store),
  ...(flowAPI.controllerConfig.platformAPIs.bi?.viewerName ===
    'thunderbolt' && { fitToContentHeight: true }),
  instance: flowAPI.controllerConfig.appParams.instance,
  defaultBIParams: extractUoUBIParams(flowAPI),
});

export const getSEODataWithProfileURL = async (
  wixCodeApi: WixCodeApi,
  seoData: ReturnType<typeof memberDataToSeoData>,
) => {
  const { location } = wixCodeApi;

  return {
    ...seoData,
    member: {
      ...seoData.member,
      profile: {
        ...seoData.member.profile,
        profilePageUrl: location.url,
      },
    },
  };
};
