export enum TAB {
  MAIN = 'main',
  MY_EVENTS = 'events',
  LAYOUT = 'layout',
  DISPLAY = 'display',
  TEXT = 'texts',
  DESIGN = 'design',
  PAGES = 'pages',
  SUPPORT = 'support',
  UPGRADE = 'upgrade',
  MEMBERS = 'members',
}

export enum PAGE {
  LANDING = 'landing',
}

export enum INNER_PAGE {
  DESIGN_TEXTS = 'design-texts',
  DESIGN_RIBBONS = 'design-ribbons',
  DESIGN_RIBBON = 'design-ribbon',
  DESIGN_BUTTONS = 'design-buttons',
  DESIGN_BACKGROUND = 'design-background',
  DESIGN_SPACES = 'design-spaces',
  DESIGN_HOVER = 'design-hover',
  DESIGN_CALENDAR_LIST_POPUP = 'design-calendar-list-popup',
  DESIGN_CALENDAR_DETAILS_POPUP = 'design-calendar-details-popup',

  DISPLAY_RIBBONS = 'display-ribbons',
  DISPLAY_RIBBON = 'display-ribbon',

  IMAGE = 'image',
  TEXT_RSVP = 'text-rsvp',
  TEXT_RSVP_CLOSED = 'text-rsvp-closed',
  TEXT_TIME_AND_LOCATION = 'text-time-and-location',
  TEXT_TODAY = 'text-today',
  TEXT_RIBBONS = 'text-ribbons',
  TEXT_RIBBON = 'text-ribbon',
  TEXT_BUTTON_RSVP_EVENT = 'text-button-rsvp-event',
  TEXT_BUTTON_TICKETED_EVENT = 'text-button-ticketed-event',
  TEXT_BUTTON_NO_REGISTRATION_EVENT = 'text-button-no-registration-event',
}

// TODO: Possible rewrite of INNER_PAGE type with TS 4+
// type INNER_PAGE_POSTFIX = `/${RIBBON}` | undefined
// type NEW_INNER_PAGE = `${INNER_PAGE}${INNER_PAGE_POSTFIX}`

export enum DETAILS_ROUTE {
  DETAILS = 'details',
  FORM = 'form',
  TICKET_FORM = 'ticket-form',
  CHECKOUT = 'checkout',
  PICK_TICKETS = 'pick-tickets',
  CHANGE_RSVP = 'change-rsvp',
  ORDER = 'order',
  ONLINE_CONFERENCING = 'oc',
  THANK_YOU_MESSAGES = 'thank-you-messages',
  LINK_EXPIRED = 'link-expired',
  NOT_FOUND = 'not-found',
  TEMPLATE_ORDER = 'template-order',
  ONLINE_CONFERENCING_PROVIDER_ERROR = 'provider-error',
  ONLINE_CONFERENCING_DISABLED = 'disabled',
}
