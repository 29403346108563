import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {WowImage, ImageResizeOptions} from 'wix-ui-tpa'
import {resolveFocalPoint} from '../../../../../commons/utils/image'
import s from './event-image.scss'
import {classes} from './event-image.st.css'
import {EventImageProps} from './interfaces'

export const EventImage = ({
  containerRef,
  alt,
  image,
  position,
  fitImage,
  className,
  doubleHeight,
}: EventImageProps) => {
  const focalPoint = resolveFocalPoint(position)

  return (
    <div className={classNames(s.outerContainer, className)} ref={containerRef}>
      <div className={classNames(s.imageContainer, s.sizing, {[s.double]: doubleHeight})} data-hook={DH.eventImage}>
        <WowImage
          className={classes.image}
          sourceWidth={image.width}
          sourceHeight={image.height}
          src={image.id}
          alt={alt}
          focalPoint={focalPoint}
          resize={fitImage ? ImageResizeOptions.contain : ImageResizeOptions.cover}
          shouldUseLQIP
          fluid
        />
      </div>
    </div>
  )
}
