import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ShowDetailsToggle} from '../../show-details-toggle'
import c from '../../classnames.scss'
import {MembershipOffersProps, MembershipOffersState} from './interfaces'
import s from './membership-offers.scss'
import {Plans} from './plans'

export class MembershipOffers extends React.Component<MembershipOffersProps, MembershipOffersState> {
  state = {expanded: false}

  componentDidMount() {
    const {plans, hasPurchasedPlans} = this.props

    if (plans?.length && hasPurchasedPlans) {
      this.toggleExpand()
    }
  }

  toggleExpand = () => this.setState({expanded: !this.state.expanded})

  componentDidUpdate(prevProps: MembershipOffersProps) {
    if (!prevProps?.plans.length && this.props.hasPurchasedPlans) {
      this.toggleExpand()
    }
  }

  render() {
    const {
      promptLogin,
      loggedIn,
      mainActionColorClass,
      mainActionBorderClass,
      mainActionRadiusClass,
      openMembershipPicker,
      plans,
      notPurchasedPlans,
      bestOfferDiscountPercent,
      allPlansPurchased,
      t,
      translatedDetails,
    } = this.props
    const {expanded} = this.state

    if (!plans.length) {
      return null
    }

    return (
      <div className={s.wrapper} data-hook={DH.MEMBERSHIP_OFFERS}>
        <div className={classNames(s.container, c.membershipOffersBorderColor)}>
          <div className={s.heading}>
            <div className={s.content}>
              <div className={s.primary}>{t('membership_offers')}</div>
              {bestOfferDiscountPercent && !allPlansPurchased && (
                <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PROMO}>
                  {plans.length > 1
                    ? t('membership_offer_promo_multiple', {percent: bestOfferDiscountPercent})
                    : t('membership_offer_promo', {percent: bestOfferDiscountPercent})}
                </div>
              )}
            </div>
            <ShowDetailsToggle className={s.expand} onClick={this.toggleExpand} expanded={expanded} />
          </div>
          {expanded && (
            <div data-hook={DH.MEMBERSHIP_OFFERS_DETAILS}>
              <Plans translatedDetails={translatedDetails} plans={plans} />
              {Boolean(notPurchasedPlans.length) && (
                <div className={classNames(s.actions, c.membershipOffersBorderColor)}>
                  {!loggedIn && (
                    <div className={s.login}>
                      {t('membership_login_text')}
                      <button
                        className={classNames(s.button, mainActionColorClass)}
                        onClick={() => promptLogin()}
                        data-hook={DH.MEMBERSHIP_OFFERS_LOGIN}
                      >
                        {t('membership_login')}
                      </button>
                    </div>
                  )}
                  <button
                    className={classNames(
                      s.mainAction,
                      mainActionColorClass,
                      mainActionBorderClass,
                      mainActionRadiusClass,
                    )}
                    onClick={() =>
                      openMembershipPicker({
                        titleText: t('membership_thankYou_title'),
                        contentText: t('membership_thankYou_content'),
                        buttonText: t('membership_thankYou_action'),
                      })
                    }
                    data-hook={DH.MEMBERSHIP_OFFERS_BUY}
                  >
                    {t('membership_button_buy')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
