import { find, get } from 'lodash'
import { Maybe } from '@wix/wix-code-adt'
import { isFieldReferencedOrNested } from './isFieldReferencedOrNested'
import {
  getReferenceFieldName,
  FIELD_PATH_SEPARATOR,
} from '@wix/dbsm-common/src/reference-fields/fieldPath'

export const getFieldByFieldName = (fields, fieldName) =>
  find(fields, { name: fieldName })

export const getFieldReferenceCollectionDisplayFieldByFieldName = (
  fields,
  fieldName,
) => {
  const field = getFieldByFieldName(fields, fieldName)
  return get(field, 'referencedCollectionDisplayField')
}

export const getReferencedCollectionName = (fields, fieldName) => {
  const field = getFieldByFieldName(fields, fieldName)
  return get(field, 'referencedCollection')
}

export const getFieldTypeByFieldName = (fields, fieldName) => {
  const field = getFieldByFieldName(fields, fieldName)
  return get(field, 'type')
}

export const getReferencingField = (fields, fieldName) => {
  if (!isFieldReferencedOrNested(fieldName)) {
    return Maybe.Nothing()
  }

  return Maybe.Just(getReferenceFieldName(fieldName)).map(parentFieldName =>
    getFieldByFieldName(fields, parentFieldName),
  )
}

export const getReferencedNestedRootFieldName = fieldPath =>
  getFieldParts(fieldPath).slice(0, 2).join(FIELD_PATH_SEPARATOR)

export const getNestedRootFieldName = fieldPath => getFieldParts(fieldPath)[0]

export const getFieldParts = fieldPath => fieldPath.split(FIELD_PATH_SEPARATOR)
