import {ScheduleStatus} from '@wix/ambassador-wix-events-agenda/types'
import {WidgetProps} from '../interfaces'
import {ScheduleItem} from '../types'

export const isEmptyStateVisible = (props: WidgetProps) => !hasScheduleItems(props)

export const isNoResultsStateVisible = (props: WidgetProps) => Object.keys(props?.days ?? {}).length === 0

export const hasScheduleItems = (props: WidgetProps) => props.scheduleTotal > 0

export const getScheduleItemDescription = (item: ScheduleItem) =>
  item.description && item.description !== '<p></p>' ? item.description : ''

export const isScheduleItemCanceled = (item: ScheduleItem) => item.status === ScheduleStatus.CANCELED

export const isScheduleItemWithIndication = (item: ScheduleItem) =>
  isScheduleItemCanceled(item) || item.formattedUpdatedDate
