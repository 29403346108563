import React, { ComponentType, useEffect } from 'react';
import {
  BaseComponentProps,
  IFrameEvent,
  InjectedGlobalSettings,
  InjectedHandlers,
  Nullable,
  PublicMember,
} from '../../types';

type WithDataSyncProps = Omit<BaseComponentProps<Nullable<PublicMember>>, 't'>;

type Options = {
  member: PublicMember;
  globalSettings: InjectedGlobalSettings;
};

type CreateMessagePayload<T extends IFrameEvent> = {
  [IFrameEvent.SetViewedMember]: [PublicMember] | PublicMember[];
  [IFrameEvent.SetGlobalSettings]: InjectedGlobalSettings;
  [IFrameEvent.EnterPublicProfilePreview]: never;
  [IFrameEvent.LeavePublicProfilePreview]: never;
  [IFrameEvent.UnfollowInState]: never;
  [IFrameEvent.FollowInState]: never;
  [IFrameEvent.SetMemberAsBlocked]: never;
}[T];

type FollowInStatePayload = {
  follower?: { uid?: string };
  followed?: { uid?: string };
};

type UnfollowInStatePayload = {
  unfollower?: { uid?: string };
  unfollowed?: { uid?: string };
};

enum FFChange {
  Increase = 1,
  Decrease = -1,
}

const reduxActionMessage = 'members_area.store_action';

const createMessage = <T extends IFrameEvent>(
  type: T,
  payload: CreateMessagePayload<T>,
) => {
  return JSON.stringify({
    type: reduxActionMessage,
    action: { type, payload },
  });
};

const getMessagePayload = (event: IFrameEvent, options: Options) => {
  if (event === IFrameEvent.SetViewedMember) {
    return [options.member];
  } else if (event === IFrameEvent.SetGlobalSettings) {
    return options.globalSettings;
  }
};

const emitEventsToFrames = (events: IFrameEvent[], options: Options) => {
  if (!parent?.frames?.length) {
    return;
  }

  events.forEach((event) => {
    const payload = getMessagePayload(event, options);
    const message = createMessage(event, payload!);

    for (let i = 0, l = parent.frames.length; i < l; i++) {
      parent.frames[i].postMessage(message, '*');
    }
  });
};

type WithDataSync = <Props extends WithDataSyncProps>(
  Component: ComponentType<Props>,
) => ComponentType<Props>;

const getFrameMessageData = (message: MessageEvent) => {
  try {
    return JSON.parse(message.data);
  } catch (e) {
    return {};
  }
};

const handleUnfollowInStore = (
  member: PublicMember,
  payload: UnfollowInStatePayload,
  handlers: InjectedHandlers,
) => {
  if (member.uid === payload.unfollower?.uid) {
    handlers.updateViewedMemberFollowingCount(FFChange.Decrease);
  } else if (member.uid === payload.unfollowed?.uid) {
    handlers.updateViewedMemberFollowerCount(FFChange.Decrease);
  }
};

const handleFollowInStore = (
  member: PublicMember,
  payload: FollowInStatePayload,
  handlers: InjectedHandlers,
) => {
  if (member.uid === payload.follower?.uid) {
    handlers.updateViewedMemberFollowingCount(FFChange.Increase);
  } else if (member.uid === payload.followed?.uid) {
    handlers.updateViewedMemberFollowerCount(FFChange.Increase);
  }
};

const getFrameEventListener =
  (handlers: InjectedHandlers, member: Nullable<PublicMember>) =>
  (message: MessageEvent) => {
    const { type, action } = getFrameMessageData(message);
    const payload = action?.payload ?? {};

    if (type !== reduxActionMessage || !member) {
      return;
    }

    const actionHandlersMap = {
      [IFrameEvent.FollowInState]: () =>
        handleFollowInStore(member, payload, handlers),
      [IFrameEvent.UnfollowInState]: () =>
        handleUnfollowInStore(member, payload, handlers),
      [IFrameEvent.SetGlobalSettings]: () =>
        handlers.patchGlobalSettingsInStore(payload),
    };

    const actionHandler =
      actionHandlersMap[action.type as keyof typeof actionHandlersMap];
    // eslint-disable-next-line no-unused-expressions
    actionHandler?.();
  };

const withDataSync: WithDataSync = (Component) => (props) => {
  const { iFrameEvents, member, globalSettings, handlers } = props;

  useEffect(() => {
    const frameEventListener = getFrameEventListener(handlers, member);
    window.addEventListener('message', frameEventListener);

    return () => {
      window.removeEventListener('message', frameEventListener);
    };
  }, [member, handlers]);

  useEffect(() => {
    if (!iFrameEvents?.length || !member) {
      return;
    }

    emitEventsToFrames(iFrameEvents, { member, globalSettings });
  }, [iFrameEvents, member, globalSettings]);

  return <Component {...props} />;
};

export default withDataSync;
