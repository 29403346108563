/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React, {useMemo} from 'react';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {classes, st} from '../BrowseMobile/BrowseMobile.st.css';
import s from './BrowseMobile.scss';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa';
import {FilterModel, FilterType} from '../../../types/galleryTypes';
import {CollectionFilterModel} from '../../../models/CollectionFilterModel';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export enum DataHook {
  BrowseDropdown = 'browse-dropdown-mobile',
}

export const BrowseMobile: React.FunctionComponent<IGalleryGlobalProps> = withGlobals(
  withTranslations()(
    ({
      globals: {filterModels, filterProducts, mainCollectionId, filterProductsOnMobile},
      t,
    }: IGalleryGlobalProps & IProvidedTranslationProps) => {
      const collectionFilter = filterModels.find(
        (filterModel: FilterModel) => filterModel.filterType === FilterType.COLLECTION
      ) as CollectionFilterModel;

      const allProductsCategory = useMemo(() => {
        return {
          isDisabled: false,
          isSelectable: true,
          id: mainCollectionId.toString(),
          value: t('allCollectionsFilterButtonText'),
        };
      }, [mainCollectionId, t]);

      const options: DropdownOptionProps[] = useMemo(() => {
        const curOptions = [allProductsCategory];
        return curOptions.concat(
          collectionFilter.options.map((option) => ({
            isDisabled: false,
            isSelectable: true,
            id: option.key,
            value: option.value,
          }))
        );
      }, [collectionFilter, allProductsCategory]);

      const handleCollectionChange = (e) => {
        const selectedCollectionId = e.id;
        filterProducts(collectionFilter.filterId, selectedCollectionId);
        filterProductsOnMobile();
      };

      return (
        <Dropdown
          mobileNativeSelect={true}
          data-hook={DataHook.BrowseDropdown}
          className={st(classes.browse, s.selector)}
          initialSelectedId={collectionFilter.activeOptions}
          onChange={handleCollectionChange}
          placement={'bottom'}
          options={options}
        />
      );
    }
  )
);
