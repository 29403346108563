import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import {
  BaseComponentProps,
  Nullable,
  PublicMember,
  DataHook,
} from '../../../types';
import withDataSync from '../../../hoc/DataSync/WithDataSync';
import forDevice from '../../../hoc/ForDevice/forDevice';
import MobileWidget from './MobileWidget';
import DesktopWidget from './DesktopWidget';
import SignUp from './SignUpWidget';

import './Widget.global.scss';

type WidgetProps = Omit<
  BaseComponentProps<Nullable<PublicMember>>,
  't' | 'i18n'
>;

const Widget = withDataSync(forDevice(MobileWidget, DesktopWidget));

const Wrapper: FC<WidgetProps> = (props) => {
  const { t, i18n } = useTranslation();
  // We may receive empty object as a props. Some bug in a platform.
  // https://wix.slack.com/archives/CSBGHP6TW/p1610727081000700
  // https://sentry.wixpress.com/sentry/profile-card-tpa-ooi/issues/40987061
  if (!props.site) {
    return null;
  }

  const { member, isCurrentUserAuthenticated, handlers, isRTL } = props;
  const hasSocialViewedMember = props.site.isSocial && member;
  const shouldShowPW = isCurrentUserAuthenticated || hasSocialViewedMember;

  return (
    <div data-hook={DataHook.WidgetWrapper} dir={isRTL ? 'rtl' : 'ltr'}>
      {shouldShowPW && member ? (
        <Widget {...props} t={t} member={member} i18n={i18n} />
      ) : (
        <SignUp isMobile={props.isMobile} t={t} onClick={handlers.signUp} />
      )}
    </div>
  );
};

export default Wrapper;
