/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './Browse.scss';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export enum DataHook {
  Title = 'browse-by-title',
  Content = 'browse-by-content',
  Container = 'browse-by-container',
}

export interface IBrowseProps extends IGalleryGlobalProps {
  children: React.ReactChild;
}

export const Browse: React.FunctionComponent<IBrowseProps & IGalleryGlobalProps> = withGlobals(
  ({children}: IBrowseProps & IGalleryGlobalProps) => {
    return (
      <div className={s.container} data-hook={DataHook.Container}>
        <h2 data-hook={DataHook.Title} className={s.title} id={'browse by header'}>
          {/*todo: add translations*/}
          {'Categories'}
        </h2>
        <div data-hook={DataHook.Content} className={s.content}>
          {children}
        </div>
      </div>
    );
  }
);
