import {useSettings as useYoshiSettings} from '@wix/tpa-settings/react'
import type {ISettingsSetter} from '@wix/tpa-settings'
import {useSettingsBi, WidgetType} from '../../../settings-commons/hooks/use-settings-bi'

export const useSettings = () => {
  const settings = useYoshiSettings()
  const {debouncedEditorEventsWidgetSettingChanged} = useSettingsBi(WidgetType.EventsMembersPage)

  const set: ISettingsSetter = (param, value) => {
    debouncedEditorEventsWidgetSettingChanged(param.key, value)
    settings.set(param, value)
  }

  return {
    ...settings,
    get: settings.get,
    set,
  }
}
