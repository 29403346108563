import React from 'react';
import {ICartItem} from '../../../../../../../../types/app.types';
import {CartItemDataHook} from '../../CartItem';
import s from '../../CartItem.scss';

export const TotalPrice = ({item}: {item: ICartItem}) => {
  return (
    <div className={s.totalPrice} data-hook={CartItemDataHook.TotalPrice}>
      {item.convertedPrices.formattedTotalPrice}
    </div>
  );
};
