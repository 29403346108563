import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MANAGE_FIELDS_EV_ID } from '../../components/MyAccount/manifest';
import { memberAccountBMPath } from '../constants';
import { refreshApp } from './public-api';

export function registerGfppEventListeners(editorSDK: FlowEditorSDK) {
  editorSDK.addEventListener('widgetGfppClicked', ({ detail }) => {
    if (detail.id === MANAGE_FIELDS_EV_ID) {
      editorSDK?.editor
        .openDashboardPanel('', {
          url: `${memberAccountBMPath}?referralInfo=my_account_gfpp_cta`,
          closeOtherPanels: false,
        })
        .then(() => refreshApp(editorSDK));
    }
  });
}
