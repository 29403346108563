import {Logger} from '@wix/bi-logger-ec-sf';
import {DependantApps} from '../../services/DependantApps';
import {EditorSDK} from '@wix/platform-editor-sdk';

export const showInstallNotification = async ({
  sdk,
  t,
  dependantApps,
  biLogger,
  installMembersArea,
}: {
  sdk: EditorSDK;
  t: (keyToTranslate: string) => string;
  dependantApps: DependantApps;
  biLogger: Logger;
  installMembersArea: boolean;
}) => {
  const linkClicked = await sdk.editor.showUserActionNotification('', {
    message: installMembersArea
      ? t('settings.productPage.floatingNotification.membersAreaAdded')
      : t('settings.productPage.floatingNotification.wishlistAdded'),
    type: sdk.editor.NotificationType.Success,
    link: {caption: t('settings.productPage.floatingNotification.previewWishlist')},
  });
  linkClicked && navigateToWishlist(sdk, dependantApps, biLogger);
};

async function navigateToWishlist(sdk: EditorSDK, dependantApps: DependantApps, biLogger: Logger) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.clickToPreviewWishlistInMembersSfe({});
  const wishlistPageRef = await dependantApps.getWishlistPageRef();
  return sdk.document.pages.navigateTo('', {pageRef: wishlistPageRef});
}
