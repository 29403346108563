import React from 'react';
import {Breadcrumbs} from './Breadcrumbs/Breadcrumbs';
import {withGlobalProps, ProvidedGlobalProps} from '../../providers/globalPropsProvider';
import {PaginationProps, Pagination} from './Pagination/Pagination';
import cx from 'classnames';
import s from './Navigation.scss';
import {LeftArrow} from '../../icons/dist';
import {ProductPagePaginationItem} from '../../types/app-types';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export interface NavigationProps {
  className?: string;
  type?: NavigationType;
  renderBreadcrumbs: boolean;
  renderPagination: boolean;
}

export const enum NavigationType {
  Short,
  Long,
  PaginationOnly,
}

export const enum NavigationDataHooks {
  ShortBreadcrumbs = 'short-breadcrumbs',
  Root = 'navigation',
}

@withGlobalProps
@withTranslations('globals.texts')
export class Navigation extends React.Component<NavigationProps & ProvidedGlobalProps & IProvidedTranslationProps> {
  public static defaultProps = {
    type: NavigationType.Long,
    renderBreadcrumbs: true,
    renderPagination: true,
  };

  private navigationProps(): PaginationProps {
    /* istanbul ignore next: todo: test */ const {
      t,
      globals: {
        navigate,
        pagination: {prevProduct = {} as ProductPagePaginationItem, nextProduct = {} as ProductPagePaginationItem},
      },
    } = this.props;

    return {
      prev: {
        text: t('BREADCRUMBS_PAGINATION_PREVIOUS'),
        href: prevProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(prevProduct.partialUrl);
        },
      },
      next: {
        text: t('BREADCRUMBS_PAGINATION_NEXT'),
        href: nextProduct.fullUrl,
        onClick: (e) => {
          e.preventDefault();
          navigate(nextProduct.partialUrl);
        },
      },
    };
  }

  private renderBack() {
    const {
      globals: {siteUrl, pagePath, hideNavigationUrls, isRTL},
      t,
    } = this.props;

    const previousPage = pagePath[pagePath.length - 2];
    /* istanbul ignore next */ let href = previousPage ? previousPage.url : siteUrl;
    /* istanbul ignore next */ const pageName = previousPage ? previousPage.name : t('BREADCRUMBS_HOME');
    const iconClass = cx({[s.shortBreadcrumbsRTL]: isRTL});

    if (hideNavigationUrls) {
      href = undefined;
    }

    return (
      <a href={href} data-hook={NavigationDataHooks.ShortBreadcrumbs} className={s.shortBreadcrumbs}>
        <LeftArrow className={iconClass} />
        <span>{t('BREADCRUMBS_BACK_TO_PAGE_LINK', {pageName})}</span>
      </a>
    );
  }

  private renderBreadcrumbs() {
    const {pagePath, navigateToLink, hideNavigationUrls} = this.props.globals;
    return <Breadcrumbs useHref={!hideNavigationUrls} path={pagePath} navigateToLink={navigateToLink} />;
  }

  private renderPagination() {
    const {next, prev} = this.navigationProps();
    return (next.href || prev.href) && <Pagination next={next} prev={prev} />;
  }

  public render(): JSX.Element {
    const {className, type, renderBreadcrumbs, renderPagination} = this.props;

    return (
      <nav data-hook={NavigationDataHooks.Root} className={cx(className, s.navigation)}>
        <div className={s.breadcrumbs}>
          {renderBreadcrumbs && (type === NavigationType.Short ? this.renderBack() : this.renderBreadcrumbs())}
        </div>
        {renderPagination && type === NavigationType.Long && this.renderPagination()}
      </nav>
    );
  }
}
