import {useEnvironment} from '@wix/yoshi-flow-editor'
import React from 'react'
import classNames from 'classnames'
import c from '../../../../classnames.scss'
import s from './expansion-button.scss'
import {ExpansionButtonProps} from './interfaces'

export const ExpansionButton: React.FC<ExpansionButtonProps> = ({
  children,
  className,
  dataHook,
  expanded,
  onChange,
  isHollowButton,
}) => {
  const {isMobile} = useEnvironment()

  const buttonClasses = `${isHollowButton ? c.readMoreHollowTextColor : c.readMoreTextColor} ${
    !isMobile ? s.link : s.mobileLink
  }`

  return (
    <button
      data-hook={dataHook}
      aria-expanded={expanded}
      className={classNames(buttonClasses, className)}
      onClick={() => onChange(!expanded)}
    >
      {children}
    </button>
  )
}
