import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { scroller, Element } from 'react-scroll';
import styles from './AddressInformation.scss';
import {
  SetCurbsideOutfitInfoPayload,
  SetCurbsidePayload,
  SetDeliveryProviderEstimatePayload,
  SetDineInTablePayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
  SetIsFetchingEstimateFromTPAPayload,
} from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import DispatchTypeSelector from '../DispatchTypeSelector';
import DispatchTimeSelector from '../DispatchTimeSelector';
import { ButtonPriority as PRIORITY, SectionNotification } from 'wix-ui-tpa';
import Error from 'wix-ui-icons-common/on-stage/Error';
import AddressInformationSummary from './AddressInformationSummary';
import CheckoutFlowStepTitle from '../CheckoutFlowStepTitle';
import RestaurantTakeoutDetails from './RestaurantTakeoutDetails';
import AddressInformationDelivery from './AddressInformationDelivery';
import Text from '../../core-components/Text';
import { Trans, useBi, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import {
  SetAddressInputErrorPayload,
  SetAddressInputValuePayload,
  SetErrorVisibilityPayload,
  SetTpaEstimateInlineErrorPayload,
  ToggleAllErrorsPayload,
  ValidateFormPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import Button from '../Button';
import {
  Address,
  AddressMismatchReason,
  calcEarnedPoints,
  CurbsideInfo,
  DispatchInfo,
  DispatchType,
  DisplayableOrderItem,
  getCurbsideInfo as restaurantCurbsideInfo,
  getIdealDeliveryArea,
  getLocationsWithDineIn,
  getLocationsWithTakeout,
  isAddress,
  isMinimumPriceMet,
  Restaurant,
  validateAddress,
  validateAddressForLocations,
  ValidateAddressReason,
  validateTakeout,
  VirtualDispatchType,
} from '@wix/restaurants-client-logic';
import { DeliveryFormField } from '../../../../state/addressInformationForm/addressForm.reducer';
import { getScrollOptions } from '../CheckoutFlow/CheckoutFlow';
import {
  calcDropoffTime,
  EstimateError,
  fetchEstimateDeliveryFee,
  getDisplayableAddressError,
  getErrorKey,
  getEstimateErrorText,
  getValidateAddressReasonText,
  InlineError,
  resolveEstimateRequestError,
} from './AddressInformation.helper';
import { Address as MembersAddress } from '@wix/ambassador-addresses-web/types';
import _ from 'lodash';
import SavedAddressView from './SavedAddressView';
import { OpenModalPayload, SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import { Modals } from '../../../../core/constants';
import Spinner from '../Spinner';
import Checkbox from '../Checkbox';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import { convertToOloAddressMembersAddress } from '../../../../core/logic/addressLogic';
import CurbsidePickup from '../CurbsidePickup';
import { useSettings } from '@wix/tpa-settings/react';
import { componentSettings } from '../../componentSettings';
import DineInDetails from '../DineInDetails/index';
import { DineInInfo } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import { PartialLocation } from '../../../../core/oloApi';
import { replaceSpecialChars } from '../../../../core/logic/replaceSpecialChars';
import useDispatchTimeSelectorProps from '../../../../core/hooks/useDispatchTimeSelectorProps';
import MemberLoginCTA from '../MemberLoginCTA';
import ChooseLocationDropdown from '../ChooseLocationDropdown';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/types';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { LocaldeliveryOrderError as OrderError } from '@wix/ambassador-restaurants-local-delivery/http';
import {
  addressInformationContinueValidationError,
  addressInformationContinue,
  dispatchSettingsUpdate,
  addToCartFailure,
  liveSiteMinimumOrderError,
  logInRequest,
  openAddressSelectionModal,
  checkboxSaveAddressForFutureOrders,
  locationPicked,
  pickupShowOnMapClick,
} from '@wix/bi-logger-olo-client/v2';

export interface AddressInformationProps {
  restaurant: Restaurant;
  dispatchTime: number;
  totalOrderPrice: number;
  subtotal: number;
  dispatchType: VirtualDispatchType;
  done?: boolean;
  collapsed?: boolean;
  forceErrorVisibility?: boolean;
  index?: string;
  onSubmit: (locationId?: string) => void;
  onEdit: () => void;
  setDispatchType: (dispatchType: SetDispatchTypePayload) => void;
  setDispatchTime: (payload: SetDispatchTimePayload) => void;
  setDispatchTimeCache: (payload: SetDispatchTimePayload) => void;
  address: Address;
  supportedDispatchTypes: Set<DispatchType>;
  supportedDispatchTypesV2: Set<DispatchType>;
  formattedAddressWithComment: string;
  fieldsErrors: {
    addressInput: boolean;
    apt: boolean;
    timingOption: boolean;
  };
  toggleAllErrors: (payload: ToggleAllErrorsPayload) => void;
  setDeliveryAddressFromForm: () => void;
  isMobile: boolean;
  selectedAddressOption: Address;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  addressInputError?: ValidateAddressReason;
  idealDeliveryArea?: DispatchInfo;
  errorOrderItem?: DisplayableOrderItem;
  saveStateToSessionStorage: () => void;
  timezone: string;
  errorsVisibility: Record<DeliveryFormField, boolean>;
  deliveryInfos: DispatchInfo[];
  describedby?: string;
  locale: string;
  isRTL?: boolean;
  savedAddresses: MembersAddress[];
  openModal: (payload: OpenModalPayload) => void;
  isLoadingAddressesFromServer?: boolean;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  initialCurbside?: boolean;
  initialTableNumber?: string;
  curbsideInfo?: CurbsideInfo;
  initialCurbsideOutfitInfo?: string;
  setCurbside: (isCurbside: SetCurbsidePayload) => void;
  setCurbsideOutfitInfo: (curbsideOutfitInfo: SetCurbsideOutfitInfoPayload) => void;
  validateForm: (dispatchType: ValidateFormPayload) => void;
  isAddressSelectionModalOpen?: boolean;
  locations: PartialLocation[];
  isUserLoggedIn?: boolean;
  setDineInTable: (payload: SetDineInTablePayload) => void;
  dineInInfo?: DineInInfo;
  isMAInstalled?: boolean;
  isRewardInstalled?: boolean;
  viewedFrom: 'multi-location-modal' | 'checkout';
  isMultiLocation: boolean;
  isLocationPicked: boolean;
  currentLocationId?: string;
  isCartEmpty: boolean;
  isLoyaltyEarnActive?: boolean;
  loggedInUserEmail?: string;
  customPointsName?: string;
  pointsBalance?: number;
  loginMember: () => void;
  modalOrigin?: OpenModalPayload;
  earningRules: EarningRule[];
  lockedDineInLocation?: PartialLocation;
  signedInstance?: string;
  setDeliveryProviderEstimate: (payload: SetDeliveryProviderEstimatePayload) => void;
  setIsFetchingEstimateFromTPA: (payload: SetIsFetchingEstimateFromTPAPayload) => void;
  removeDeliveryProviderFromState: () => void;
  tpaEstimateInlineError: InlineError | undefined;
  setTpaEstimateInlineError: (payload: SetTpaEstimateInlineErrorPayload) => void;
  isFetchingEstimateFromTPA?: boolean;
  setAddressInputValue: (payload: SetAddressInputValuePayload) => void;
  hasOnlinePayment: boolean;
  getCurrentPacingLevel: (payload: string) => void;
  orderPacingLevel?: number;
  isASAPDisabled?: boolean;
}

interface SubmitHandle {
  submit: () => void;
}

const AddressInformation: React.ForwardRefRenderFunction<SubmitHandle, AddressInformationProps> = (
  {
    setDispatchType,
    dispatchType,
    index = '0',
    done,
    collapsed,
    onEdit,
    address,
    onSubmit,
    restaurant,
    totalOrderPrice,
    subtotal,
    dispatchTime,
    supportedDispatchTypes,
    supportedDispatchTypesV2,
    setDispatchTime,
    formattedAddressWithComment,
    toggleAllErrors,
    setDeliveryAddressFromForm,
    isMobile,
    selectedAddressOption,
    setAddressInputError,
    setFieldError,
    initialTableNumber,
    addressInputError,
    fieldsErrors,
    idealDeliveryArea,
    errorOrderItem,
    saveStateToSessionStorage,
    setErrorVisibility,
    timezone,
    errorsVisibility,
    deliveryInfos,
    describedby,
    locale,
    savedAddresses,
    openModal,
    isLoadingAddressesFromServer,
    validateForm,
    saveAddressToServer,
    initialCurbside,
    curbsideInfo,
    initialCurbsideOutfitInfo,
    setCurbside,
    setCurbsideOutfitInfo,
    locations,
    isAddressSelectionModalOpen,
    isUserLoggedIn,
    setDineInTable,
    dineInInfo,
    isMAInstalled,
    isRewardInstalled,
    viewedFrom = 'checkout',
    isMultiLocation,
    isLocationPicked,
    currentLocationId,
    isCartEmpty,
    isLoyaltyEarnActive,
    loggedInUserEmail,
    customPointsName,
    pointsBalance,
    lockedDineInLocation,
    loginMember,
    modalOrigin,
    earningRules,
    signedInstance,
    setDeliveryProviderEstimate,
    setIsFetchingEstimateFromTPA,
    removeDeliveryProviderFromState,
    tpaEstimateInlineError,
    setTpaEstimateInlineError,
    isFetchingEstimateFromTPA,
    setAddressInputValue,
    hasOnlinePayment,
    getCurrentPacingLevel,
    setDispatchTimeCache,
    orderPacingLevel,
    isASAPDisabled = false,
  },
  ref,
) => {
  const [localCurbsideOutfitInfo, setLocalCurbsideOutfitInfo] = useState(initialCurbsideOutfitInfo);
  const [isDineInEmptyField, setIsDineInEmptyField] = useState(false);
  const [isDineInNoLocationPicked, setIsDineInNoLocationPicked] = useState(false);
  const [tableNumber, setTableNumber] = useState(initialTableNumber);
  const [isTakeoutUnavailable, setIsTakeoutUnavailable] = useState(false);
  const [shouldSaveAddress, setShouldSaveAddress] = useState(false);
  const [curbsideOutfitInfoError, setCurbsideOutfitInfoError] = useState(false);
  const [pickupTime, setPickupTime] = useState<number | undefined>(
    dispatchType === 'delivery' ? undefined : dispatchTime,
  );
  const [deliveryTime, setDeliveryTime] = useState<number | undefined>(
    dispatchType !== 'delivery' ? undefined : dispatchTime,
  );
  const [localDispatchType, setLocalDispatchType] = useState<VirtualDispatchType>(dispatchType);
  const [selectedLocationDelivery, setSelectedLocationDelivery] = useState<PartialLocation | undefined>(() =>
    getDefaultLocation(
      locations,
      dispatchType,
      isAddress(selectedAddressOption) ? selectedAddressOption : address,
      isLocationPicked,
      currentLocationId,
    ),
  );
  const [selectedLocationPickup, setSelectedLocationPickup] = useState<PartialLocation | undefined>(() =>
    getDefaultLocation(
      locations,
      dispatchType,
      isAddress(selectedAddressOption) ? selectedAddressOption : address,
      isLocationPicked,
      currentLocationId,
    ),
  );
  const [selectedLocationDineIn, setSelectedLocationDineIn] = useState<PartialLocation | undefined>(() =>
    getDefaultLocation(
      locations,
      dispatchType,
      isAddress(selectedAddressOption) ? selectedAddressOption : address,
      isLocationPicked,
      currentLocationId,
    ),
  );

  const getSelectedLocationByDispatch = (currentDispatchType = localDispatchType) => {
    switch (currentDispatchType) {
      case 'dine-in':
        return {
          selectedLocation: selectedLocationDineIn,
          setSelectedLocation: setSelectedLocationDineIn,
        };
      case 'delivery':
        return {
          selectedLocation: selectedLocationDelivery,
          setSelectedLocation: setSelectedLocationDelivery,
        };
      case 'takeout':
      default:
        return {
          selectedLocation: selectedLocationPickup,
          setSelectedLocation: setSelectedLocationPickup,
        };
    }
  };
  const { selectedLocation, setSelectedLocation } = getSelectedLocationByDispatch();

  const [isCurbsideToggledOn, onCurbsideToggle] = useState(initialCurbside && !!getCurbsideInfo());
  const localaddressInputError = localDispatchType === 'delivery' ? addressInputError : undefined;
  const experiments = useExperiments();
  const { t } = useTranslation();
  const location = useLocation();
  const settings = useSettings();
  const biLogger = useBi();

  const isValidSelected = getLocationsWithDineIn(locations).some(
    (loc) => loc.currentLocationId === selectedLocation?.currentLocationId,
  );
  useEffect(() => {
    if (
      viewedFrom === 'checkout' &&
      !isAddressSelectionModalOpen &&
      (errorsVisibility.addressInput || errorsVisibility.apt || errorsVisibility.timingOption)
    ) {
      scroller.scrollTo('address-input', getScrollOptions());
    }
  }, [errorsVisibility, isAddressSelectionModalOpen, viewedFrom]);

  const validationProps = {
    locations,
    selectedLocation,
    currentLocationId,
    setSelectedLocation,
    setAddressInputError,
    setErrorVisibility,
    localDispatchType,
    isMultiLocation,
  };

  /* We need this useEffect because the address can be changed from the AddressSelectionModal component */
  useEffect(() => {
    if (isMultiLocation && localDispatchType === 'delivery' && isAddress(selectedAddressOption)) {
      validateAddressWithLocations({ ...validationProps, addressToValidate: selectedAddressOption });
    }
  }, [selectedAddressOption]);

  useEffect(() => {
    if (isMultiLocation && localDispatchType === 'delivery') {
      setTpaEstimateInlineError({ value: undefined });
    }
  }, [deliveryTime, pickupTime, selectedLocation]);

  const localDispatchTime = localDispatchType === 'delivery' ? deliveryTime : pickupTime;

  const handlePickupTimeChange = useCallback(
    ({ selectedDateTime }) => {
      setPickupTime(selectedDateTime);

      if (viewedFrom === 'checkout') {
        setDispatchTime({ timestamp: selectedDateTime });
      }

      setFieldError({ error: 'timingOption', value: false });
    },
    [setPickupTime, setDispatchTime, setFieldError, viewedFrom],
  );

  const handleDeliveryTimeSelectorChange = useCallback(
    ({ selectedDateTime }) => {
      setDeliveryTime(selectedDateTime);

      if (viewedFrom === 'checkout') {
        setDispatchTime({ timestamp: selectedDateTime });
      }

      setFieldError({ error: 'timingOption', value: false });
    },
    [setDeliveryTime, setDispatchTime, setFieldError, viewedFrom],
  );
  const appendToElement =
    (viewedFrom === 'multi-location-modal' &&
      document.querySelector(`[data-hook='${dataHooks.addressInformationModal}']`)) ||
    undefined;

  const hasSavedAddresses = !_.isEmpty(savedAddresses);
  const timeError =
    fieldsErrors.timingOption && (isTakeoutUnavailable || localaddressInputError?.type === 'unavailable');
  const hasMembersAreaIntegration = settings.get(componentSettings.hasMembersAreaIntegration);
  const shouldShowMembersCTA = isMAInstalled && hasMembersAreaIntegration;
  const isMembersAddressEnabled = shouldShowMembersCTA && isUserLoggedIn;
  const idSuffix = Math.random();
  const addressInformationTitleId = `restaurants.address-information.title-${idSuffix}`;
  const currentAddress = selectedAddressOption;
  const addressForMinPrice = currentAddress;
  const dispatchTimeSelectorProps = useDispatchTimeSelectorProps(selectedLocation, currentAddress);

  // update the validity of the form
  useEffect(() => {
    validateForm({ dispatchType: localDispatchType, isMultiLocation });
  }, [errorsVisibility, localDispatchType, validateForm, isMultiLocation, currentAddress, tpaEstimateInlineError]);

  useEffect(() => {
    toggleAllErrors({ value: false });
  }, [location, selectedLocation, toggleAllErrors]);

  useEffect(() => {
    if (viewedFrom === 'multi-location-modal') {
      setTpaEstimateInlineError({ value: undefined });
    }
  }, [localDispatchTime]);

  const { displayableAmountLeft, minOrderPrice, displayableMinOrderPrice, isMinOrderPriceMet } =
    useMinOrderPriceDetails({
      location: selectedLocation,
      address: addressForMinPrice,
      dispatchTime: localDispatchTime,
      dispatchType: localDispatchType,
    });

  function getCurbsideInfo() {
    if (isMultiLocation && selectedLocation) {
      return restaurantCurbsideInfo(selectedLocation.deliveryInfos);
    } else {
      return curbsideInfo;
    }
  }

  function shouldShowCurbside() {
    if (isMultiLocation) {
      return localDispatchType === 'takeout' && !!getCurbsideInfo() && selectedLocation;
    } else {
      return localDispatchType === 'takeout' && !!getCurbsideInfo();
    }
  }

  function shouldShowMinPrice() {
    if (!selectedAddressOption?.formatted) {
      return false;
    }

    if (minOrderPrice === 0) {
      return false;
    }

    if (isMultiLocation && selectedLocation) {
      return true;
    } else if (!isMultiLocation) {
      return true;
    }
  }

  const setFieldErrorAndBi = ({ errorType }: { errorType: DeliveryFormField }) => {
    setFieldError({ error: errorType, value: true });
    biLogger.report(
      addressInformationContinueValidationError({
        locationGuid: selectedLocation?.currentLocationId,
        comment: selectedAddressOption?.comment || '',
        dispatchTime: localDispatchTime,
        dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
        dispatchType: localDispatchType,
        errorReason: errorType as string,
      }),
    );
  };

  const validateAddressInputAndRunSideEffects = (addressToValidate: Address | undefined) => {
    if (isMultiLocation && addressToValidate) {
      return validateAddressWithLocations({ ...validationProps, addressToValidate });
    } else {
      const validateAddressReason = validateAddress({
        address: addressToValidate,
        restaurant,
        dispatchTime: localDispatchTime,
        totalOrderPrice,
        deliveryPartnerProps: {
          deliveryPartnerFee: undefined,
          shouldConsiderDeliveryPartner: true,
        },
      });

      if (validateAddressReason?.type === 'unavailable') {
        setFieldErrorAndBi({ errorType: 'timingOption' });
      }

      setAddressInputError({ validateAddressReason });
      setErrorVisibility({ error: 'addressInput', value: true });
      biLogger.report(
        addressInformationContinueValidationError({
          locationGuid: selectedLocation?.currentLocationId,
          comment: addressToValidate?.comment || '',
          dispatchTime: localDispatchTime,
          dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
          dispatchType: localDispatchType,
          errorReason: validateAddressReason?.type as string,
        }),
      );

      return validateAddressReason;
    }
  };

  const submitDispatch = (configurationId?: string, dropoffDeliveryTime?: number) => {
    if (
      localDispatchType !== 'delivery' &&
      experiments.experiments.enabled('specs.restaurants.fix-it-reset-delivery-on-pickup-save')
    ) {
      setAddressInputValue({ value: '' });
    }

    setDeliveryAddressFromForm();

    if (shouldSaveAddress && selectedAddressOption) {
      saveAddressToServer({ address: convertToOloAddressMembersAddress(selectedAddressOption), setAsDefault: false });
    }

    setDispatchType({ dispatchType: localDispatchType });
    setDispatchTime({ timestamp: localDispatchTime });
    setDispatchTimeCache({ timestamp: localDispatchTime });
    onSubmit(selectedLocation?.currentLocationId);
    toggleAllErrors({ value: false });
    saveStateToSessionStorage();

    if (viewedFrom === 'checkout') {
      biLogger.report(
        addressInformationContinue({
          locationGuid: selectedLocation?.currentLocationId,
          comment: selectedAddressOption?.comment || '',
          dispatchTime: localDispatchTime,
          dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
          dispatchType: localDispatchType,
          curbsidePickupToggle: isCurbsideToggledOn && !!getCurbsideInfo(),
          curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
          contactlessDineInInputLabel: localDispatchType === 'dine-in' ? dineInInfo?.label : undefined,
          contactlessDineInUOUInput: localDispatchType === 'dine-in' ? tableNumber : undefined,
          isSaved: isUserLoggedIn,
        }),
      );
    } else if (viewedFrom === 'multi-location-modal') {
      biLogger.report(
        dispatchSettingsUpdate({
          locationGuid: selectedLocation?.currentLocationId,
          dispatchTime: localDispatchTime,
          dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
          dispatchType: localDispatchType,
          curbsidePickupToggle: isCurbsideToggledOn && !!getCurbsideInfo(),
          curbsideAdditionalInformationContent: localCurbsideOutfitInfo,
          contactlessDineInInputLabel: localDispatchType === 'dine-in' ? dineInInfo?.label : undefined,
          contactlessDineInUOUInput: localDispatchType === 'dine-in' ? tableNumber : undefined,
          validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
          configurationId,
          estimatedDeliveryTime: dropoffDeliveryTime,
        }),
      );
    }
  };

  const handleSubmitTakeout = () => {
    if (isMultiLocation && !selectedLocation) {
      setErrorVisibility({ error: 'location', value: true });
      return;
    }
    const { isValid: isTakeoutValid, reason: invalidTakeoutReason } = validateTakeout(
      isMultiLocation && selectedLocation ? selectedLocation : restaurant,
      localDispatchTime!,
    );

    if (!isTakeoutValid && invalidTakeoutReason === 'unavailable') {
      setIsTakeoutUnavailable(true);
      setFieldErrorAndBi({ errorType: 'timingOption' });
    }
    if (isTakeoutValid) {
      if (shouldShowCurbside()) {
        if (isCurbsideToggledOn && getCurbsideInfo()?.additionalInformationRequired && !localCurbsideOutfitInfo) {
          setCurbsideOutfitInfoError(true);
          setErrorVisibility({ error: 'curbsideAdditionalInfo', value: true });
          biLogger.report(
            addressInformationContinueValidationError({
              errorReason: 'Curbside Pickup',
              locationGuid: selectedLocation?.currentLocationId,
              pageName: viewedFrom === 'checkout' ? 'checkout' : 'dispatch settings',
            }),
          );
          return;
        }
      }

      submitDispatch();
      setCurbside({ isCurbside: isCurbsideToggledOn && !!getCurbsideInfo() });
      setCurbsideOutfitInfo({ curbsideOutfitInfo: localCurbsideOutfitInfo });
    }
  };

  const handleSubmitDelivery = async () => {
    const addressToValidate = selectedAddressOption;
    const validateAddressReason = validateAddressInputAndRunSideEffects(addressToValidate);
    let estimateTpaHasError = false;
    const isAptValid =
      Boolean(addressToValidate?.apt) ||
      Boolean(addressToValidate?.addressLine2) ||
      !experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery') ||
      viewedFrom === 'multi-location-modal';

    toggleAllErrors({ value: true });

    if (!isAptValid) {
      setFieldErrorAndBi({ errorType: isMembersAddressEnabled ? 'addressLine2' : 'apt' });
    }

    const configurationId = _.get(
      getIdealDeliveryArea({
        address: addressToValidate,
        dispatchTime: localDispatchTime,
        totalOrderPrice: subtotal,
        restaurant: isMultiLocation && selectedLocation ? (selectedLocation as Restaurant) : restaurant,
        deliveryPartnerProps: {
          deliveryPartnerFee: undefined,
          shouldConsiderDeliveryPartner: true,
        },
      }),
      'dispatchInfo.deliveryProviderInfo.configurationId',
      undefined,
    );

    if (configurationId && addressToValidate) {
      if (viewedFrom === 'multi-location-modal') {
        toggleAllErrors({ value: false });
      }
      const subdivision: string = addressToValidate.properties
        ? JSON.parse(addressToValidate.properties['com.wix.restaurants']).subdivision
        : 'NY';
      const validateResult = validateAddressForEstimateDelivery(addressToValidate, subdivision);
      if (!validateResult.isValid) {
        setAddressInputError({ validateAddressReason: { type: validateResult.addressError } });
        return setErrorVisibility({ value: true, error: 'addressInput' });
      }
      const { fee, estimateId, errors } = await fetchEstimateDeliveryFee({
        addressToValidate,
        subtotal,
        currency: restaurant.currency,
        idealDeliveryArea,
        configurationId,
        setIsFetchingEstimateFromTPA,
        signedInstance,
        localDispatchTime,
        experiments: experiments.experiments,
        hasOnlinePayment,
        biLogger,
        stage: viewedFrom,
        locationGuid: selectedLocation?.currentLocationId,
      });
      estimateTpaHasError = !!(errors && errors.length > 0);
      if (estimateTpaHasError) {
        handleEstimateReject(errors);
      }
      if (!estimateTpaHasError && fee !== undefined && estimateId && viewedFrom === 'checkout') {
        setDeliveryProviderEstimate({ estimateId, fee, configurationId });
      }
    }

    if (!configurationId) {
      removeDeliveryProviderFromState();
    }
    if (!validateAddressReason && isAptValid && !estimateTpaHasError) {
      const dropoffDeliveryTime = calcDropoffTime({
        dispatchInfo: idealDeliveryArea,
        futureOrderTime: localDispatchTime,
      });
      submitDispatch(configurationId, dropoffDeliveryTime);
    }
  };

  const handleEstimateReject = (errors?: OrderError[]) => {
    const configurationId = _.get(idealDeliveryArea, 'deliveryProviderInfo.configurationId', undefined);
    const estimateError: EstimateError = errors
      ? resolveEstimateRequestError(t, 'Our partner', viewedFrom, errors[0])
      : {
          addressError: 'unavailable',
        };
    if (estimateError.addressError) {
      setAddressInputError({
        validateAddressReason: {
          type: estimateError.addressError,
        },
      });
      setErrorVisibility({ error: 'addressInput', value: true });
    }
    if (estimateError.inlineError) {
      const errorReason = errors && errors[0].code;
      setTpaEstimateInlineError({ value: estimateError.inlineError });
      biLogger.report(
        addressInformationContinueValidationError({
          locationGuid: selectedLocation?.currentLocationId,
          comment: selectedAddressOption?.comment || '',
          dispatchTime: localDispatchTime,
          dispatchTimeOption: localDispatchTime ? 'later' : 'asap',
          dispatchType: localDispatchType,
          errorReason,
          configurationId,
          pageName: 'checkout',
        }),
      );
    }
    if (estimateError.modalError) {
      openModal({ modal: estimateError.modalError });
    }
  };

  const handleSubmitDineIn = () => {
    if (!tableNumber) {
      const isDineInLocationPicked =
        selectedLocation || (locations && getLocationsWithDineIn(locations)?.length === 1) || lockedDineInLocation;
      if (isMultiLocation && !isDineInLocationPicked) {
        setIsDineInNoLocationPicked(true);
      } else {
        setIsDineInEmptyField(true);
        setFieldErrorAndBi({ errorType: 'contactlessDineInInputLabel' });
      }
    } else {
      setDineInTable({ tableNumber });
      submitDispatch();
    }
  };

  const handleSubmit = () => {
    switch (localDispatchType) {
      case 'takeout':
        handleSubmitTakeout();
        break;
      case 'delivery':
        handleSubmitDelivery();
        break;
      case 'dine-in':
        handleSubmitDineIn();
        break;
      default:
        return;
    }
  };
  // used by AddressInformationModal
  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const setDispatchTypeAndBi = (dispatchTypePayload: SetDispatchTypePayload) => {
    // Evid: 830 - Checkout-> Dispatch update. (addToCartFailure it's a bad name)
    biLogger.report(
      addToCartFailure({
        lastState: dispatchTypePayload.dispatchType,
      }),
    );

    setLocalDispatchType(dispatchTypePayload.dispatchType);

    if (viewedFrom === 'checkout') {
      setDispatchType(dispatchTypePayload);
      if (dispatchTypePayload.dispatchType === 'delivery') {
        setDispatchTime({ timestamp: deliveryTime });
      }
      if (dispatchTypePayload.dispatchType === 'takeout') {
        setDispatchTime({ timestamp: pickupTime });
      }
    }

    // when moving between tabs we want to reselect the best location for that dispatch type
    if (isMultiLocation) {
      const { setSelectedLocation: newSetFunc } = getSelectedLocationByDispatch(dispatchTypePayload.dispatchType);
      const defaultLocation = getDefaultLocation(
        locations,
        dispatchTypePayload.dispatchType,
        isAddress(selectedAddressOption) ? selectedAddressOption : address,
        isLocationPicked,
        dispatchTypePayload.dispatchType === 'dine-in' && lockedDineInLocation
          ? lockedDineInLocation.currentLocationId
          : currentLocationId,
      );
      if (defaultLocation !== undefined) {
        newSetFunc(defaultLocation);
      }
    }

    const shouldSendMinOrderError = !isMinimumPriceMet({
      totalOrderPrice: subtotal,
      restaurant,
      dispatchType: dispatchTypePayload.dispatchType,
      selectedAddressOption,
      idealDeliveryArea,
      shouldConsiderDeliveryPartner: true,
    });

    if (shouldSendMinOrderError) {
      biLogger.report(
        liveSiteMinimumOrderError({
          locationGuid: selectedLocation?.currentLocationId,
          price: totalOrderPrice,
          minimumOrder: minOrderPrice,
          pageName: 'Checkout',
          dispatchType,
        }),
      );
    }
  };

  const shouldShowLocationsError = () => {
    return (
      !isCartEmpty && isMultiLocation && selectedLocation && selectedLocation.currentLocationId !== currentLocationId
    );
  };

  const renderMemberLoginCTA = () => {
    return (
      viewedFrom === 'multi-location-modal' &&
      shouldShowMembersCTA &&
      !isLoadingAddressesFromServer && (
        <MemberLoginCTA
          dataHook={dataHooks.addressInformationMembersLogin}
          isMobile={isMobile}
          isUserLoggedIn={isUserLoggedIn}
          onLoginClick={() => {
            loginMember();
            biLogger.report(logInRequest({ origin: 'address-modal' }));
          }}
          userEmail={loggedInUserEmail}
          onLogoutClick={() => openModal({ modal: Modals.LOGOUT_MODAL })}
          pointsToBeEarned={calcEarnedPoints({ rules: earningRules, moneyAmountInCents: subtotal })}
          customPointsName={customPointsName}
          pointsBalance={pointsBalance}
          isLoyaltyEarnActive={isLoyaltyEarnActive}
        />
      )
    );
  };

  const changeLocation = (id: string) => {
    setSelectedLocation(_.find(locations, { currentLocationId: id }));
    getCurrentPacingLevel(id);
  };

  const renderDelivery = () => {
    const getTpaAddressError = () => {
      if (
        localaddressInputError &&
        (localaddressInputError.type === 'invalid-dropoff-address' ||
          localaddressInputError.type === 'dropoff-address-not-serviceable')
      ) {
        return getValidateAddressReasonText(
          restaurant.currency,
          restaurant.locale,
          t,
          address.addressLine2 || '',
          localaddressInputError,
        );
      }
    };
    return (
      <React.Fragment>
        {isLoadingAddressesFromServer && (
          <div className={styles.spinner}>
            <Spinner data-hook={dataHooks.addressInformationSpinner} />
          </div>
        )}
        {!isLoadingAddressesFromServer && (!hasSavedAddresses || !isMembersAddressEnabled) && (
          <React.Fragment>
            <AddressInformationDelivery
              appendToElement={appendToElement}
              restaurant={restaurant}
              dispatchTime={localDispatchTime}
              totalOrderPrice={totalOrderPrice}
              onAddressInputBlur={validateAddressInputAndRunSideEffects}
              onAddressInputSelect={validateAddressInputAndRunSideEffects}
              showAddressLine2={isMembersAddressEnabled}
              className={minOrderPrice ? styles.addressInputMinPrice : ''}
              shouldDisplayAllInputs={viewedFrom === 'checkout'}
            />
            {shouldShowMinPrice() && (
              <div className={styles.deliveryMinOrderPrice}>
                <Text data-hook={dataHooks.addressInformationMinOrderPrice} typography="p2-s-secondary">
                  {t('Order_Online_MinimumOrder_Amount_Label', { amount: displayableMinOrderPrice })}
                </Text>
              </div>
            )}
          </React.Fragment>
        )}
        {!isLoadingAddressesFromServer && hasSavedAddresses && isMembersAddressEnabled && (
          <SavedAddressView
            address={currentAddress}
            onChange={() => {
              biLogger.report(openAddressSelectionModal({}));
              openModal({ modal: Modals.ADDRESS_SELECTION });
            }}
            error={
              getDisplayableAddressError({
                address: currentAddress,
                restaurant,
                dispatchTime: localDispatchTime,
                t,
                totalOrderPrice,
                isAptRequired: experiments.experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery'),
                isMultiLocation,
                locations,
              }) || getTpaAddressError()
            }
          />
        )}
        {!isLoadingAddressesFromServer && !hasSavedAddresses && isMembersAddressEnabled && (
          <Checkbox
            label={t('checkout_main_deliverymethod.saveaddress.text')}
            onChange={() => {
              const newValue = !shouldSaveAddress;
              biLogger.report(checkboxSaveAddressForFutureOrders({ value: newValue ? 'on' : 'off' }));
              setShouldSaveAddress(newValue);
            }}
            checked={shouldSaveAddress}
            data-hook={dataHooks.addressInformationSaveAddressCheckbox}
            name={dataHooks.addressInformationSaveAddressCheckbox}
            className={styles.checkbox}
          />
        )}
        {!isLoadingAddressesFromServer && isMultiLocation && (
          <ChooseLocationDropdown
            appendToElement={appendToElement}
            className={styles.locationPicker}
            labelText={t('order_settings_modal_choose_location_label')}
            filters="delivery"
            onChange={(id: string) => {
              changeLocation(id);
              biLogger.report(
                locationPicked({
                  dispatchType: 'delivery',
                  locationGuid: id,
                  pageName: modalOrigin?.context?.origin,
                  validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
                }),
              );
            }}
            location={selectedLocationDelivery?.currentLocationId}
          />
        )}
      </React.Fragment>
    );
  };

  const renderTakeout = () => {
    return (
      <React.Fragment>
        <RestaurantTakeoutDetails
          appendToElement={appendToElement}
          formattedAddressWithComment={formattedAddressWithComment}
          className={shouldShowMinPrice() || selectedLocation}
          onLocationChange={(id: string) => {
            changeLocation(id);
            setErrorVisibility({ error: 'location', value: false });
            biLogger.report(
              locationPicked({
                dispatchType: 'pickup',
                locationGuid: id,
                pageName: modalOrigin?.context?.origin,
                validationError: shouldShowLocationsError() ? 'location-changed' : undefined,
              }),
            );
          }}
          selectedLocation={selectedLocationPickup?.currentLocationId}
        />
        {(shouldShowMinPrice() || selectedLocation) && (
          <div className={styles.takeoutLocationInfo}>
            {shouldShowMinPrice() && (
              <Text data-hook={dataHooks.addressInformationMinOrderPrice} typography="p2-s-secondary">
                {t('Order_Online_MinimumOrder_Amount_Label', { amount: displayableMinOrderPrice })}
              </Text>
            )}
            {selectedLocation && (
              <TextButton
                className={styles.showMapLink}
                priority={TEXT_BUTTON_PRIORITY.link}
                onClick={() => {
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      selectedLocation.address.formatted,
                    )}`,
                  );
                  biLogger.report(
                    pickupShowOnMapClick({
                      locationGuid: currentLocationId,
                      pageName: viewedFrom === 'checkout' ? 'checkout' : 'dispatch settings',
                    }),
                  );
                }}
                data-hook={dataHooks.addressInformationShowMap}
              >
                {t('order_settings_modal_showmap_cta')}
              </TextButton>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  const onChangeTableNumber = (value: string) => {
    /* Validate that the tableNumber contains only letters and digits */
    const validValue = replaceSpecialChars(value);

    if (validValue !== tableNumber) {
      setIsDineInEmptyField(false);
      setTableNumber(validValue);
    }
  };

  const renderDineIn = () => {
    return (
      <DineInDetails
        tableNumber={tableNumber}
        appendToElement={appendToElement}
        onChangeTableNumber={onChangeTableNumber}
        shouldShowError={isDineInEmptyField}
        showNoLocatonPickedError={isDineInNoLocationPicked}
        onLocationChange={(id: string) => {
          setIsDineInNoLocationPicked(false);
          setIsDineInEmptyField(false);
          changeLocation(id);
        }}
        selectedLocation={
          lockedDineInLocation?.currentLocationId ||
          (isValidSelected ? selectedLocationDineIn?.currentLocationId : undefined)
        }
      />
    );
  };

  const shouldDisplayTimeSelector = () => {
    if (localDispatchType === 'dine-in') {
      return false;
    }

    if (isMultiLocation) {
      if (!selectedLocation) {
        return false;
      }

      if (localDispatchType === 'delivery') {
        return isAddress(currentAddress);
      }
    }

    return true;
  };

  const sdt = experiments.experiments.enabled('specs.restaurants.olo-client-dtl-v2')
    ? supportedDispatchTypesV2
    : supportedDispatchTypes;

  const onTimeChange = localDispatchType === 'delivery' ? handleDeliveryTimeSelectorChange : handlePickupTimeChange;
  return (
    <Element name="address-information">
      <div
        data-hook={dataHooks.addressInformation}
        className={!isMultiLocation ? styles.wrapper : ''}
        aria-labelledby={addressInformationTitleId}
        aria-describedby={describedby}
      >
        {viewedFrom === 'checkout' && (
          <CheckoutFlowStepTitle
            text={t('checkout_main_delivery_method')}
            done={done}
            collapsed={collapsed}
            index={index}
            onEdit={onEdit}
            editButtonDataHook={dataHooks.checkoutSummaryLineEditAddress}
            titleId={addressInformationTitleId}
          />
        )}

        {!done && !collapsed && sdt.size > 1 && (
          <DispatchTypeSelector
            className={styles.selector}
            dispatchType={localDispatchType}
            setDispatchType={setDispatchTypeAndBi}
            supportedDispatchTypes={sdt}
            isMobile={isMobile}
          />
        )}

        {!done && !collapsed && (
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              handleSubmit();
            }}
            data-hook={dataHooks.addressInformationForm}
          >
            {renderMemberLoginCTA()}
            {localDispatchType === 'delivery' && renderDelivery()}
            {localDispatchType === 'takeout' && renderTakeout()}
            {localDispatchType === 'dine-in' && renderDineIn()}

            {shouldDisplayTimeSelector() && (
              <DispatchTimeSelector
                {...dispatchTimeSelectorProps}
                dispatchTime={localDispatchTime}
                timingOption={localDispatchTime || isASAPDisabled ? 'later' : 'asap'}
                dispatchType={localDispatchType}
                onChange={onTimeChange}
                error={timeError ? t('checkout_main_delivery_time_errormessage') : undefined}
                isModal={viewedFrom === 'multi-location-modal'}
                locationId={selectedLocation?.currentLocationId}
              />
            )}
            {shouldShowCurbside() && (
              <CurbsidePickup
                setCurbsideOutfitInfo={(info: string) => {
                  setLocalCurbsideOutfitInfo(info);
                  setErrorVisibility({ error: 'curbsideAdditionalInfo', value: info === '' });
                }}
                curbsideOutfitInfo={localCurbsideOutfitInfo}
                isCurbsideOn={isCurbsideToggledOn}
                onCurbsideToggle={(isOn: boolean) => {
                  setErrorVisibility({
                    error: 'curbsideAdditionalInfo',
                    value: isOn && localCurbsideOutfitInfo === '',
                  });
                  onCurbsideToggle(isOn);
                }}
                curbsideInfo={getCurbsideInfo()}
                outfitInfoError={curbsideOutfitInfoError}
              />
            )}

            {errorOrderItem && getErrorKey(errorOrderItem) && (
              <SectionNotification type="error" className={styles.error} data-hook={dataHooks.addressInformationError}>
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  <Trans
                    t={t}
                    i18nKey={getErrorKey(errorOrderItem)}
                    components={[
                      <Link data-hook={dataHooks.addressInformationErrorLink} to="/cart">
                        placeholder
                      </Link>,
                    ]}
                  />
                </SectionNotification.Text>
              </SectionNotification>
            )}
            {tpaEstimateInlineError && localDispatchType === 'delivery' && (
              <SectionNotification
                type="error"
                className={styles.error}
                data-hook={dataHooks.addressInformationTpaInlineErrorBanner}
              >
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  {getEstimateErrorText({ tpaEstimateInlineError, t })}
                </SectionNotification.Text>
              </SectionNotification>
            )}
            {!isMinOrderPriceMet && viewedFrom === 'checkout' && (
              <SectionNotification
                type="error"
                className={styles.error}
                data-hook={dataHooks.addressInformationMinOrderPriceErrorBanner}
              >
                <SectionNotification.Icon icon={<Error />} />
                <SectionNotification.Text>
                  <Trans
                    t={t}
                    i18nKey="checkout_main_order_minprice_errormessage_with_link"
                    components={{
                      1: (
                        <Link data-hook={dataHooks.addressInformationMinOrderPriceErrorBannerLink} to="/">
                          placeholder
                        </Link>
                      ),
                    }}
                    values={{ amount: displayableAmountLeft }}
                  />
                </SectionNotification.Text>
              </SectionNotification>
            )}
            {shouldShowLocationsError() && (
              <SectionNotification
                className={styles.locationError}
                type="error"
                data-hook={dataHooks.addressInformationNotificationBar}
              >
                <SectionNotification.Icon data-hook="error-icon" icon={<Error />} />
                <SectionNotification.Text>{t('order_settings_modal_emtpy_cart_error')}</SectionNotification.Text>
              </SectionNotification>
            )}
            {viewedFrom === 'checkout' && (
              <Button
                upgrade
                fullWidth
                priority={PRIORITY.primary}
                className={styles.button}
                data-hook={dataHooks.addressInformationContinue}
                type="submit"
                disabled={!!errorOrderItem || !isMinOrderPriceMet}
                loading={isFetchingEstimateFromTPA}
              >
                <Text typography="p2-m-colorless">{t('checkout_main_button_continue')}</Text>
              </Button>
            )}
          </form>
        )}

        {done && (
          <AddressInformationSummary
            dineInInfo={dineInInfo}
            address={localDispatchType === 'delivery' ? address : restaurant.address}
            dispatchType={localDispatchType}
            dispatchTime={localDispatchTime}
            timezone={timezone}
            locale={locale}
            tableNumber={initialTableNumber}
            deliveryInfos={deliveryInfos}
            idealDeliveryArea={idealDeliveryArea}
            isCurbsideToggledOn={isCurbsideToggledOn}
            curbsideOutfitInfo={initialCurbsideOutfitInfo}
            curbsideInfo={curbsideInfo}
            orderPacingLevel={orderPacingLevel}
          />
        )}
      </div>
    </Element>
  );
};

AddressInformation.displayName = 'AddressInformation';

/**
 * @returns a location to be selected for the user by default
 * based on dispatch type, current location, if location was picked and the address
 */
function getDefaultLocation(
  locations: PartialLocation[],
  dispatchType: VirtualDispatchType,
  selectedAddressOption: Address,
  isLocationPicked: boolean,
  currentLocationId?: string,
) {
  if (dispatchType === 'delivery' && isAddress(selectedAddressOption)) {
    const validatedLocations = validateAddressForLocations(locations, selectedAddressOption, {
      deliveryPartnerFee: undefined,
      shouldConsiderDeliveryPartner: true,
    });
    if (validatedLocations.hasLocations && validatedLocations.locations) {
      return _.find(validatedLocations.locations, { currentLocationId }) || validatedLocations.locations[0];
    }
  } else if (dispatchType === 'takeout') {
    const takeoutLocations = getLocationsWithTakeout(locations);
    if (takeoutLocations.length === 1) {
      return takeoutLocations[0];
    } else if (isLocationPicked) {
      return _.find(takeoutLocations, { currentLocationId });
    }
  } else if (dispatchType === 'dine-in') {
    if (isLocationPicked) {
      return _.find(locations, { currentLocationId });
    }
  }
  return undefined;
}

interface ValidateAddressWithAllLocationsArgs {
  locations: PartialLocation[];
  addressToValidate: Address;
  selectedLocation?: PartialLocation;
  currentLocationId?: string;
  setSelectedLocation: (loc?: PartialLocation) => void;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  isMultiLocation: boolean;
  localDispatchType: VirtualDispatchType;
}

function validateAddressWithLocations({
  locations,
  addressToValidate,
  selectedLocation,
  currentLocationId,
  setSelectedLocation,
  setAddressInputError,
  setErrorVisibility,
}: ValidateAddressWithAllLocationsArgs) {
  const {
    hasLocations,
    locations: validLocations,
    reason,
  } = validateAddressForLocations(locations, addressToValidate, {
    shouldConsiderDeliveryPartner: true,
    deliveryPartnerFee: undefined,
  });
  setAddressInputError({ validateAddressReason: reason });

  if (hasLocations && validLocations) {
    // if the selected location is in the bestLocation array do nothing
    let aLocation = validLocations.find((loc) => loc.currentLocationId === selectedLocation?.currentLocationId);
    setErrorVisibility({ error: 'addressInput', value: false });
    setErrorVisibility({ error: 'location', value: false });
    setErrorVisibility({ error: 'timingOption', value: false });

    // else set the selected location to the currentLocationId(the location we are in) or the first best location
    if (!aLocation) {
      aLocation = validLocations.find((loc) => loc.currentLocationId === currentLocationId) || validLocations[0];
      setSelectedLocation(aLocation);
      setErrorVisibility({ error: 'location', value: false });
      return;
    }
  } else if (reason) {
    setErrorVisibility({ error: 'addressInput', value: true });
    return reason;
  }
}

function validateAddressForEstimateDelivery(
  address: Address,
  subdivision: string,
): { isValid: false; addressError: AddressMismatchReason } | { isValid: true } {
  const isCityValid = !_.isEmpty(address.city);
  const isSubdivisionVaild = !_.isEmpty(subdivision);
  const isStreetValid = !_.isEmpty(address.street);
  const isPostalCodeValid = !_.isEmpty(address.postalCode);

  if (!(isCityValid && isSubdivisionVaild && isStreetValid && isPostalCodeValid)) {
    return {
      isValid: false,
      addressError: 'invalid-dropoff-address',
    };
  }
  return { isValid: true };
}
export default forwardRef(AddressInformation);
