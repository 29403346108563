import {IDimensions} from '@wix/yoshi-flow-editor'
import {
  hasGuestsGoing,
  isInternal,
  isRsvp,
  DATE_SIZE,
  IMAGE_RATIO,
  EVENT_FILTER_TYPE,
} from '@wix/wix-events-commons-statics'
import {isEditor, isFullWidth, isMobile, isSite} from '../../../../commons/selectors/environment'
import {MEDIUM_WIDTH, MOBILE_WIDTH} from '../components/list-layout/constants'
import {State} from '../types/state'
import {
  isCalendarLayout,
  isListAdditionalInfoEnabled,
  isListDateEnabled,
  isListDescriptionEnabled,
  isListFullDateEnabled,
  isListImageEnabled,
  isListLayout,
  isListLocationEnabled,
  isListMainTitleEnabled,
  isListMembersElementEnabled,
  isListSocialShareEnabled,
  isListVenueNameEnabled,
  isResponsive,
  getComponentConfig,
  getMultiImageRatio,
} from './settings'
import {getEventById, getEvents, getRealEvents} from './events'

// TODO: check - seems that these are duplicate selectors

export const isNarrow = (state: State, dimensions: IDimensions) => {
  if (isResponsive(getComponentConfig(state))) {
    return isMobile(state)
  }
  return !isFullWidth(state, dimensions) && dimensions.width < MOBILE_WIDTH
}

export const isMainTitleVisible = (state: State) => isListMainTitleEnabled(getComponentConfig(state), isMobile(state))

export const isDateVisible = (state: State) => isListDateEnabled(getComponentConfig(state), isMobile(state))

export const isVenueVisible = (state: State, dimensions: IDimensions) => {
  const componentConfig = getComponentConfig(state)
  const shouldVenueShow = isListVenueNameEnabled(componentConfig)

  if (isListLayout(componentConfig) && !isResponsive(componentConfig)) {
    return shouldVenueShow && (dimensions.width > MEDIUM_WIDTH || isFullWidth(state, dimensions))
  }

  return shouldVenueShow
}

export const isFullDateVisible = (state: State) => isListFullDateEnabled(getComponentConfig(state), isMobile(state))

export const isLocationVisible = (state: State) => isListLocationEnabled(getComponentConfig(state), isMobile(state))

export const isDescriptionVisible = (state: State) =>
  isListDescriptionEnabled(getComponentConfig(state), isMobile(state))

export const isSocialShareVisible = (state: State) =>
  isListSocialShareEnabled(getComponentConfig(state), isMobile(state))

export const isImageVisible = (state: State) => isListImageEnabled(getComponentConfig(state), isMobile(state))

export const getDateOnImageSize = (state: State) => {
  if (isMobile(state)) {
    const componentSettings = getComponentConfig(state).settings
    const dateOnImageSizeMobile = componentSettings['dateOnImageSize▶︎m'] ?? componentSettings.dateOnImageSizeMobile
    return dateOnImageSizeMobile || DATE_SIZE.SMALL
  }

  return DATE_SIZE.SMALL
}

export const isMembersEnabled = (state: State) => {
  const mobile = isMobile(state)
  const componentConfig = getComponentConfig(state)
  return (
    state.membersEnabled && (isListMembersElementEnabled(componentConfig, mobile) || isMembersVisibleInEditor(state))
  )
}

export const isMembersVisible = (state: State, event: ExtendedEvent) =>
  isMembersEnabled(state) &&
  isInternal(event) &&
  (hasGuestsGoing(event.totalGuests, isRsvp(event)) || isMembersVisibleInEditor(state))

export const isMembersVisibleInEditor = (state: State) => {
  const mobile = isMobile(state)
  const componentConfig = getComponentConfig(state)
  return isEditor(state) && isListMembersElementEnabled(componentConfig, mobile)
}

export const isAdditionalComponentsHidden = (state: State, eventId?: string) => {
  const descriptionVisible = eventId
    ? isDescriptionVisible(state) && !!getEventById(state, eventId).description
    : isDescriptionVisible(state)
  const mobile = isMobile(state)

  return (
    !isListAdditionalInfoEnabled(getComponentConfig(state), mobile) ||
    (!isFullDateVisible(state) && !isLocationVisible(state) && !descriptionVisible && !isSocialShareVisible(state))
  )
}

export const isSquareImage = (state: State, mobile = false): boolean => {
  const componentConfig = getComponentConfig(state)
  return getMultiImageRatio(componentConfig, mobile) === IMAGE_RATIO['1:1']
}

export const getImageMargins = (state: State, dimensions: IDimensions, rtl: boolean) =>
  isNarrow(state, dimensions) ? {marginBottom: 20} : {[rtl ? 'marginLeft' : 'marginRight']: 22}

export const isEmptyStateNotificationVisible = (state: State) => {
  if (
    !isCalendarLayout(state.component) &&
    state.events.filterType === EVENT_FILTER_TYPE.MANUAL &&
    !getRealEvents(state).length &&
    !isSite(state)
  ) {
    return true
  }

  return !isCalendarLayout(state.component) && !getEvents(state).length && isSite(state)
}
