import {State} from '../types/state'
import {isCalendarLayout, getComponentConfig} from './settings'
import {getFirstEvent} from './events'

export const isItemOpened = (state: State, eventId: string) => state.layout.listItemOpened === eventId

export const getForcedRibbon = (state: State) => state.layout.forceShowRibbon

export const shouldForceShowRibbon = (state: State, event: wix.events.Event) =>
  !!getForcedRibbon(state) && (getFirstEvent(state)?.id === event.id || isCalendarLayout(getComponentConfig(state)))
