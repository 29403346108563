import s from './Hero.scss';
import React, {useEffect, useState} from 'react';
import {Image, ImageResizeOptions, Text, TextButton, TextButtonPriority, TextTypography} from 'wix-ui-tpa';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';
import {HeadingTags} from '@wix/wixstores-client-core/dist/es/src/types/heading-tags';

export enum HeroDataHook {
  Root = 'HeroDataHook.Root',
  HeroImage = 'HeroDataHook.HeroImage',
  CategoryName = 'HeroDataHook.CategoryName',
  Description = 'HeroDataHook.Description',
  ReadMoreButton = 'HeroDataHook.ReadMoreButton',
}

export const Hero: React.FunctionComponent<IGalleryGlobalProps> = withGlobals((props: IGalleryGlobalProps) => {
  const {categoryImage, categoryDescription, categoryName} = props.globals;
  const inlineDescription = false;

  const descriptionRef = React.createRef<any>();
  const [shouldClampDescription, setShouldClampDescription] = useState<boolean>(true);

  useEffect(() => {
    const isClamped = descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight;
    setShouldClampDescription(isClamped);
  }, [descriptionRef]);

  return (
    <div className={classNames(s.root, {[s.inline]: inlineDescription})} data-hook={HeroDataHook.Root}>
      {categoryImage && (
        <div className={s.imageContainer}>
          <Image data-hook={HeroDataHook.HeroImage} src={categoryImage} resize={ImageResizeOptions.cover} fluid />
        </div>
      )}
      {categoryDescription && (
        <div className={s.descriptionContainer}>
          <Text
            tagName={HeadingTags.H1}
            typography={TextTypography.smallTitle}
            className={s.categoryName}
            data-hook={HeroDataHook.CategoryName}>
            {categoryName}
          </Text>

          <Text className={s.description}>
            <span
              ref={descriptionRef}
              className={classNames({[s.limitDescriptionLines]: shouldClampDescription})}
              data-hook={HeroDataHook.Description}>
              {categoryDescription}
            </span>
            {shouldClampDescription && (
              <TextButton
                priority={TextButtonPriority.link}
                onClick={
                  /* istanbul ignore next: cant test with jsdom, will be tested by sled */
                  () => setShouldClampDescription(false)
                }
                data-hook={HeroDataHook.ReadMoreButton}>
                Read More
              </TextButton>
            )}
          </Text>
        </div>
      )}
    </div>
  );
});
