import {
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_ROLE,
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_RANGE_SLIDER_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
} from './connection-config/roles'

export const USER_INPUT_FILTER_ROLES = [
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_CHECKBOX_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
  USER_INPUT_FILTER_RANGE_SLIDER_ROLE,
]
