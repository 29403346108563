import React, { FC } from 'react';

import { InjectedClassNameProps } from '../../../types';

interface LimitCounterProps extends InjectedClassNameProps {
  limit: number;
  length: number;
}

export const LimitCounter: FC<LimitCounterProps> = ({
  className,
  limit,
  length,
}) => (
  <div className={className}>
    {length}/{limit}
  </div>
);
