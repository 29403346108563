import {WidgetSettingsState} from '../reducers'

export const isEventsInMembersInstalled = (state: WidgetSettingsState) => state.installedApps.eventsInMembersInstalled

export const wasEventsInMembersInstalled = (state: WidgetSettingsState) =>
  state.installedApps.wasEventsInMembersInstalled

export const isMembersInstalled = (state: WidgetSettingsState) => state.installedApps.members

export const isPaidPlansInstalled = (state: WidgetSettingsState) => state.installedApps.paidPlans
