import React, { FC } from 'react';

import { BaseComponentProps, ProfileLayout } from '../../../../types';
import DesktopResponsiveWidget from './DesktopResponsiveWidget';
import DesktopNonResponsiveWidget from './DesktopNonResponsiveWidget';

const DesktopWidget: FC<BaseComponentProps> = (props) => {
  return props.computed.profileLayout === ProfileLayout.FullWidth ? (
    <DesktopResponsiveWidget {...props} />
  ) : (
    <DesktopNonResponsiveWidget {...props} />
  );
};

export default DesktopWidget;
