/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import s from './ColorFilter.scss';

import {IFilterOption} from '../../../types/galleryTypes';
import {
  ColorSampleGroup,
  IColorSampleGroupOption,
} from '@wix/wixstores-client-common-components/dist/es/src/ColorSampleGroup/ColorSampleGroup';
import _ from 'lodash';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface IColorFilterProps extends IGalleryGlobalProps {
  colorOptions: IFilterOption[];
  showOptionName?: Function;
  filterId: number;
  selected: string[];
}

class ColorFilterComp extends React.Component<IColorFilterProps> {
  public shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.selected, nextProps.selected);
  }

  public render() {
    const {colorOptions, selected: controllerSelected} = this.props;
    const {shouldShowMobile} = this.props.globals;
    const options: IColorSampleGroupOption[] = colorOptions.map((option, i) => ({
      id: i,
      value: option.key,
      description: option.value,
    }));

    const selected = options.filter((option) => controllerSelected.includes(option.value));
    return (
      <div className={s.colorList}>
        <ColorSampleGroup
          selected={selected as any}
          options={options}
          onMouseLeave={() => this.props.showOptionName('')}
          onMouseEnter={(value) => this.props.showOptionName(value)}
          onSelect={this.handleClick}
          allowMultiple={true}
          shouldFocus={!shouldShowMobile}
        />
      </div>
    );
  }

  private readonly handleClick = (selections: IColorSampleGroupOption[]) => {
    const selectedValue = _.xor(
      selections.map((option) => option.value),
      this.props.selected
    );
    this.props.globals.filterProducts(this.props.filterId, selectedValue[0]);
  };
}

export const ColorFilter = withGlobals(ColorFilterComp);
