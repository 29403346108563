import React, { FC } from 'react';
import {
  Popover as TPAPopover,
  PopoverPlacement as Placement,
  Text,
} from 'wix-ui-tpa';

import { classes } from './Popover.st.css';

type Props = {
  placement?: Placement;
  popoverContent: string;
  className?: string;
};

export const Popover: FC<Props> = ({
  children,
  popoverContent,
  className,
  placement = 'top',
}) => {
  const [shown, setShown] = React.useState(false);
  const show = () => setShown(true);
  const hide = () => setShown(false);

  return (
    <div className={`${classes.root} ${className ?? ''}`}>
      <TPAPopover
        placement={placement}
        className={classes.popover}
        shown={shown}
      >
        <TPAPopover.Element>
          <div
            onFocus={(e) => {
              // We need to not show content popover if focus is not triggered by keyboard focus
              // To do so we check secondary target (relatedTarget)
              if (e.relatedTarget) {
                show();
              }
            }}
            onBlur={hide}
            onMouseEnter={show}
            onMouseLeave={hide}
            onClick={hide}
          >
            {children}
          </div>
        </TPAPopover.Element>
        <TPAPopover.Content>
          <Text className={classes.popoverContent}>{popoverContent}</Text>
        </TPAPopover.Content>
      </TPAPopover>
    </div>
  );
};
