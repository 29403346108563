const mapValues = require('lodash/mapValues') // eslint-disable-line lodash/import-scope
const mapKeys = require('lodash/mapKeys') // eslint-disable-line lodash/import-scope
const { Maybe } = require('@wix/wix-code-adt')

const types = {
  AddressInput: {
    viewerType: ['wixui.AddressInput'],
    sdkType: '$w.AddressInput',
  },
  Text: {
    viewerType: ['wysiwyg.viewer.components.WRichText'],
    sdkType: '$w.Text',
  },
  Image: {
    viewerType: ['wysiwyg.viewer.components.WPhoto', 'wixui.ImageX'],
    sdkType: '$w.Image',
  },
  TextInput: {
    viewerType: ['wysiwyg.viewer.components.inputs.TextInput'],
    sdkType: '$w.TextInput',
  },
  TimePicker: {
    viewerType: ['wixui.TimePicker'],
    sdkType: '$w.TimePicker',
  },
  Button: {
    viewerType: ['wysiwyg.viewer.components.SiteButton'],
    sdkType: '$w.Button',
  },
  IconButton: {
    viewerType: ['wysiwyg.common.components.imagebutton.viewer.ImageButton'],
    sdkType: '$w.IconButton',
  },
  RatingsDisplay: {
    viewerType: ['wixui.RatingsDisplay'],
    sdkType: '$w.RatingsDisplay',
  },
  RatingsInput: {
    viewerType: ['wixui.RatingsInput'],
    sdkType: '$w.RatingsInput',
  },
  VerticalMenu: {
    viewerType: ['wysiwyg.common.components.verticalmenu.viewer.VerticalMenu'],
    sdkType: '$w.VerticalMenu',
  },
  Checkbox: {
    viewerType: ['wysiwyg.viewer.components.inputs.Checkbox'],
    sdkType: '$w.Checkbox',
  },
  Gallery: {
    viewerType: [
      'wysiwyg.viewer.components.MatrixGallery',
      'wysiwyg.viewer.components.SliderGallery',
      'wysiwyg.viewer.components.SlideShowGallery',
      'wysiwyg.viewer.components.PaginatedGridGallery',
      'wysiwyg.viewer.components.tpapps.TPAWidget',
    ],
    sdkType: '$w.Gallery',
  },
  Dropdown: {
    viewerType: [
      'wysiwyg.viewer.components.inputs.ComboBoxInput',
      'wixui.Dropdown',
    ],
    sdkType: '$w.Dropdown',
  },
  TextBox: {
    viewerType: ['wysiwyg.viewer.components.inputs.TextAreaInput'],
    sdkType: '$w.TextBox',
  },
  RichTextBox: {
    viewerType: ['wixui.RichTextBox'],
    sdkType: '$w.RichTextBox',
  },
  Table: {
    viewerType: ['wysiwyg.viewer.components.Grid'],
    sdkType: '$w.Table',
  },
  DatePicker: {
    viewerType: ['wysiwyg.viewer.components.inputs.DatePicker'],
    sdkType: '$w.DatePicker',
  },
  RadioButtonGroup: {
    viewerType: ['wysiwyg.viewer.components.inputs.RadioGroup'],
    sdkType: '$w.RadioButtonGroup',
  },
  UploadButton: {
    viewerType: [
      'wysiwyg.viewer.components.inputs.UploadButton',
      'wixui.FileUploaderNew',
      'wysiwyg.viewer.components.inputs.FileUploader',
    ],
    sdkType: '$w.UploadButton',
  },
  ClassicSection: {
    viewerType: ['wysiwyg.viewer.components.ClassicSection'],
    sdkType: '$w.Section',
  },
  Column: {
    viewerType: ['wysiwyg.viewer.components.Column'],
    sdkType: '$w.Column',
  },
  Video: {
    viewerType: ['wysiwyg.viewer.components.Video'],
    sdkType: '$w.Video',
  },
  VideoPlayer: {
    viewerType: ['wixui.VideoPlayer'],
    sdkType: '$w.VideoPlayer',
  },
  VideoBox: {
    viewerType: ['wysiwyg.viewer.components.MediaPlayer'],
    sdkType: '$w.VideoBox',
  },
  MusicPlayer: {
    viewerType: ['wixui.MusicPlayer'],
    sdkType: '$w.AudioPlayer',
  },
  Repeater: {
    viewerType: ['wysiwyg.viewer.components.Repeater'],
    sdkType: '$w.Repeater',
  },
  Pagination: {
    viewerType: ['wixui.Pagination'],
    sdkType: '$w.Pagination',
  },
  Page: {
    viewerType: ['mobile.core.components.Page'],
    sdkType: '$w.Page',
  },
  Document: {
    viewerType: ['fake-document-type'],
    sdkType: '$w.Document',
  },
  MediaContainer: {
    viewerType: ['wysiwyg.viewer.components.MediaContainer'],
    sdkType: '$w.Container',
  },
  StripColumnsContainer: {
    viewerType: ['wysiwyg.viewer.components.StripColumnsContainer'],
    sdkType: '$w.ColumnStrip',
  },
  ToggleSwitch: {
    viewerType: ['wixui.ToggleSwitch'],
    sdkType: '$w.Switch',
  },
  Slider: {
    viewerType: ['wixui.Slider'],
    sdkType: '$w.Slider',
  },
  RangeSlider: {
    viewerType: ['wixui.RangeSlider'],
    sdkType: '$w.RangeSlider',
  },
  StylableButton: {
    viewerType: ['wixui.StylableButton'],
    sdkType: '$w.StylableButton',
  },
  GoogleMap: {
    viewerType: ['wysiwyg.viewer.components.GoogleMap'],
    sdkType: '$w.GoogleMap',
  },
  CheckboxGroup: {
    viewerType: ['wysiwyg.viewer.components.inputs.CheckboxGroup'],
    sdkType: '$w.CheckboxGroup',
  },
  ProgressBar: {
    viewerType: ['wixui.ProgressBar'],
    sdkType: '$w.ProgressBar',
  },
  VectorImage: {
    viewerType: [
      'wysiwyg.viewer.components.VectorImage',
      'wysiwyg.viewer.components.svgshape.SvgShape',
    ],
    sdkType: '$w.VectorImage',
  },
  SelectionTags: {
    viewerType: ['wixui.SelectionTagsList'],
    sdkType: '$w.SelectionTags',
  },
  Section: {
    viewerType: ['responsive.components.Section'],
    sdkType: '$w.Section',
  },
  SignatureInput: {
    viewerType: ['wixui.SignatureInput'],
    sdkType: '$w.SignatureInput',
  },
  RefComponent: {
    viewerType: ['wysiwyg.viewer.components.RefComponent'],
    sdkType: '$w.RefComponent',
  },
  RichContent: {
    viewerType: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    sdkType: '$w.RichContent',
  },
}

module.exports = mapValues(types, ({ sdkType }) => sdkType)

module.exports.getSdkTypeByViewerType = viewerType =>
  Maybe.fromNullable(
    Object.keys(types)
      .filter(key => types[key].viewerType.indexOf(viewerType) >= 0)
      .map(key => types[key].sdkType)[0],
  )

module.exports.getViewerTypeBySdkType = sdkType =>
  Maybe.fromNullable(
    Object.keys(types)
      .filter(key => types[key].sdkType === sdkType)
      .map(key => types[key].viewerType[0])[0],
  )

module.exports.getViewerTypes = Object.keys(types).reduce((acc, val) => {
  acc.concat(types[val].viewerType)
  return acc
}, [])

module.exports.viewerTypes = Object.keys(types)
  .map(key => types[key].viewerType)
  .reduce((acc, val) => acc.concat(val), [])
  .reduce((acc, type) => {
    acc[type.split('.').reverse()[0]] = type
    return acc
  }, {})

module.exports.sdkTypes = Object.keys(types).map(key => types[key].sdkType)

module.exports.isSdkTypeSupported = sdkType =>
  !!mapKeys(types, ({ sdkType }) => sdkType)[sdkType]
