import classNames from 'classnames'
import React from 'react'
import {MobileMonthlyEvent} from './event'
import s from './mobile-monthly-day.scss'
import {MobileMonthlyDayProps} from '.'

export class MobileMonthlyDay extends React.PureComponent<MobileMonthlyDayProps> {
  render() {
    const {borderStyleRight, borderStyleBottom, events, past, today, dayNumber} = this.props

    return (
      <div className={s.content} data-hook={`calendar-day-${dayNumber}`}>
        <div className={classNames(s.day, {[s.past]: past, [s.today]: today})} style={borderStyleRight}>
          <div className={s.dayText} data-hook={`day-of-month-${dayNumber}`}>
            {dayNumber}
          </div>
        </div>
        <ul className={s.events}>
          {events.map(event => (
            <li key={event.id} className={s.event} style={borderStyleBottom}>
              <MobileMonthlyEvent event={event} />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}
