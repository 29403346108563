import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {getComponentConfig, isFitImage, isSingleEventWidget} from '../../selectors/settings'
import {isMobile} from '../../../../../commons/selectors/environment'
import {EventImage as Presentation} from './event-image'
import {EventImageStateProps, EventImageOwnProps} from './interfaces'

const mapState = ({state}: AppProps) => ({
  fitImage: isFitImage(getComponentConfig(state), isMobile(state)),
  singleLayout: isSingleEventWidget(getComponentConfig(state)),
})

export const EventImage = connect<EventImageOwnProps, EventImageStateProps>(mapState)(Presentation)
