import { TFunction } from '@wix/yoshi-flow-editor';
import { Field, FieldOrigin } from '../../types';

const hasTranslation = (t: TFunction, key: string) => t(key) !== key;

const appendPostfix = (label: string, postfix: number | null) => {
  return postfix ? `${label} ${postfix}` : label;
};
const getPostfix = (index?: number) => (index ? index + 1 : null);

export const getMultilingualFieldLabel = (
  t: TFunction,
  field: Pick<Field, 'key' | 'index' | 'label'>,
) => {
  const { key, index, label } = field;
  const postfix = getPostfix(index);

  if (key === label || !label) {
    return appendPostfix(t(key), postfix);
  } else {
    return appendPostfix(label, postfix);
  }
};

export const getNonMultilingualFieldLabel = (
  t: TFunction,
  field: Pick<Field, 'key' | 'index' | 'label' | 'fieldOrigin'>,
) => {
  const { key, label, index, fieldOrigin } = field;
  const postfix = getPostfix(index);

  if (fieldOrigin !== FieldOrigin.CUSTOM && hasTranslation(t, key)) {
    return appendPostfix(t(key), postfix);
  } else {
    return appendPostfix(label, postfix);
  }
};
