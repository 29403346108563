import { RESET_ALL } from '../helpers/constants'
import { ComponentDatabindingApiFactory } from './types'
import { baseComponentBindingApi } from './baseComponentBindingApi'
import appContext from '../../src/viewer-app-module/DataBindingAppContext'
import {
  getUserFilterInitialData as getUserFilterInitialDataFromState,
  UserFilterListInitialData,
} from '../../src/dataset-controller/rootReducer'
import { AdaptedComponent, Option } from '../inverted-dependencies/components'
import { UoUFilterConnectionConfig } from '../types'
import { LIST_TYPE_USER_INPUT_FILTER_ROLES } from '../../src/helpers/constants'
import { handleUserInputFilterOnChange } from './utils'

export const userInputFilterListTypeBindingApi: ComponentDatabindingApiFactory<
  AdaptedComponent,
  UoUFilterConnectionConfig
> = (component, connectionConfig, context) => {
  const { i18n } = appContext
  const { getState } = context

  const getUserFilterInitialData = (fieldName: string) =>
    getUserFilterInitialDataFromState(getState())?.find(
      userFilterInitialData =>
        userFilterInitialData.fieldName === fieldName &&
        LIST_TYPE_USER_INPUT_FILTER_ROLES.includes(userFilterInitialData.role),
    )

  const componentHasPlaceholderField = (component: any) =>
    'placeholder' in component

  const setInitialComponentValue = (prop: string) => {
    const value = Array.isArray(component.getValue({ propPath: prop }))
      ? []
      : RESET_ALL
    component.setValue(value, { propPath: prop })
  }

  const getComponentOptions = ({
    component,
    prop,
    userFilterInitialData,
  }: {
    component: AdaptedComponent
    prop: string
    userFilterInitialData: UserFilterListInitialData
  }) =>
    [
      !Array.isArray(component.getValue({ propPath: prop })) && {
        value: RESET_ALL,
        label: i18n.t('USER_INPUT_FILTER_OPTION_RESET_ALL'),
      },
      ...userFilterInitialData.options.map((value: any) => ({
        value: String(value),
        label: String(value),
      })),
    ].filter(Boolean) as Option[]

  return {
    ...baseComponentBindingApi(component, connectionConfig, context),
    bind() {
      const { userInputFilter } = connectionConfig

      handleUserInputFilterOnChange(component, connectionConfig, context)

      const { prop, fieldName } = userInputFilter

      const userFilterInitialData = getUserFilterInitialData(fieldName)

      if (userFilterInitialData) {
        component.setOptions(
          getComponentOptions({
            component,
            prop,
            userFilterInitialData:
              userFilterInitialData as UserFilterListInitialData,
          }),
        )

        if (!componentHasPlaceholderField(component)) {
          setInitialComponentValue(prop)
        }
      }
    },

    resetUserFilter() {
      const {
        userInputFilter: { prop },
      } = connectionConfig
      if (component.getValue({ propPath: prop })) {
        setInitialComponentValue(prop)
      }
    },
  }
}
