import { FieldType } from '@wix/wix-data-schema-types'

const {
  text,
  boolean,
  number,
  dateTime,
  date,
  richText,
  url,
  reference,
  stringArray,
} = FieldType

const supportedAutomationFieldsTypes = [
  text,
  richText,
  boolean,
  dateTime,
  date,
  reference,
  number,
  url,
  stringArray,
]

export const isFieldSupported = (fieldData, fieldName) => {
  const supportedSystemFieldsNames = ['_createdDate', '_updatedDate']
  const fieldType = fieldData.type
  const isSupportedType = supportedAutomationFieldsTypes.includes(fieldType)
  const isSystemField = !!fieldData.systemField
  const isSupportedSystemField = supportedSystemFieldsNames.includes(
    fieldName || fieldData.name,
  )
  return (!isSystemField || isSupportedSystemField) && isSupportedType
}
