import classNames from 'classnames'
import React, {useEffect} from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Title} from '../list-title'
import {LoadMoreButton} from '../load-more-button'
import SideBySideItem from './side-by-side-item'
import s from './side-by-side.scss'
import {Utils} from './utils'
import {SideBySideProps} from '.'

export const SideBySide = ({showImage, events, margins, t, width}: SideBySideProps) => {
  const utils = new Utils()

  useEffect(() => {
    utils.setItemsHeight()
  }, [events.length])

  return (
    <div className={s.container}>
      <Title />
      <ul className={s.items} data-hook={DH.sideBySideItems} style={{minWidth: utils.getMinItemsWidth(showImage)}}>
        {events.map((event: ExtendedEvent, index: number) => (
          <li
            key={event.id}
            ref={element => utils.addItem(element)}
            className={classNames(s.item, utils.itemsHeightClass(width))}
            data-hook={DH.sideBySideItem}
            style={{marginBottom: margins}}
          >
            <SideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
          </li>
        ))}
      </ul>
      <LoadMoreButton t={t} />
    </div>
  )
}
