import React, {useState} from 'react'
import {Divider} from 'wix-ui-tpa'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getPricingOptions} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {ExpansionButton} from '../../parts/expansion-button'
import {PricingOptionPrice} from '../pricing-option/pricing-option-price'
import {PricingOptionQuantity} from '../pricing-option/pricing-option-quantity'
import {LimitReachedTooltip} from '../../parts/limit-reached-tooltip'
import {classes} from './mobile-pricing-options.st.css'
import {MobilePricingOptionsProps} from './interfaces'

export const MobilePricingOptions = ({ticket, readonly}: MobilePricingOptionsProps) => {
  const [expanded, setExpanded] = useState(false)
  const {t} = useTranslation()

  const buttonText = expanded
    ? 'ticketsPicker.ticketDescription.showLessPricingOptions'
    : 'ticketsPicker.ticketDescription.showMorePricingOptions'

  const pricingOptions = getPricingOptions(ticket)
  const visibleOptions = expanded ? pricingOptions : [pricingOptions[0]]

  return (
    <>
      {visibleOptions?.map((pricingOption, index) => (
        <React.Fragment key={pricingOption.id}>
          {index !== 0 ? <Divider className={classes.pricingOptionDivider} /> : null}
          <div className={classes.pricingOption} data-hook={DH.TICKET_PRICING_OPTION}>
            <PricingOptionPrice pricingOption={pricingOption} ticket={ticket} />
            {readonly ? null : (
              <LimitReachedTooltip ticket={ticket} pricingOptionId={pricingOption.id}>
                <PricingOptionQuantity
                  pricingOption={pricingOption}
                  ticket={ticket}
                  className={classes.priceOptionQuantity}
                />
              </LimitReachedTooltip>
            )}
          </div>
        </React.Fragment>
      ))}
      {pricingOptions.length > 1 ? (
        <ExpansionButton dataHook={DH.MORE_PRICE_OPTIONS_BUTTON} expanded={expanded} onChange={setExpanded}>
          {t(buttonText)}
        </ExpansionButton>
      ) : null}
    </>
  )
}
