import { useEffect } from 'react';
import { FormikErrors } from 'formik';

import { DataHook } from '../../../../constants/DataHook';

const SCROLL_BEHAVIOR = 'scrollBehavior';
const SCROLL_BEHAVIOR_SMOOTH = 'smooth';
const SCROLL_BLOCK_CENTER = 'center';
const options: ScrollIntoViewOptions = {
  behavior: SCROLL_BEHAVIOR_SMOOTH,
  block: SCROLL_BLOCK_CENTER,
};

export const useErrorFocus = (
  isSubmitting: boolean,
  isValidating: boolean,
  errors: FormikErrors<unknown>,
) =>
  useEffect(() => {
    if (errors && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(
        `[data-hook="${DataHook.WidgetWrapper}"] [data-error="true"]`,
      );

      if (errorElement) {
        try {
          // browser compatibility issue:
          // all browsers support boolean as arg for scrollIntoView(arg),
          // but not all (safari :confused:) support an object with options.
          // options give us better user experience, so we try to use them if we can.
          // see https://stackoverflow.com/questions/46919627/is-it-possible-to-test-for-scrollintoview-browser-compatibility/52393930#comment103745961_51070207
          const isBehaviorSupported =
            SCROLL_BEHAVIOR in document.documentElement.style;

          errorElement.scrollIntoView &&
            errorElement.scrollIntoView(isBehaviorSupported ? options : true);
        } catch (error) {
          errorElement.scrollIntoView && errorElement.scrollIntoView(true);
        }
      }
    }
  }, [isSubmitting, isValidating, errors]);
