import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Accordion, AccordionSize } from 'wix-ui-tpa';

import { SectionHeader } from '../../common/SectionHeader';
import { classes, st } from './AccountSettings.st.css';
import {
  getBlockedMembersItem,
  getCommunityPageUrlItem,
  getProfileVisibilityItem,
} from './AccordionItems';
import {
  MemberRole,
  PageAlignment,
  ProfilePrivacyStatus,
} from '../../../../../types';
import { useMember, useSiteContext } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';
import { DataHook } from '../../../../../constants/DataHook';
import { useBi } from '../../../../../hooks';
import { useModal } from '../../../../../contexts/modals/hooks';

const isPublicProfile = (privacyStatus: ProfilePrivacyStatus) => {
  return privacyStatus === ProfilePrivacyStatus.PUBLIC;
};

const isOwner = (role: MemberRole | undefined) => role === MemberRole.OWNER;

export const shouldShowCommunityUrlItem = (
  isSocial: boolean,
  privacyStatus: ProfilePrivacyStatus,
) => isSocial && isPublicProfile(privacyStatus);

export const shouldShowProfileVisibilityItem = (role: MemberRole | undefined) =>
  !isOwner(role);

export const shouldShowBlockedMembersItem = (role: MemberRole | undefined) =>
  !isOwner(role);

export const AccountSettings: FC = () => {
  const { t } = useTranslation();
  const { privacyStatus, role, blockedMembers, profile } = useMember();
  const { baseUrl, isSocial } = useSiteContext();
  const settings = useSettings();
  const biLogger = useBi();
  const { showModal } = useModal();

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;

  const accordionItems = [
    shouldShowCommunityUrlItem(isSocial, privacyStatus) &&
      getCommunityPageUrlItem({
        showModal,
        t,
        biLogger,
        baseUrl,
        slug: profile.slug,
      }),
    shouldShowProfileVisibilityItem(role) &&
      getProfileVisibilityItem({
        showModal,
        biLogger,
        t,
        privacyStatus,
      }),
    shouldShowBlockedMembersItem(role) &&
      getBlockedMembersItem({
        blockedMembers,
        showModal,
        t,
        biLogger,
        privacyStatus,
      }),
  ].filter((x) => x) as JSX.Element[];

  return (
    <div
      data-hook={DataHook.MyAccountSettings}
      className={st(classes.root, {
        alignCenter: pageAlignment === PageAlignment.center,
      })}
    >
      <SectionHeader
        title={t('app.widget.sections.account-settings.title')}
        subtitle={t('app.widget.sections.account-settings.subtitle')}
        className={classes.sectionHeader}
      />
      <Accordion multiple={true} size={AccordionSize.Large}>
        {accordionItems}
      </Accordion>
    </div>
  );
};
