export const categoryMock = {
  id: 'e655eee5-bfff-5f93-95d6-9f7cbe2e6d62',
  name: 'Hats',
  slug: 'hats',
  media: {
    mainMedia: {
      thumbnail: {
        url: 'https://static.wixstatic.com/media/c837a6_7db0aae63f414bb09ea1035911f0f3e7~mv2.jpg/v1/fit/w_50,h_50,q_90/file.jpg',
        width: 50,
        height: 50,
      },
      mediaType: 'image',
      title: '',
      image: {
        url: 'https://static.wixstatic.com/media/c837a6_7db0aae63f414bb09ea1035911f0f3e7~mv2.jpg/v1/fit/w_1000,h_1300,q_90/file.jpg',
        width: 1000,
        height: 1300,
      },
      id: '',
    },
    items: [
      {
        thumbnail: {
          url: 'https://static.wixstatic.com/media/c837a6_7db0aae63f414bb09ea1035911f0f3e7~mv2.jpg/v1/fit/w_50,h_50,q_90/file.jpg',
          width: 50,
          height: 50,
        },
        mediaType: 'image',
        title: '',
        image: {
          url: 'https://static.wixstatic.com/media/c837a6_7db0aae63f414bb09ea1035911f0f3e7~mv2.jpg/v1/fit/w_1000,h_1300,q_90/file.jpg',
          width: 1000,
          height: 1300,
        },
        id: '',
      },
    ],
  },
  numberOfProducts: 6,
  visible: true,
};
