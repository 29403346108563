import {ExperimentsBag} from '@wix/wix-experiments'
import {AnyAction} from 'redux'

const defaultState: ExperimentsBag = {}

export const experiments = (state = defaultState, action: AnyAction): ExperimentsBag => {
  switch (action.type) {
    default:
      return state
  }
}
