import {EditorSDK} from '@wix/platform-editor-sdk';
import {sliderWidthMigration} from '../migration-scripts/sliderMigration';
import {reinstallPage} from '../migration-scripts/reinstallPage';
import {wishlistPageId} from '../constants';
import {freeProductsLegacyFlag} from '../migration-scripts/freeProductsLegacyFlag';
import {productWithSubscriptionSaved} from '../migration-scripts/productWithSubscriptionSaved';
import {EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {DependantApps} from '../services/DependantApps';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {updateEcomPagesForPagesPanel} from './publicApi';
import {StoresWidgetID} from '@wix/wixstores-client-core/dist/src/constants';
import {EcomComponent} from '@wix/ecom-platform-sdk';
import * as _ from 'lodash';
import {COMPONENTS_METADATA, ComponentType} from '@wix/ecom-platform-sdk/dist/src/constants';
import {PageData} from '@wix/editor-platform-sdk-types';
import {Logger} from '@wix/bi-logger-ec-sf';

const STORES_WIDGETS_TO_REMOVE = [
  StoresWidgetID.ADD_TO_CART,
  StoresWidgetID.GRID_GALLERY,
  StoresWidgetID.PRODUCT_WIDGET,
  StoresWidgetID.SLIDER_GALLERY,
];

export async function handleAction(
  {type, payload},
  {
    sdk,
    dependantApps,
    ecomPlatformInstallationState,
    biLogger,
  }: {
    sdk: EditorSDK;
    dependantApps: DependantApps;
    ecomPlatformInstallationState: EcomPlatformInstallationState;
    biLogger: Logger;
  }
) {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  try {
    if (type === 'removeAppCompleted') {
      const appToRemove = payload?.appDefinitionId;
      const appToRemoveComponents = ecomPlatformInstallationState.getInstalledAppComponents(appToRemove);
      if (appToRemoveComponents) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        biLogger.exposureEventForTests({
          testName: 'appToRemoveComponents-start',
          is_eligible: true,
          appState: JSON.stringify(ecomPlatformInstallationState.getInstalledAppDefIds() ?? 'false'),
        });
        await handleEcomDependencyAppRemoved(
          sdk,
          dependantApps,
          ecomPlatformInstallationState,
          appToRemove,
          appToRemoveComponents,
          biLogger
        );
      }
    } else if (type === 'migrate') {
      switch (payload.type) {
        case 'sliderWidth':
          return await sliderWidthMigration(sdk);
        case 'installNewStores':
          return await dependantApps.installNewStoresAppIfNeeded();
        case 'reinstallWishlist':
          return await reinstallPage(
            sdk,
            wishlistPageId,
            dependantApps.installWishlistPageInMembersArea.bind(dependantApps)
          );
        case 'reinstallCheckout':
          return await reinstallPage(sdk, 'checkout', dependantApps.addCheckoutIfNeeded.bind(dependantApps));
        case 'tryInstallWishlistTPA':
          return await dependantApps.installWishlistTPA();
        case 'freeProductsLegacyFlag':
          return await freeProductsLegacyFlag(sdk, payload.value);
        case 'productWithSubscriptionSaved':
          return await productWithSubscriptionSaved();
        default:
          return await Promise.resolve();
      }
    } else {
      return await Promise.resolve();
    }
  } catch (e) {
    return await Promise.reject(e);
  }
}

async function handleEcomDependencyAppRemoved(
  sdk: EditorSDK,
  dependantApps: DependantApps,
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appToRemove: string,
  appToRemoveComponents: EcomComponent[],
  biLogger: Logger
) {
  const allComponentsRefs: ComponentRef[] = await sdk.components.getAllComponents('');
  const allComponents: ComponentSpec[] = await sdk.components.get('', {
    componentRefs: allComponentsRefs,
    properties: ['data'],
  });
  const allPages = await sdk.pages.data.getAll('');
  const siteComponents = {
    pages: allPages,
    widgets: allComponents,
  };

  ecomPlatformInstallationState.removeInstalledApp(appToRemove);
  const allAppsRemoved = !ecomPlatformInstallationState.hasInstalledApps();
  if (allAppsRemoved) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    biLogger.exposureEventForTests({
      testName: 'appToRemoveComponents-remove-app',
      is_eligible: true,
      appState: JSON.stringify(ecomPlatformInstallationState.getInstalledAppDefIds() ?? 'false'),
    });
    await sdk.application.uninstall('', {openConfirmation: false});
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    biLogger.exposureEventForTests({
      testName: 'appToRemoveComponents-remove-pages',
      is_eligible: true,
      appState: JSON.stringify(ecomPlatformInstallationState.getInstalledAppDefIds() ?? 'false'),
    });
    await removeUnneededEcomPages(sdk, siteComponents, ecomPlatformInstallationState, appToRemoveComponents);
  }

  if (ecomPlatformInstallationState.getInstalledAppDefIds().length === 1) {
    await updateEcomPagesForPagesPanel(sdk, ecomPlatformInstallationState);
  }

  if (!allAppsRemoved && appToRemove === STORES_APP_DEF_ID) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    biLogger.exposureEventForTests({
      testName: 'appToRemoveComponents-remove-stores',
      is_eligible: true,
      appState: JSON.stringify(ecomPlatformInstallationState.getInstalledAppDefIds()),
    });
    await removeStoresWidgets(sdk, dependantApps, siteComponents);
  }
}

async function removeUnneededEcomPages(
  sdk: EditorSDK,
  siteComponents: {pages: PageData[]; widgets: ComponentSpec[]},
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appToRemoveComponents: EcomComponent[]
) {
  const installedComponents = _.flatten(
    ecomPlatformInstallationState
      .getInstalledAppDefIds()
      .map((app) => ecomPlatformInstallationState.getInstalledAppComponents(app))
  );

  return Promise.all(
    appToRemoveComponents.map(async (component) => {
      if (installedComponents.includes(component)) {
        return Promise.resolve();
      }

      if (COMPONENTS_METADATA[component].type === ComponentType.PAGE) {
        const pageToRemove = siteComponents.pages.find((page) => page.tpaPageId === COMPONENTS_METADATA[component].id);
        return pageToRemove
          ? sdk.pages.remove('', {pageRef: {id: pageToRemove.id, type: 'DESKTOP'}})
          : Promise.resolve();
      } else {
        const widgetIdToRemove = COMPONENTS_METADATA[component].widget.widgetId;
        const compToRemove = siteComponents.widgets.find((comp) => comp.data?.widgetId === widgetIdToRemove);
        return compToRemove ? sdk.components.remove('', {componentRef: compToRemove.componentRef}) : Promise.resolve();
      }
    })
  );
}

async function removeStoresWidgets(
  sdk: EditorSDK,
  dependantApps: DependantApps,
  siteComponents: {pages: PageData[]; widgets: ComponentSpec[]}
) {
  await Promise.all(
    siteComponents.widgets
      .filter((comp) => STORES_WIDGETS_TO_REMOVE.includes(comp.data?.widgetId))
      .map(async (comp) => {
        await sdk.components.remove('', {componentRef: comp.componentRef});
      })
  );

  await dependantApps.uninstallWishlistPageInMembersArea();
}
