import classNames from 'classnames'
import React from 'react'
import {HEADER_IMAGE_ALIGNMENT} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getAlignmentClass} from '../../alignment'
import {RegistrationButton} from '../../registration-button'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Description} from '../description'
import {Image} from './image'
import s from './side-by-side.scss'
import {SideBySideLayoutProps} from './index'

export const SideBySideLayout: React.FC<SideBySideLayoutProps> = ({
  headerAlignment,
  onClickRegistrationButton,
  headerImageAlignment,
  imageVisible,
  fullWidth,
  responsive,
}) => {
  const eventInfo = () => (
    <div
      className={classNames(
        s.eventInfoContainer,
        imageVisible ? s.withImage : s.withoutImage,
        fullWidth || responsive ? s.fullWidth : s.minimized,
        s.content,
        getAlignmentClass(headerAlignment),
      )}
    >
      <ShortDateLocation />
      <Title />
      <Description />
      <RegistrationButton onClick={onClickRegistrationButton} className={s.sideBySideRegistrationButton} />
    </div>
  )

  return (
    <div className={classNames(s.wrapper)}>
      <div
        className={classNames(
          s.container,
          {[s.fullWidth]: fullWidth},
          s.containerWithImage,
          headerImageAlignment === HEADER_IMAGE_ALIGNMENT.LEFT ? s.alignImageLeft : s.alignImageRight,
        )}
        data-hook={DH.header}
      >
        {imageVisible ? <Image /> : null}
        {eventInfo()}
      </div>
    </div>
  )
}
