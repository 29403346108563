import {getWrappedComponent} from '../../../appConfig';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME} from '../../../constants';
import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {CategoriesApp} from '../../../category/components/CategoriesApp/CategoriesApp';

export default getWrappedComponent({
  Component: CategoriesApp,
  withStyleStrategy: withStyles,
  withExperimentAttribute: USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME,
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
