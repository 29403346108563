import classNames from 'classnames'
import React from 'react'
import sc from '../../classes.scss'
import s from './description.scss'
import {DescriptionProps} from '.'

export const Description = ({event, onImage, isMobile}: DescriptionProps) => (
  <div
    className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
      [s.onImage]: onImage,
      [s.mobile]: isMobile,
    })}
    data-hook="ev-description"
  >
    {event.description}
  </div>
)
