import {EventsHttpClient} from '@wix/wix-events-commons-statics'
import type {ControllerParams, IHttpClient} from '@wix/yoshi-flow-editor'
import {instanceGetterFactory} from '../services/instance'
import {getLinguisticHeader} from '../services/multilingual'
import {getLanguage, getWriteServerBaseUrl, getLocale, getTimezone} from './wix-code-api'

export class Api {
  controller: ControllerParams['controllerConfig']
  getInstance: () => string
  instanceId: string
  compId: string
  viewMode: string
  language: string
  locale: string
  tz: string
  api: EventsHttpClient
  writeApi: EventsHttpClient
  registrar: Dictionary<Function>
  httpClient: IHttpClient

  constructor(controllerParams: ControllerParams) {
    this.httpClient = controllerParams.flowAPI.httpClient
    this.controller = controllerParams.controllerConfig
    this.getInstance = instanceGetterFactory(this.controller)
    this.instanceId = this.controller.appParams.instanceId
    this.compId = this.controller.compId
    this.viewMode = this.controller.wixCodeApi.window.viewMode.toLowerCase()
    this.language = getLanguage(this.controller.wixCodeApi)
    this.locale = getLocale(this.controller.wixCodeApi)
    this.tz = getTimezone(this.controller.wixCodeApi)

    this.api = new EventsHttpClient(this.httpClient, undefined, getLinguisticHeader(this.controller))
    this.writeApi = new EventsHttpClient(
      this.httpClient,
      getWriteServerBaseUrl(this.controller.wixCodeApi),
      getLinguisticHeader(this.controller),
    )
  }

  get(name: string) {
    const api = this.registrar[name]

    if (api) {
      return api
    }

    throw new Error(`API METHOD IS NOT REGISTERED ${name}`)
  }
}
