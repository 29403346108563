import React from 'react';

export const withExperiment = (
  ComponentWhenTrue: React.ComponentType,
  ComponentWhenFalse: React.ComponentType,
  experimentAttribute: string
) => {
  return (props) => {
    if (props?.experiments?.[experimentAttribute]) {
      return <ComponentWhenTrue {...props} />;
    }

    return <ComponentWhenFalse {...props} />;
  };
};
