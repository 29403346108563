import classNames from 'classnames'
import React from 'react'
import {Button, ButtonPriority, TextButton} from 'wix-ui-tpa'
import {classes} from './event-link.st.css'
import {EventLinkProps} from '.'

export const EventLink: React.FC<EventLinkProps> = ({primary, secondary, mobile, ...rest}) => {
  if (primary) {
    return (
      <Button
        {...rest}
        className={classNames(classes.primaryButton, {[classes.mobile]: mobile})}
        priority={ButtonPriority.basic}
        fullWidth={mobile}
      />
    )
  } else if (secondary) {
    return (
      <Button
        {...rest}
        className={classNames(classes.secondaryButton, {[classes.mobile]: mobile})}
        priority={ButtonPriority.basicSecondary}
        fullWidth={mobile}
      />
    )
  }

  return <TextButton {...rest} className={classNames(classes.link, {[classes.mobile]: mobile})} />
}
