import {areAllTicketsSoldOut} from '../../../../../selectors/tickets'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {TotalPriceOwnProps, TotalPriceRuntimeProps} from './interfaces'
import {TotalPrice as Presentation} from './total-price'

const mapRuntime = ({state}: DetailsPageAppProps): TotalPriceRuntimeProps => ({
  soldOut: areAllTicketsSoldOut(state.tickets),
})

export const TotalPrice = connect<TotalPriceOwnProps, TotalPriceRuntimeProps>(mapRuntime)(Presentation)
