import React, { ComponentType, FC } from 'react';

import { RolesMap, InjectedTranslateProps } from '../../../types';
import roleIcons from '../../../constants/role-icons';
import Icon from '../Icon';
import styles from './RoleIcons.scss';

interface RoleIconProps extends InjectedTranslateProps {
  role: string;
  IconClass: ComponentType;
  tooltipText: string;
  iconClassName?: string;
  withNames?: boolean;
}

interface RoleIconsProps extends InjectedTranslateProps {
  roles: string[];
  rolesMap: RolesMap;
  containerClassName?: string;
  iconClassName?: string;
  withNames?: boolean;
}

const RoleIcon: FC<RoleIconProps> = (props) => {
  const { IconClass, role, tooltipText, iconClassName, withNames, t } = props;

  if (withNames) {
    return (
      <div key={role} className={styles.role}>
        <Icon icon={<IconClass />} iconClassName={iconClassName} t={t} />
        <div className={styles.roleName}>{t(tooltipText)}</div>
      </div>
    );
  }

  return (
    <Icon
      key={role}
      tooltip={tooltipText}
      icon={<IconClass />}
      iconClassName={iconClassName}
      t={t}
    />
  );
};

const RoleIcons: FC<RoleIconsProps> = (props) => {
  const {
    roles = [],
    rolesMap,
    containerClassName,
    iconClassName,
    withNames,
    t,
  } = props;

  if (!rolesMap) {
    return null;
  }

  const rolesWithIcons = roles.filter((role) =>
    Object.keys(roleIcons).includes(role),
  );

  const hasRolesWithIcons = !rolesWithIcons || rolesWithIcons.length === 0;
  if (hasRolesWithIcons) {
    return null;
  }

  return (
    <div className={containerClassName}>
      {rolesWithIcons
        .filter((role) => role in rolesMap)
        .map((role, index) => (
          <RoleIcon
            key={`role-${index}`}
            role={role}
            IconClass={roleIcons[role]}
            tooltipText={rolesMap[role].roleName}
            iconClassName={iconClassName}
            withNames={withNames}
            t={t}
          />
        ))}
    </div>
  );
};

export default RoleIcons;
