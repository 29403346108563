import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createEventHandler } from '@wix/tpa-settings';

import { createStore, getActionHandlers, Store } from '../../store';
import { ControllerProps, Experiment, FlowApi, WixCodeApi } from '../../types';
import { getAccountData } from '../../server/account.api';
import { AccountData } from '../../types/server';
import { localeThunk } from '../../store/slices';
import { ProfileInfoChangeSubject } from '../../viewer.app';
import { withLocaleHeadersFactory } from './controllerUtils/localeHeaders';
import { SettingsEvent, SettingsEventHandler } from '../../types/appSettings';
import { registerSettingEventHandlers } from './controllerUtils/appSettingsEventHandlers';

const getControllerProps = (flowAPI: FlowApi, store: Store) => {
  const state = store.getState();
  const actionHandlers = getActionHandlers(store);
  const props: ControllerProps = {
    ...state,
    ...actionHandlers,
    ...(flowAPI.controllerConfig.platformAPIs.bi?.viewerName ===
      'thunderbolt' && { fitToContentHeight: true }),
  };

  return props;
};

const getInitialState = async (wixCodeApi: WixCodeApi, flowAPI: FlowApi) => {
  const withLocaleHeaders = withLocaleHeadersFactory(flowAPI);
  const isGeneralSettingsEnabled = flowAPI.experiments.enabled(
    Experiment.EnableGeneralSettings,
  );
  const { data } = await flowAPI.httpClient.request<AccountData>(
    withLocaleHeaders(
      getAccountData({
        shouldFetchMemberPrivacySettings: isGeneralSettingsEnabled,
      }),
    ),
  );

  const { member, fields, site } = data;

  return {
    fields,
    member,
    site: {
      baseUrl: wixCodeApi.location.baseUrl,
      isSocial: site?.isSocial ?? false,
      showPublicSections: site?.showPublicSections ?? false,
    },
  };
};

export const registerControllerListeners = async (
  flowAPI: FlowApi,
  settingsEventHandler: SettingsEventHandler,
  store: Store,
) => {
  const { isEditor } = flowAPI.environment;

  if (isEditor) {
    registerSettingEventHandlers(settingsEventHandler, store);
  }
};

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig: { setProps, wixCodeApi },
  appData,
}) => {
  const profileInfoChangeSubject = appData?.profileInfoChangeSubject as
    | ProfileInfoChangeSubject
    | undefined;
  const settingsListener = createEventHandler<SettingsEvent>({});

  return {
    async pageReady() {
      if (wixCodeApi.user.currentUser.loggedIn === false) {
        flowAPI.sentry.captureMessage('User not logged in');
        return;
      }

      const store = createStore(
        {
          flowAPI,
          wixCodeApi,
          profileInfoChangeSubject,
        },
        await getInitialState(wixCodeApi, flowAPI),
      );
      store.subscribe(() => setProps({ ...store.getState() }));

      const props = getControllerProps(flowAPI, store);
      setProps(props);
      store.dispatch(localeThunk.fetchLocaleData());
      await registerControllerListeners(flowAPI, settingsListener, store);
    },
    onBeforeUnLoad() {
      profileInfoChangeSubject?.unregisterObservers();
    },
    updateConfig(_, { publicData }) {
      settingsListener.notify(publicData.COMPONENT || {});
    },
  };
};

export default createController;
