import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC, useEffect } from 'react';
import { InputDialog } from 'wix-ui-tpa';
import { DataHook } from '../../../../../constants/DataHook';
import {
  BiActionType,
  ModalType,
  MY_ACCOUNT_REFERRAL,
} from '../../../../../types';
import { classes } from './BlockedMembersModal.st.css';
import { ActionableMemberRow } from '../../common/ActionableMemberRow';
import { useMember, useMemberActions } from '../../../../../contexts/widget';
import { useBi } from '../../../../../hooks';

export const BlockedMembersModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { blockedMembers } = useMember();
  const { unblockMember } = useMemberActions();
  const biLogger = useBi();

  useEffect(() => {
    if (!blockedMembers.length) {
      onClose();
    }
  }, [blockedMembers.length, onClose]);

  const onUnblock = (memberId: string) => {
    biLogger.ctaButtonClickedWithProps(BiActionType.Unblock_Member_Confirm, {
      member_chosen: memberId,
      formOrigin: MY_ACCOUNT_REFERRAL,
    });
    return unblockMember(memberId);
  };

  return (
    <InputDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t('app.widget.modals.blocked-members.title')}
      subtitle={t('app.widget.modals.blocked-members.subtitle')}
    >
      <div className={classes.content} data-hook={DataHook.BlockedMembersModal}>
        <div>
          {blockedMembers.map((member) => (
            <ActionableMemberRow
              member={member}
              onAction={() => onUnblock(member.id)}
              buttonText={t('app.widget.modals.blocked-members.unblock')}
            />
          ))}
        </div>
      </div>
    </InputDialog>
  );
};
