import {
  getEventTicketLimitPerOrder,
  getPricingOption,
  hasPricingOptions,
  InvoiceSelectedItems,
  isDonationTicketDefinition,
  PlaceWithTicketInfo,
  saleEnded,
  getPricingOptions,
} from '@wix/wix-events-commons-statics'
import {createSelector} from 'reselect'
import {SeatsInfo, SelectedTickets, State, TicketsToPlaces} from '../../types'
import {getSelectedTicketsQuantity, sortTicketDefinitionIds} from '../selected-tickets'
import {getTicketById, getTickets} from '../tickets'
import {DONATION_OPTION_ID, getSelectedPrice, getSelectedZone} from './filters'
import {
  getPlaceStock,
  getSelectedPlaceQuantity,
  getSelectedPricingOptionIds,
  isPlaceInBasket,
  isPlaceReservableAsWhole,
} from './place'
import {getSelectedPlaceId} from './mode'

export const getPlaces = (state: State) => state.seating.places

export const getPlace = (state: State, placeId: string) => getPlaceById(getPlaces(state), placeId)

export const getWholeTablePlace = (state: State, placeId: string) =>
  getPlaces(state)
    .filter(place => place.places)
    .flatMap(({places}) => places)
    .find(place => place.id === placeId)

export const getSelectedPlace = createSelector(getPlaces, getSelectedPlaceId, (places, selectedPlaceId) =>
  places.find(place => place.id === selectedPlaceId),
)

export const getPlaceById = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.id === id)

export const getAnyPlaceByPlanPlaceId = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.planPlaceId === id)

export const getPlacesInBasket = createSelector(getPlaces, places => {
  return places.filter(isPlaceInBasket).sort((a, b) => b.timeAddedToBasket - a.timeAddedToBasket)
})

export const calculatePlacesStock = (places: PlaceWithTicketInfo[]) =>
  places.reduce((acc, place) => {
    acc[place.planPlaceId] = getPlaceStock(places, place)
    return acc
  }, {} as Dictionary<number>)

export const calculatePlacesCapacity = (places: PlaceWithTicketInfo[]) =>
  places.reduce((acc, place) => {
    acc[place.planPlaceId] = place.capacity
    return acc
  }, {} as Dictionary<number>)

export const getSelectedSeatingTickets = (state: State): SelectedTickets => {
  const placesInBasket = getPlacesInBasket(state)

  return placesInBasket.reduce((acc, item) => {
    const quantity = acc[item.ticket.id]?.quantity || 0

    return {
      ...acc,
      [item.ticket.id]: {
        quantity: quantity + item.quantity,
      },
    }
  }, {})
}

export const getPlacesInBasketCount = (state: State) =>
  getPlacesInBasket(state).reduce((sum, {quantity}) => sum + quantity, 0)

export const getSelectedTicketsToPlaces = (state: State): TicketsToPlaces => {
  const placesInBasket = getPlacesInBasket(state)
  const selectedTickets = getSelectedSeatingTickets(state)

  return Object.keys(selectedTickets).reduce((acc, ticket) => {
    const selectedPlaces = placesInBasket.filter(place => place.ticket?.id === ticket)

    const seatsInfo: SeatsInfo = selectedPlaces.reduce(
      (info, place) => {
        const quantity = isPlaceReservableAsWhole(place) ? place.places.length : place.quantity

        const placeIds = isPlaceReservableAsWhole(place)
          ? [...info.placeIds, ...place.places.map(p => p.id)]
          : [...info.placeIds, ...new Array(place.quantity).fill(place.planPlaceId)]

        const donations = isDonationTicketDefinition(place.ticket)
          ? [...(info.donations ?? []), ...new Array(quantity).fill(place.donation || '0')]
          : undefined

        const pricingOptionIds = hasPricingOptions(place.ticket)
          ? [...(info.pricingOptionIds ?? []), ...getSelectedPricingOptionIds(place)]
          : undefined

        return {
          placeIds,
          donations,
          pricingOptionIds,
        }
      },
      {placeIds: [], donations: undefined, pricingOptionIds: undefined},
    )

    return {
      ...acc,
      [ticket]: seatsInfo,
    }
  }, {})
}

export const getSeatsReservationQuantities = (state): wix.events.ticketing.TicketReservationQuantity[] => {
  const ticketsToPlaces = getSelectedTicketsToPlaces(state)
  const selectedTicketDefinitionIds = Object.keys(ticketsToPlaces).sort(sortTicketDefinitionIds(state))

  return selectedTicketDefinitionIds.map(ticketDefinitionId => {
    const {placeIds, donations, pricingOptionIds} = ticketsToPlaces[ticketDefinitionId]
    const ticketDetails: wix.events.ticketing.TicketDetails[] =
      placeIds.reduce((acc, seatId, placeIndex) => {
        const index = acc.findIndex(
          seat =>
            seat.seatId === seatId && (!pricingOptionIds || seat.pricingOptionId === pricingOptionIds[placeIndex]),
        )

        if (index !== -1) {
          const currentCapacity = acc[index]?.capacity ?? 1
          acc[index].capacity = currentCapacity + 1
        } else {
          const pricingOptionId = pricingOptionIds?.[placeIndex]
          const priceOverride = donations?.[placeIndex]
          acc.push({seatId, priceOverride, pricingOptionId})
        }

        return acc
      }, [] as wix.events.ticketing.TicketDetails[]) ?? undefined

    return {
      ticketDefinitionId,
      quantity: placeIds.length,
      ticketDetails,
    }
  })
}

export const getSeatsPreliminaryInvoiceItems = (state: State): InvoiceSelectedItems => {
  return Object.entries(getSelectedTicketsToPlaces(state)).reduce(
    (acc, [ticketId, {placeIds, donations, pricingOptionIds}]) => {
      let priceOverrides: string[]
      if (donations) {
        priceOverrides = donations
      } else if (pricingOptionIds) {
        const ticket = getTicketById(getTickets(state), ticketId)
        priceOverrides = pricingOptionIds.map(optionId => getPricingOption(ticket, optionId).price.amount)
      }

      acc[ticketId] = {
        quantity: placeIds.length,
        priceOverrides,
      }
      return acc
    },
    {},
  )
}

export const getOrderRemainder = (state: State, place: PlaceWithTicketInfo) => {
  const selectedTicketQuantity = getSelectedPlaceQuantity(state, place.ticket?.id, place.id)
  const selectedTicketsQuantity = getSelectedTicketsQuantity(state)
  const eventTicketLimitPerOrder = getEventTicketLimitPerOrder(state.event)

  const totalSelectedTicketsQuantity = selectedTicketQuantity
    ? selectedTicketsQuantity - selectedTicketQuantity
    : selectedTicketsQuantity
  const orderRemainder = eventTicketLimitPerOrder - totalSelectedTicketsQuantity
  return orderRemainder
}

export const getFilteredPlaces = createSelector(
  getPlaces,
  getSelectedPrice,
  getSelectedZone,
  getTickets,
  (places, price, zone, tickets) => {
    places = places.filter(place => {
      const ticket = getTicketById(tickets, place.ticket?.id)
      let isAvailable = place.capacity

      if (isPlaceReservableAsWhole(place) && place?.places?.some(item => !item?.capacity)) {
        isAvailable = 0
      }

      return isAvailable && place.ticket?.id && !saleEnded(ticket)
    })

    if (price) {
      if (price === DONATION_OPTION_ID) {
        places = places.filter(place => isDonationTicketDefinition(place.ticket))
      } else {
        places = places.filter(place => {
          if (hasPricingOptions(place.ticket)) {
            if (place.pricingOptionId) {
              const pricingOption = getPricingOption(place.ticket, place.pricingOptionId)
              return pricingOption.price.amount === price
            } else {
              const pricingOptions = getPricingOptions(place.ticket)
              return pricingOptions.some(pricingOption => pricingOption.price.amount === price)
            }
          } else if (isDonationTicketDefinition(place.ticket)) {
            return false
          } else {
            return place.ticket.price.amount === price
          }
        })
      }
    }

    if (zone) {
      places = places.filter(place => `${place.elementType}_${place.elementLabel}` === zone)
    }

    return places
  },
)

export const getFilteredPlacesCount = (state: State) => {
  const places = getFilteredPlaces(state)
  const placesCapacity = calculatePlacesCapacity(places)
  return Object.values(placesCapacity).reduce((sum, value) => sum + value, 0)
}
