import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {PaymentMethodsBanner, PaymentMethodsBannerProps} from '@wix/payment-methods-banner/lazy';
import s from './ProductPaymentBreakdown.scss';

export enum ProductPaymentBreakdownDataHook {
  ProductPaymentBreakdown = 'product-payment-breakdown',
}

@withGlobalProps
export class ProductPaymentBreakdown extends React.Component<ProvidedGlobalProps> {
  private readonly openModal: PaymentMethodsBannerProps['openModal'] = (url, width, height) => {
    return this.props.globals.handleOpenModal(url, {width, height});
  };

  public render(): JSX.Element {
    return (
      <div data-hook={ProductPaymentBreakdownDataHook.ProductPaymentBreakdown} className={s.root}>
        {<PaymentMethodsBanner {...this.props.globals.breakdownParams} openModal={this.openModal} />}
      </div>
    );
  }
}
