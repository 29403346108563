import {EVENT_FILTER_TYPE, ExperimentNames, RECURRING_FILTER, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {StatusFilter} from '@wix/events-types'
import {userLiveVideoStartRecordVideo} from '@wix/bi-logger-events-users/v2'
import {IWixStatic, OwnerLogger} from '@wix/yoshi-flow-editor'
import {createAsyncAction} from '../services/redux-toolkit'
import {WidgetSettingsState} from '../reducers'
import {getEventIdsByStatusFilter} from '../selectors/events'
import {getEventFilterType} from '../reducers/component-config'
import {setDefaultDimensions} from '../services/resize'
import {LoadEventsResponse} from '../../../../settings-commons/services/api'
import {getStatusFilter} from '../../utils/status-filter'
import settingsParams from '../../settingsParams'
import {WidgetSettingsEventsKeys} from '../../interfaces'
import {updateComponentDraft} from './component-config'
import {setActiveEvent, loadEvents} from './events'

interface SetEventsWidgetTypeParams {
  newWidgetType: WIDGET_TYPE
  recurringFilter: number
}

export const setEventsWidgetType = createAsyncAction<void, SetEventsWidgetTypeParams>(
  'SET_EVENTS_WIDGET_TYPE',
  async (
    {newWidgetType, recurringFilter},
    {getState, dispatch, extra: {bi, Wix, editorSDK, experiments, settings}},
  ) => {
    const state = getState()
    const singleEventWidget = newWidgetType === WIDGET_TYPE.SINGLE
    const newSelectManuallyEnabled = experiments.enabled(ExperimentNames.SelectEventsManually)
    let event

    editorSettingsMyEventsShowSetup({
      state,
      bi,
      singleEventWidget,
      recurringFilter,
      Wix,
    })

    setDefaultDimensions({Wix, singleEventWidget, editorSDK, state: getState()})

    if (newSelectManuallyEnabled || settings.get(settingsParams.filterType) !== undefined) {
      const newFilterType = singleEventWidget ? EVENT_FILTER_TYPE.MANUAL : EVENT_FILTER_TYPE.UPCOMING_AND_PAST

      settings.set(settingsParams.filterType, newFilterType)
      await dispatch(loadEvents({filterType: newFilterType, widgetType: newWidgetType, fromBeginning: true}))

      if (singleEventWidget) {
        event = getState().events.all[0]
        await dispatch(selectSingleWidgetEvent(event.id))
      }

      settings.publishEvent(WidgetSettingsEventsKeys.ReloadEvents, null)
    } else {
      event = getState().events.all[0]
      await dispatch(
        updateComponentDraft({
          byEventId: singleEventWidget ? {eventId: event ? [event.id] : []} : null,
          byStatus: !singleEventWidget ? {filter: StatusFilter.FUTURE_AND_PAST} : null,
        }),
      )
    }

    if (singleEventWidget) {
      dispatch(setActiveEvent({event, singleEventWidget, recurringFilter}))
    }
  },
)

interface SetEventFilterTypeParams {
  filterType: EVENT_FILTER_TYPE
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const setEventFilterType = createAsyncAction<void, SetEventFilterTypeParams>(
  'SET_EVENT_FILTER_TYPE',
  async ({filterType, multiEventWidget, calendar, recurringFilter}, {getState, dispatch, extra: {bi, Wix}}) => {
    const state = getState()
    const byStatus = getComponentConfigStatus(filterType)
    const byEventId = getComponentConfigEventId({
      state: getState(),
      filterType,
      multiEventWidget,
      calendar,
      recurringFilter,
    })
    const byCategoryId = state.componentConfig.byCategoryId

    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget: !multiEventWidget,
      recurringFilter,
      Wix,
    })

    await dispatch(
      updateComponentDraft(
        filterType !== EVENT_FILTER_TYPE.MANUAL && byCategoryId
          ? {byCategoryId: {categoryId: byCategoryId.categoryId, statusFilter: getStatusFilter(filterType)}}
          : {
              byStatus,
              byEventId,
            },
      ),
    )
  },
)

interface SetCategoryFilterParams {
  categoryId: string
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const setCategoryFilter = createAsyncAction<LoadEventsResponse, SetCategoryFilterParams>(
  'SET_CATEGORY_FILTER',
  async (
    {categoryId, calendar, multiEventWidget, recurringFilter},
    {getState, dispatch, extra: {bi, Wix, serverApi}},
  ) => {
    const state = getState()
    const filterType = getEventFilterType(state)
    const byStatus = getComponentConfigStatus(filterType)
    const byEventId = getComponentConfigEventId({state, filterType, calendar, multiEventWidget, recurringFilter})

    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget: !multiEventWidget,
      recurringFilter,
      categoryId,
      Wix,
    })

    const [, response] = await Promise.all([
      dispatch(
        updateComponentDraft(
          categoryId
            ? {
                byCategoryId: {categoryId: [categoryId], statusFilter: getStatusFilter(filterType)},
              }
            : {byStatus, byEventId},
        ),
      ),
      serverApi.loadEvents({offset: 0, categoryId: categoryId ? [categoryId] : undefined}),
    ])

    return response
  },
)

interface SetRecurringFilterParams {
  recurringFilter: RECURRING_FILTER
  singleEventWidget: boolean
}

export const setRecurringFilter = createAsyncAction<void, SetRecurringFilterParams>(
  'SET_RECURRING_FILTER',
  async ({recurringFilter, singleEventWidget}, {getState, extra: {bi, Wix}}) =>
    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget,
      recurringFilter,
      Wix,
    }),
)

const getComponentConfigStatus = (filterType: EVENT_FILTER_TYPE): wix.events.editor.ByStatus => {
  const isStatic = filterType === EVENT_FILTER_TYPE.MANUAL
  const filter = getStatusFilter(filterType)

  return isStatic ? undefined : {filter}
}

interface GetComponentConfigByEventIdParams {
  state: WidgetSettingsState
  filterType: EVENT_FILTER_TYPE
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

const getComponentConfigEventId = ({
  state,
  filterType,
  multiEventWidget,
  calendar,
  recurringFilter,
}: GetComponentConfigByEventIdParams) => {
  const isStatic = filterType === EVENT_FILTER_TYPE.MANUAL
  const filter = getStatusFilter(filterType)
  const eventId = getEventIdsByStatusFilter({state, filter, multiEventWidget, calendar, recurringFilter})

  return isStatic ? {eventId} : undefined
}

interface EditorSettingsMyEventsShowSetupsParams {
  state: WidgetSettingsState
  singleEventWidget: boolean
  recurringFilter: number
  eventId?: string
  categoryId?: string
  bi: OwnerLogger
  Wix: IWixStatic
}

export const editorSettingsMyEventsShowSetup = ({
  state,
  singleEventWidget,
  eventId,
  categoryId,
  bi,
  recurringFilter,
  Wix,
}: EditorSettingsMyEventsShowSetupsParams) => {
  let category_id =
    categoryId ?? (state.componentConfig.byCategoryId ? state.componentConfig.byCategoryId.categoryId[0] : null)
  const recurring_shown = recurringFilter === RECURRING_FILTER.ALL ? 'individual' : 'one'
  const event_id = eventId ?? singleEventWidget ? state.events.active?.id : null

  if (singleEventWidget) {
    category_id = null
  }

  bi.report(
    userLiveVideoStartRecordVideo({
      instance_id: Wix.Utils.getInstanceId(),
      buttonStatus: singleEventWidget ? 'single' : 'multiple',
      category_id,
      event_id,
      recurring_shown,
      events_shown: getEventsShown(state),
    }),
  )
}

const getEventsShown = (state: WidgetSettingsState) => {
  const statusFilter = state.componentConfig.byCategoryId
    ? state.componentConfig.byCategoryId.statusFilter
    : state.componentConfig.byStatus?.filter

  if (statusFilter === StatusFilter.FUTURE_AND_PAST) {
    return 'upcoming_past'
  }

  if (statusFilter === StatusFilter.FUTURE_ONLY) {
    return 'upcoming'
  }

  if (statusFilter === StatusFilter.PAST_ONLY) {
    return 'past'
  }

  return 'selected_manually'
}

export const selectSingleWidgetEvent = createAsyncAction(
  'SELECT_SINGLE_WIDGET_EVENT',
  async (eventId: string, {getState, extra: {serverApi, settings}}) => {
    const state = getState()
    const componentCategory = state.myEvents.componentCategory

    await serverApi.setSingleCategoryEvent({
      categoryId: componentCategory.id,
      eventId,
    })

    settings.publishEvent(WidgetSettingsEventsKeys.ReloadEvents, null)

    return {eventIds: eventId}
  },
)
