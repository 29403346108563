import React from 'react';
import cx from 'classnames';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {GalleryNavigationType, MarginBottom} from '../../../constants';
import {convertCssValueToConfig} from '../../../commons/utils';
import s from './SimpleLayout.scss';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {ProductPageButtonsContainer} from '../../ProductPageButtonsContainer/ProductPageButtonsContainer';
import {ProductPrice} from '../../ProductPrice/ProductPrice';
import {ProductPaymentBreakdown} from '../../ProductPaymentBreakdown/ProductPaymentBreakdown';

export const SimpleLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <article className={s.container}>
    <Cell className={s.width}>{settings.shouldShowNavigation && <Navigation className={s.navigation} />}</Cell>
    <header className={s.header}>
      <Cell className={s.gallery}>
        <ProductGalleryLayout
          product={product}
          layoutConfig={createLayoutConfigWithDefaults({
            marginBottom:
              /* istanbul ignore next: todo: test */ settings.navigationType === GalleryNavigationType.THUMBNAILS
                ? MarginBottom.LARGE
                : MarginBottom.SMALL,
            dimensions: {
              mainMedia: {
                widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                heightConf: convertCssValueToConfig(s.sharedStyleVariables_galleryHeight),
              },
              thumbnails: {
                widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                heightConf: {num: 50, unit: 'px'},
              },
            },
          })}
          {...settings}
        />
      </Cell>
    </header>
    <div className={cx(s.width, s.main)}>
      <section className={s.leftSection}>
        <Cell>
          <ProductName name={product.name} />
        </Cell>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}
        <Cell className={s.description}>
          <ProductDescription description={product.description} />
        </Cell>
        {settings.shouldShowInfoSection && (
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        )}
      </section>
      <section className={s.rightSection}>
        {settings.shouldShowPrice && (
          <Cell>
            <ProductPrice />
          </Cell>
        )}

        {settings.shouldShowProductPaymentBreakdown && (
          <Cell>
            <ProductPaymentBreakdown />
          </Cell>
        )}

        <Cell>
          <DetailsPlaceholder />
        </Cell>
        <Cell>
          <ProductOptions
            shouldShowQuantity={settings.shouldShowQuantity}
            shouldShowStockIndicator={settings.shouldShowStockIndicator}
          />
          <ProductPageButtonsContainer />
        </Cell>
        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    </div>
    <Cell>
      <BottomPlaceholder />
    </Cell>
  </article>
);
