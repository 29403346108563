import React from 'react'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'

interface VisibilityWrapperStateProps {
  visible: boolean
}

export function renderIfVisible<ComponentOwnProps>(
  Component: React.ComponentClass | React.SFC<ComponentOwnProps>,
  visibilityFunc: Function,
) {
  const VisibilityWrapper = ({visible, ...rest}: any) => (visible ? <Component {...rest} /> : null)

  const mapState = (props: AppProps, ownProps: any): VisibilityWrapperStateProps => ({
    visible: visibilityFunc(props.state, ownProps.event),
  })

  return connect<ComponentOwnProps, VisibilityWrapperStateProps>(mapState)(VisibilityWrapper)
}
