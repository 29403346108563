import {getPricingOption} from '@wix/wix-events-commons-statics'
import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {LabelWithValue} from '../../../common/label-with-value'
import {getPlaceInfoForOrderSummary} from '../../../../selectors/order-summary'
import {useCurrencyFormatter} from '../../../../../../../commons/hooks/currency'
import s from './place.scss'
import {PlaceProps} from './index'

export const Place = ({seatingPlan, ticket, seatId, priceOverride, pricingOptionId, currency}: PlaceProps) => {
  const {t} = useTranslation()
  const {getFormattedMoney} = useCurrencyFormatter()
  const {section, element, area, table, place} = getPlaceInfoForOrderSummary(seatId, seatingPlan)
  let price = Number(ticket.price.amount)
  let priceOptionName: string
  if (priceOverride) {
    price = Number(priceOverride)
  } else if (pricingOptionId) {
    const priceOption = getPricingOption(ticket, pricingOptionId)
    price = Number(priceOption.price.amount)
    priceOptionName = priceOption.name
  }

  return (
    <div className={s.container}>
      <div className={s.placeInfo}>
        <LabelWithValue label={t('seatings_sector')} value={section.title} />
        {area ? (
          <LabelWithValue label={t('seatings_area')} value={element.title} />
        ) : (
          <>
            <LabelWithValue label={table ? t('seatings_table') : t('seatings_row')} value={element.title} />
            <LabelWithValue label={t('seatings_seat')} value={place.label} />
          </>
        )}
      </div>
      <div className={s.ticketInfo}>
        {priceOptionName ? (
          <div className={s.ticketNameContainer}>
            <div className={s.priceOptionTicketName}>{ticket.name}</div>
            <div>{priceOptionName}</div>
          </div>
        ) : (
          <span>{ticket.name}</span>
        )}
        <span className={s.ticketPrice}>{getFormattedMoney({amount: price.toString(), currency})}</span>
      </div>
    </div>
  )
}
