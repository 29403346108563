import {TFunction} from '@wix/yoshi-flow-editor'
import {calculateSubPrice} from '../../components/details-page/Widget/services/payment'
import {getTicketPriceWithoutTax} from './tickets'

export interface InvoiceItem {
  id: string
  ticketDefinitionId: string
  quantity: number
  price: wix.events.Money
  name: string
  ticketDetails: wix.events.ticketing.TicketDetails
  total: wix.events.Money
}

const calculateTotal = (item: InvoiceItem): wix.events.Money => ({
  amount: calculateSubPrice(Number(item.price.amount), item.quantity).toString(),
  currency: item.price.currency,
})

export const getGroupedInvoiceItems = (order: wix.events.ticketing.Order): InvoiceItem[] => {
  return order.tickets
    .reduce((groupedTickets, ticket) => {
      const existingTicket = groupedTickets.find(
        groupedTicket =>
          groupedTicket.ticketDefinitionId === ticket.ticketDefinitionId &&
          groupedTicket.ticketDetails?.pricingOptionId === ticket.ticketDetails?.pricingOptionId,
      )
      if (existingTicket) {
        existingTicket.quantity += 1
      } else {
        groupedTickets.push({
          id: ticket.ticketDetails?.pricingOptionId
            ? `${ticket.ticketDefinitionId}_${ticket.ticketDetails.pricingOptionId}`
            : ticket.ticketDefinitionId,
          quantity: 1,
          price: getTicketPriceWithoutTax(order, ticket),
          name: ticket.name,
          ticketDefinitionId: ticket.ticketDefinitionId,
          ticketDetails: ticket.ticketDetails,
          total: null,
        })
      }
      return groupedTickets
    }, [] as InvoiceItem[])
    .map(item => ({...item, total: calculateTotal(item)}))
}

export const getLabeledItemTicketPrice = (
  item: InvoiceItem,
  t: TFunction,
  getTicketPriceText: (price: wix.events.Money) => string,
) => {
  let ticketPrice = getTicketPriceText(item.price)
  if (item.ticketDetails?.pricingOptionName) {
    ticketPrice = `${item.ticketDetails.pricingOptionName} ${ticketPrice}`
  }

  return ticketPrice
}
