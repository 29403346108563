export enum Experiment {
  EnableSectionsTabInMobileAS = 'specs.membersAboutOOI.enableSectionsMobileAS',
  EnableOOIInEditor = 'specs.membersArea.framelessEditorProfilePage',
  UseWarmupData = 'specs.membersAboutOOI.useWarmupData',
  RenderSEOTags = 'specs.membersAboutOOI.RenderSEOTags',
  EnableGeneralSettings = 'specs.membersArea.generalSettings',
  EnableGeneralSettingsViewer = 'specs.membersArea.generalSettingsViewer',
  EnableRceNext = 'specs.membersAboutOOI.enableRceNext',
  UseErrorToast = 'specs.membersAboutOOI.UseErrorToasts',
  AboutPublishValidation = 'specs.about.MembersAboutSpecs$ValidateLinksInAboutContent',
}
