/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import {Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9, Icon10, Icon11, IconProps} from './Icons/Icons';
import classNames from 'classnames';
import s from './CartIconApp.scss';
import {ICartIconSantaProps, ICtrlProps} from '../../types/app-types';
import md5 from 'md5';
import {resizeComponent} from '../../utils/wixSdkUtils';

export type ICartIconAppProps = ICtrlProps & ICartIconSantaProps;

const icons: {[key: string]: React.ComponentType<IconProps>} = {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
};

export class CartIconApp extends React.Component<ICartIconAppProps> {
  private readonly anchorRef: React.RefObject<HTMLAnchorElement>;

  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();

    this.onClick = this.onClick.bind(this);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
    this.resizeComponent = this.resizeComponent.bind(this);
  }

  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: ICartIconAppProps): void {
    if (this.props.triggerFocus !== prevProps.triggerFocus) {
      this.triggerFocus();
    }
  }

  private reportAppLoaded() {
    if (this.props.isInteractive) {
      this.safeRun(this.props.onAppLoaded);
    }
  }

  private renderIcon(iconId: number, count: number, text: string) {
    const IconComponent = icons[`Icon${iconId}`];
    const {
      viewMode,
      dimensions: {width, height},
    } = this.props.host;
    const aspectRatio = viewMode === 'Site' ? width / height : undefined;

    return (
      <IconComponent
        iconId={iconId}
        count={count}
        text={text}
        s={s}
        aspectRatio={aspectRatio}
        resizeComponent={this.resizeComponent}
        containerHeight={this.props.host.dimensions.height}
      />
    );
  }

  private onClick(event) {
    event.preventDefault();
    if (!this.props.isNavigate) {
      event.stopPropagation();
    }
    this.safeRun(this.props.onIconClick);
  }

  private resizeComponent(width: number) {
    if (this.props.host.viewMode === 'Editor') {
      // Instead of host.resizeComponent the Wix SDK is used following this thread
      // https://wix.slack.com/archives/C4KPAQ33K/p1574331075103900?thread_ts=1574254301.100100&cid=C4KPAQ33K
      return resizeComponent({
        width,
        height: null,
        mobileResize: true,
      });
    }
    return Promise.resolve();
  }

  private triggerFocus() {
    this.anchorRef.current && this.anchorRef.current.focus();
    this.safeRun(this.props.onFocusTriggered);
  }

  private safeRun(fn) {
    if (this.props.isInteractive && typeof fn === 'function') {
      fn();
    }
  }

  private componentKey() {
    const {
      host: {
        style: {
          styleParams: {fonts},
        },
      },
    } = this.props;
    return `cart-icon-${md5(JSON.stringify(fonts))}`;
  }

  public render(): React.ReactNode {
    if (!this.props.isLoaded) {
      return null;
    }

    const {ariaLabelLink, cartLink, count, displayText, isNavigate} = this.props;
    const {style} = this.props.host;
    const iconId: number = (style && style.styleParams.numbers.cartWidgetIcon) || 1;
    const classes = classNames(s.cartIconDefaults, s[`cart-icon-${iconId}`], s.cartIconButtonContainer, {
      [s.dozens]: count >= 10 && count <= 99,
      [s.hundreds]: count >= 100,
    });

    return (
      <a
        key={this.componentKey()}
        aria-label={ariaLabelLink}
        className={classes}
        data-hook="cart-icon-button"
        href={cartLink}
        onClick={this.onClick}
        ref={this.anchorRef}
        role={isNavigate ? 'link' : 'button'}>
        {this.renderIcon(iconId, count, displayText)}
      </a>
    );
  }
}
