import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { PageAlignment } from '../../types';
import { TextFieldTheme } from 'wix-ui-tpa';

export type ISettingsParams = {
  pageAlignment: SettingsParamType.String;
  pageSectionTitle: SettingsParamType.String;
  pageSectionSubtitle: SettingsParamType.String;
  publicInfoSectionTitle: SettingsParamType.String;
  publicInfoSectionSubtitle: SettingsParamType.String;
  accountSectionTitle: SettingsParamType.String;
  accountSectionSubtitle: SettingsParamType.String;
  socialSectionTitle: SettingsParamType.String;
  saveButtonText: SettingsParamType.String;
  discardButtonText: SettingsParamType.String;
  inputFieldsTheme: SettingsParamType.String;
  avatarVisibility: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  pageAlignment: {
    type: SettingsParamType.String,
    getDefaultValue: () => PageAlignment.left,
  },
  inputFieldsTheme: {
    type: SettingsParamType.String,
    getDefaultValue: () => TextFieldTheme.Box,
  },
  pageSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.header.title'),
  },
  pageSectionSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.header.subtitle'),
  },
  publicInfoSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.sections.public.title'),
  },
  publicInfoSectionSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.sections.public.subtitle'),
  },
  accountSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.sections.account.title'),
  },
  accountSectionSubtitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.sections.account.subtitle'),
  },
  socialSectionTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.sections.social.title'),
  },
  saveButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.save'),
  },
  discardButtonText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.widget.discard'),
  },
  avatarVisibility: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
});
