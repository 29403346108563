import {EcomComponent} from '@wix/ecom-platform-sdk';

export class EcomPlatformInstallationState {
  private state: {[key: string]: EcomComponent[]} = {};

  public addInstalledApp(appDefId: string, installedComponents: EcomComponent[]) {
    this.state[appDefId] = installedComponents;
  }

  public getInstalledAppComponents(appDefId: string) {
    return this.state[appDefId];
  }

  public removeInstalledApp(appDefId: string) {
    if (this.state.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.state[appDefId];
    }
  }

  public hasInstalledApps() {
    return !!this.getInstalledAppDefIds().length;
  }

  public isMoreThanOneAppInstalled() {
    return this.getInstalledAppDefIds().length > 1;
  }

  public getInstalledAppDefIds() {
    return Object.keys(this.state);
  }
}
