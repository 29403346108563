export enum DataHook {
  WidgetWrapper = 'Profile-Wrapper',
  EditProfileButton = 'Profile-EditProfileButton',
  LikesReceivedStats = 'Profile-LikesReceivedStats',
  CommentsStats = 'Profile-CommentsStats',
  TopCommentsStats = 'Profile-TopCommentsStats',
  AboutOverlay = 'Profile-AboutOverlay',
  AboutEditModal = 'Profile-AboutEditModal',
  CreatePostLink = 'Profile-CreatePostLink',
  WriteYourFirstPostLink = 'Profile-WriteYourFirstPostLink',
  Post = 'Profile-Post',
  PostsLoader = 'Profile-PostsLoader',
  LoadMorePostsButton = 'Profile-LoadMorePostsButton',
  Toast = 'Profile-Toast',
  RceLinkButton = 'LinkButton',
  RceLinkPanelInput = 'linkPanelInput',
  RceLinkPanelActionButtonSave = 'actionButtonSave',
  RcePanelContainer = 'linkPanelContainer',
  RceFloatingToolbar = 'inlineToolbar',
  RceLink = 'linkViewer',
  AboutInputDialogPrimaryButton = 'InputDialog-primaryButton',
}
