import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {CustomDatesLabel} from '../custom-dates-label'
import {useEventDateInformation} from '../../../../../../commons/hooks/dates'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import s from './secondary-rsvp-button.scss'
import {SecondaryRsvpButtonProps} from './index'

export const SecondaryRsvpButton = ({
  componentSettings,
  buttonStyle,
  onClick,
  className,
  url,
  isExternalLink,
  visible,
  registrationWithCustomDates,
  eventId,
  t,
}: SecondaryRsvpButtonProps) => {
  const {formattedRegistrationEndDate: date} = useEventDateInformation(eventId)
  const settings = useSettings(componentSettings)
  const rsvpButtonText = settings.getWithFallback(settingsParams.rsvpEventButtonText, settingsParams.rsvpButtonText)
  if (!visible) {
    return null
  }

  return (
    <div className={classNames(s.spacing, className)}>
      <RegistrationButtonSkin
        secondary={true}
        buttonStyle={buttonStyle}
        text={rsvpButtonText}
        onClick={onClick}
        url={url}
        isExternalLink={isExternalLink}
        dataHook={DH.RSVP_BUTTON}
      />
      {registrationWithCustomDates && (
        <CustomDatesLabel text={t('registration.closesLabel', {date})} dataHook={DH.RSVP_CLOSES_LABEL} />
      )}
    </div>
  )
}
