import React from 'react'
import {SocialShare} from '../index'
import {Subtitle} from '../../subtitle'
import s from '../social-share.scss'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {SocialShareWithTitleProps} from './index'

export const SocialShareWithTitle: React.FC<SocialShareWithTitleProps> = ({componentSettings, isVisible, t, page}) => {
  const settings = useSettings(componentSettings)
  const shareTitleText = settings.get(settingsParams.shareTitleText)

  return isVisible ? (
    <div>
      <Subtitle dataHook="social-share" text={shareTitleText} />
      <div className={s.container}>
        <SocialShare t={t} page={page} />
      </div>
    </div>
  ) : null
}
