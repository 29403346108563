import { createSlice } from '@reduxjs/toolkit';
import { Badge } from '@wix/ambassador-badges-v2-badge/types';

import { fetchAssignedBadges } from './thunk';

export type State = Badge[];

const initialState: State = [];

export const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssignedBadges.fulfilled, (state, action) => {
      state = action.payload.badges;
    });
  },
});
