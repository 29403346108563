import React, { FC, MouseEventHandler } from 'react';
import classNames from 'classnames';

import { InjectedClassNameProps, InjectedDataHookProps } from '../../../types';
import styles from './Button.scss';

export type ButtonClickHandler = MouseEventHandler<HTMLButtonElement>;

interface ButtonProps extends InjectedClassNameProps, InjectedDataHookProps {
  ariaLive?: 'off' | 'assertive' | 'polite';
  isMenuItem?: boolean;
  isDisabled?: boolean;
  onClick: ButtonClickHandler;
}

const Button: FC<ButtonProps> = ({
  className = '',
  dataHook,
  ariaLive,
  isMenuItem = false,
  isDisabled = false,
  children,
  onClick,
}) => (
  <button
    className={classNames(
      styles.btn,
      className,
      isMenuItem ? styles.menuItem : undefined,
    )}
    data-hook={dataHook}
    type="button"
    role={isMenuItem ? 'menuitem' : undefined}
    tabIndex={isMenuItem ? -1 : 0}
    aria-live={ariaLive}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
