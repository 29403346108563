import {getEventUrl} from '../../../../../commons/selectors/event'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {SocialShare as Presentation} from './social-share'
import {SocialShareOwnProps, SocialShareRuntimeProps} from './index'

const mapRuntime = ({state, pageUrl, actions: {shareEvent}}: DetailsPageAppProps): SocialShareRuntimeProps => {
  const {multilingual, event} = state
  return {
    eventUrl: getEventUrl(pageUrl, event, multilingual),
    shareEvent,
  }
}

export const SocialShare = connect<SocialShareOwnProps, SocialShareRuntimeProps>(mapRuntime)(Presentation)

export * from './interfaces'
