/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import s from './MobileSort.scss';
import classNames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

import {sortOptions} from '../../../constants';
import {ArrowDown, Arrows} from '../../../icons/dist';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface MobileSortProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  areFiltersEnabled: boolean;
}

class MobileSortComp extends React.Component<MobileSortProps> {
  private onChange(event) {
    const sortingOption = sortOptions.find((option) => option.id === event.target.value);
    this.props.globals.sortProducts(sortingOption);
  }

  private onClick() {
    this.props.globals.sendSortClickBiEvent();
  }

  public render() {
    const {selectedSort, sortingOptions} = this.props.globals;

    return (
      <div className={classNames(s.wrapper, {[s.withFilters]: this.props.areFiltersEnabled})}>
        <span className={classNames(s.selectButton, {[s.centered]: this.props.areFiltersEnabled})}>
          {this.props.areFiltersEnabled && <Arrows size="32" className={s.arrows} />}
          {!this.props.areFiltersEnabled && <ArrowDown className={s.arrowDown} />}
        </span>
        <select
          onClick={() => this.onClick()}
          data-hook="select-sort"
          id={'sortBy'}
          onChange={(event) => this.onChange(event)}
          className={classNames({[s.withoutFilters]: !this.props.areFiltersEnabled}, s.mobileSortSelect)}
          value={selectedSort.id}>
          {sortingOptions.map((o) => {
            return (
              <option key={o.id} data-hook="option" value={o.id}>
                {this.props.t(o.titleKey)}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export const MobileSort = withGlobals(withTranslations()(MobileSortComp));
