import {GalleryApp} from '../../../gallery/components/GalleryApp/GalleryApp';
import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {getWrappedComponent} from '../../../appConfig';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME} from '../../../constants';

export default getWrappedComponent({
  Component: GalleryApp,
  withStyleStrategy: withStyles,
  withExperimentAttribute: USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME,
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
