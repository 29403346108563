import {useOwnerBi, useWixSdk} from '@wix/yoshi-flow-editor'
import _ from 'lodash'
import {editorEventsWidgetSettingChanged} from '@wix/bi-logger-events-users/v2'
import {useMemo} from 'react'

export enum WidgetType {
  EventsWidget = 'Events Widget',
  EventDetails = 'Event Details',
  EventsMembersPage = 'Events Members Page',
  EventSchedule = 'Event Schedule',
}

export const useSettingsBi = (widget_type: WidgetType) => {
  const bi = useOwnerBi()
  const {Wix} = useWixSdk()
  const map = useMemo(() => ({}), [])

  const editorEventsWidgetSettingChangedHandler = (setting_name: string, value: string) =>
    bi.report(
      editorEventsWidgetSettingChanged({
        editor_working_mode: Wix.Utils.getDeviceType(),
        instance_id: Wix.Utils.getInstanceId(),
        widget_type,
        setting_name,
        value,
      }),
    )

  return {
    debouncedEditorEventsWidgetSettingChanged: (settings_name: string, value: string) => {
      if (!(settings_name in map)) {
        map[settings_name] = _.debounce(editorEventsWidgetSettingChangedHandler, 2000)
      }
      map[settings_name](settings_name, value)
    },
  }
}
