import {TFunction} from '@wix/yoshi-flow-editor'
import {isDateVisible} from '../../../../selectors/list-settings'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {Date as DatePresentation} from './date'

export interface DateProps {
  t: TFunction
  customColorClass?: string
  size: number
  event: wix.events.Event
  scheduleTbd: boolean
}

export const Date = renderIfVisible<DateProps>(DatePresentation, isDateVisible)
