import {
  useExperiments,
  useCurrencyFormatter as useInfraCurrencyFormatter,
  useTranslation,
  FlowAPI,
} from '@wix/yoshi-flow-editor'
import {GetFormattedMoney, getFormattedMoney, ExperimentNames} from '@wix/wix-events-commons-statics'
import {usePageStateSelector} from '../../components/details-page/Widget/hooks/state'
import {CommonState} from '../types/state'
import {getCountry} from '../selectors/environment'

export const getWorkerCurrencyFormatter = (flowApi: FlowAPI, state: CommonState) => {
  const {t} = flowApi.translations
  const enabled = flowApi.experiments.enabled(ExperimentNames.UseInfraCurrencyFormatter)
  const country = getCountry(state)
  const formatCurrency = flowApi.getCurrencyFormatter({notation: 'standard', unitDisplay: 'narrow', country})

  return getCurrencyFormatter({formatCurrency, t, enabled})
}

const getCurrencyFormatter = ({enabled, t, formatCurrency}) => {
  if (enabled) {
    return {
      getFormattedMoney: (price: wix.events.Money): string => {
        if (!price.currency) {
          return price.amount
        }
        return formatCurrency({value: price.amount, currency: price.currency})
      },
      getTicketPriceText: (price: wix.events.Money): string => {
        const amount = Number(price.amount)

        if (!amount) {
          return t('ticketPrice.free')
        }

        if (!price.currency) {
          return amount.toString()
        }

        return formatCurrency({value: price.amount, currency: price.currency})
      },
    }
  } else {
    return {
      getTicketPriceText: (price: wix.events.Money) => {
        return Number(price.amount) ? getFormattedMoney(price) : t('ticketPrice.free')
      },
      getFormattedMoney,
    }
  }
}

export const useCurrencyFormatter = () => {
  const {experiments} = useExperiments()
  const state = usePageStateSelector(s => s)
  const country = getCountry(state)
  const formatCurrency = useInfraCurrencyFormatter({notation: 'standard', unitDisplay: 'narrow', country})
  const {t} = useTranslation()
  const enabled = experiments.enabled(ExperimentNames.UseInfraCurrencyFormatter)

  return getCurrencyFormatter({enabled, t, formatCurrency})
}

export type GetTicketPriceText = (price: wix.events.Money) => string

export type PriceFormatters = {getTicketPriceText: GetTicketPriceText; getFormattedMoney: GetFormattedMoney}
