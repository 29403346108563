import {getEventId} from '@wix/wix-events-commons-statics'
import {getReservationId} from '../selectors/reservation'
import {getSelectedPaidPlan} from '../selectors/paid-plans'
import {GetState} from '../types'
import {isShowCouponInputInPaymentStepEnabled} from '../../../../commons/selectors/experiments'
import {isOrderCompleted} from '../selectors/placed-order'
import {getDiscount} from './checkout'
import {updateOrder} from './placed-order'

export const SUBMIT_COUPON = 'SUBMIT_COUPON'
export const RESET_COUPON_CODE = 'RESET_COUPON_CODE'
export const COUPON_APPLIED = 'COUPON_APPLIED'

export const resetCouponCode = () => (dispatch: Function, getState: GetState) => {
  dispatch({
    type: RESET_COUPON_CODE,
  })

  const state = getState()

  if (isShowCouponInputInPaymentStepEnabled(state) && isOrderCompleted(state.placedOrder.order)) {
    dispatch(updateOrder({buyer: state.checkout.buyerDetails, guests: state.checkout.ticketsDetails}))
  }

  if (getSelectedPaidPlan(state)) {
    dispatch(getDiscount({eventId: getEventId(state.event), reservationId: getReservationId(state)}))
  }
}

export const submitCoupon = (couponCode: string) => ({
  type: SUBMIT_COUPON,
  payload: couponCode,
})

export const couponApplied = () => ({
  type: COUPON_APPLIED,
})

export const applyCoupon = (eventId: string, reservationId: string, couponCode: string) => (dispatch: Function) => {
  dispatch(submitCoupon(couponCode))
  return dispatch(getDiscount({eventId, reservationId}))
}
