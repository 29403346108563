import * as yup from 'yup';
import { TFunction } from '@wix/yoshi-flow-editor';

import { FieldType } from '../../../../types';

const EMAIL_LABEL = 'Email';

export const getValidationSchema = (t: TFunction) =>
  yup.object().shape({
    fields: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        type: yup.string(),
        label: yup.string(),
        required: yup.boolean(),
        value: yup
          .mixed()
          .when('type', {
            is: FieldType.NUMBER,
            then: yup.number(), // Validation is done using Html validation state in Number Field, because number html input does not return invalid input values
          })
          .when('type', {
            is: (type: FieldType) =>
              [FieldType.SOCIAL, FieldType.URL].includes(type),
            then: yup
              .string()
              .url(t('app.widget.fields.validation.invalid-url')),
          })
          .when('label', {
            is: EMAIL_LABEL,
            then: yup
              .string()
              .email(t('app.widget.fields.validation.invalid-email')),
          })
          .when('required', {
            is: true,
            then: yup
              .string()
              .required(t('app.widget.fields.validation.required')),
          }),
      }),
    ),
  });
