import galleryStyle from '../../GalleryApp/GalleryApp.scss';
import {MobileSort} from '../../MobileSort/MobileSort';
import React from 'react';
import {DataHook} from '../../../../category/components/CategoriesApp/CategoriesApp';
import {MobileFilters} from '../../MobileFilters/MobileFilters';
import {IGlobals} from '../../../galleryGlobalStrategy';
import {BrowseMobile} from '../../BrowseMobile/BrowseMobile';
import s from './MobileFiltersInLayout.scss';

export interface MobileFiltersInLayoutProps {
  isSplitView?: boolean;
}

export const MobileFiltersInLayout = ({
  filterModels,
  shouldShowSort,
  isSplitView = false,
}: MobileFiltersInLayoutProps & IGlobals) => {
  const shouldShowFilters = filterModels.length > 0;

  const getFiltersView = () => {
    return (
      <div className={galleryStyle.mobileFiltersAndSort} data-hook={DataHook.MobileContainer}>
        {shouldShowFilters && <MobileFilters isSplitView={isSplitView} />}
        {shouldShowSort && <MobileSort areFiltersEnabled={shouldShowFilters} />}
      </div>
    );
  };

  const getSplitView = () => {
    return (
      <>
        {shouldShowFilters && <BrowseMobile />}
        <div className={s.restOfFilters}>{getFiltersView()}</div>
      </>
    );
  };

  return isSplitView ? getSplitView() : getFiltersView();
};
