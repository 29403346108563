import {getEventId, EVENTS_APP_ID, EVENTS_SECTION_ID, getSlug, isTicketed} from '@wix/wix-events-commons-statics'
import {createAction} from '@reduxjs/toolkit'
import {getPricingPlansPublicAPI} from '@wix/pricing-plans-tpa-api'
import {DETAILS_ROUTE} from '../../../../commons/constants/navigation'
import {GetState, StoreExtraArgs} from '../types'
import {
  getPurchasedPlanByOrderId,
  planHasRemainingCredits,
  getNotPurchasedPlanIds,
  getPaidPlansSectionUrl,
} from '../selectors/paid-plans'
import {isMobile} from '../../../../commons/selectors/environment'
import {createAsyncAction} from '../services/redux-toolkit'
import {getDiscount} from './checkout'

export const getPlanList = createAsyncAction(
  'GET_PLAN_LIST',
  async (_, {getState, extra: {serverApi, wixCodeApi}}) => {
    const [{plans}, {getTranslatedPricingDetails}] = await Promise.all([
      serverApi.getPlanList(getEventId(getState().event)),
      getPricingPlansPublicAPI(wixCodeApi),
    ])

    return {
      plans,
      translatedDetails: plans.reduce((prev, curr) => {
        if (!curr.plan) {
          return prev
        }

        return {
          ...prev,
          [curr.plan.id]: getTranslatedPricingDetails(curr.plan as any),
        }
      }, {}),
    }
  },
  {condition: (_, {getState}) => isTicketed(getState().event)},
)

interface ApplyPlanParams {
  eventId: string
  reservationId: string
  planOrderId: string
}

export const applyPlan = createAsyncAction<void, ApplyPlanParams>(
  'APPLY_PLAN',
  async ({eventId, reservationId, planOrderId}, {getState, dispatch}) => {
    const targetPlan = getPurchasedPlanByOrderId(getState(), planOrderId)
    const hasRemainingCredits = planHasRemainingCredits(targetPlan)

    dispatch(setSelectedPlanOrderId(targetPlan && hasRemainingCredits ? planOrderId : undefined))
    dispatch(getDiscount({eventId, reservationId}))
  },
)

export const openMembershipPicker =
  ({titleText, contentText, buttonText}: OpenMembershipPickerArgs) =>
  async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
    const state = getState()
    const slug = getSlug(state.event)
    const returnUrl = isMobile(state) ? `${slug}/${DETAILS_ROUTE.PICK_TICKETS}` : slug

    const queryParams = {
      originAppDefinitionId: EVENTS_APP_ID,
      planIds: getNotPurchasedPlanIds(state).join(','),
      navigateToSectionProps: btoa(
        JSON.stringify({
          appDefinitionId: EVENTS_APP_ID,
          sectionId: EVENTS_SECTION_ID,
          state: returnUrl,
        }),
      ),
      verticalStatusContent: undefined,
    }

    try {
      queryParams.verticalStatusContent = btoa(
        JSON.stringify({
          titleText,
          contentText,
          buttonText,
        }),
      )
    } catch (error) {
      console.log(error)
    }

    const path = `?appSectionParams=${encodeURIComponent(JSON.stringify(queryParams))}`
    const sectionUrl = await getPaidPlansSectionUrl(wixCodeApi)

    wixCodeApi.location.to(`${sectionUrl}${path}`)
  }

export const setSelectedPlanOrderId = createAction<string>('SET_SELECTED_PLAN_ORDER_ID')

interface OpenMembershipPickerArgs {
  titleText: string
  contentText: string
  buttonText: string
}
