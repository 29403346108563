import { Field, FieldType } from '../../../../types';

export const getChangedFields = (
  currentValues: Field[],
  initialValues: Field[],
) =>
  currentValues.filter((field, idx) => {
    if (field.type === FieldType.ADDRESS) {
      const arrayDifference: Field[] = getChangedFields(
        initialValues[idx].value,
        field.value,
      );
      return !!arrayDifference.length;
    }
    if (field.type === FieldType.DROPDOWN) {
      return initialValues[idx].value?.selectedId !== field.value?.selectedId;
    }
    return initialValues[idx].value !== field.value;
  });

export const getFieldsWithChangedPrivacy = (
  currentValues: Field[],
  initialValues: Field[],
) =>
  currentValues.filter(
    (field, idx) => initialValues[idx].privacy !== field.privacy,
  );
