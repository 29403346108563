import {useTranslation} from '@wix/yoshi-flow-editor'
import {translateDonationAbcKey} from '@wix/wix-events-commons-statics'
import {usePageStateSelector} from './state'

export type TranslateDonationAbcKeyType = (translationKey: string, options?: {}) => string

// delete this during specs.events.ui.DonationText-ABC merge
export const useDonationsAbcTranslation = () => {
  const {t} = useTranslation()
  const siteSettings = usePageStateSelector(s => s.siteSettings)
  const donationsContentValue = siteSettings.settings?.donationsContentValue

  return {
    translateDonations: (translationKey: string, options = {}) =>
      t(translateDonationAbcKey(translationKey, donationsContentValue), options),
  }
}
