import {isMobile} from '../../../../../commons/selectors/environment'
import {
  getCardMargins,
  getListStripWidth,
  isCardsLayout,
  isListLayout,
  getComponentConfig,
} from '../../selectors/settings'
import {isMainTitleVisible} from '../../selectors/list-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Title as TitlePresentation} from './title'
import {TitleStateProps} from './interfaces'

const mapRuntimeToContext = ({state}: AppProps): TitleStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    listLayout: isListLayout(componentConfig),
    cardsLayout: isCardsLayout(componentConfig),
    listStripWidth: getListStripWidth(componentConfig),
    mobile: isMobile(state),
    visible: isMainTitleVisible(state),
    cardMargins: getCardMargins(componentConfig),
    settings: componentConfig.settings,
  }
}

export const Title = connect<{}, TitleStateProps>(mapRuntimeToContext)(TitlePresentation)
export * from './interfaces'
