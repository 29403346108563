import {
  Origin,
  Privacy,
  Section as FieldSection,
} from '@wix/ambassador-members-v1-custom-field/types';
import { CustomField as MemberCustomField } from '@wix/ambassador-members-v1-member/types';
import { TextFieldTheme, DropdownOptionProps } from 'wix-ui-tpa';

export enum RequiredFieldKey {
  EMAIL = 'customfields.system.email',
}

export enum PublicInfoFieldId {
  DISPLAY_NAME = 'display-name-id',
  TITLE = 'title-id',
}

export enum PublicInfoFieldKey {
  DISPLAY_NAME = 'app.widget.fields.display-name',
  TITLE = 'customfields.system.title',
}

export { FieldSection };

export { Privacy as FieldPrivacy };

export { Origin as FieldOrigin };

export enum FieldType {
  UNKNOWN = 'UNKNOWN',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  URL = 'URL',
  SOCIAL = 'SOCIAL',
  ADDRESS = 'ADDRESS',
  DROPDOWN = 'DROPDOWN',
}

export type Field = {
  id: string;
  key: string;
  label: string;
  fieldOrigin: Origin;
  type: FieldType;
  required: boolean;
  privacy: Privacy;
  section: FieldSection;
  isPrivacyEditable: boolean;
  infoTooltipText?: string;
  index?: number;
  value: MemberCustomField['value'] | AddressSubfield[];
};

export type DropdownValue = {
  options: DropdownOptionProps[];
  selectedId: DropdownOptionProps['id'];
};

export type AddressSubfield = {
  id: string;
  addressId: string;
  key: string;
  type: FieldType;
  infoTooltipText?: string;
  value: string | DropdownValue | undefined | null;
};

export interface FieldWrapper {
  formikFieldSelector: string;
  theme?: TextFieldTheme;
}

export interface UpdateFieldsProps {
  fields: Field[];
}
