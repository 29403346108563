import { useCallback, useEffect } from 'react';

export const useBeforeUnload = (enabled: boolean) => {
  const handler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (enabled) {
        event.preventDefault();
        /* https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
         preventDefault not supported everywhere, so we need to return*/
        return (event.returnValue = '');
      }
    },
    [enabled],
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    window.addEventListener('beforeunload', handler);

    return () => window.removeEventListener('beforeunload', handler);
  }, [enabled, handler]);
};
