import {applyMiddleware, createStore, PreloadedState, Middleware, Reducer, Store} from 'redux'
import thunk from 'redux-thunk'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {State} from '../../components/details-page/Widget/types'

interface StoreConfig<State, StoreExtraArgs> {
  reducers: Reducer
  initialData: PreloadedState<State>
  extraArguments: StoreExtraArgs
  middleware: Middleware[]
}

export const createReduxStore = <State, StoreExtraArgs>({
  reducers,
  initialData,
  extraArguments,
  middleware,
}: StoreConfig<State, StoreExtraArgs>) =>
  createStore(reducers, initialData, applyMiddleware(thunk.withExtraArgument(extraArguments), ...middleware))

export const subscribeToStateChanges = (controller: ControllerParams['controllerConfig'], store: Store) => {
  let state: State

  const onStateChange = () => {
    const newState = store.getState()
    if (newState !== state) {
      controller.setProps({state: newState})
      state = newState
    }
  }

  store.subscribe(onStateChange)
}
