import { createAsyncThunk } from '@reduxjs/toolkit';
import { Badge } from '@wix/ambassador-badges-v2-badge/types';

import { ThunkApiConfig } from '../../../types';
import { refetchMemberBadges } from '../../../server/profile.api';

interface FetchAssignedBadgesResponse {
  badges: Badge[];
  assignedBadges: Badge[];
}

export const fetchAssignedBadges = createAsyncThunk<
  FetchAssignedBadgesResponse,
  string[],
  ThunkApiConfig
>(
  'badges/fetchAssignedBadges',
  async (assignedBadgeIds, { extra: { flowAPI } }) => {
    const { httpClient } = flowAPI;

    const { data } = await httpClient.request(
      refetchMemberBadges({ assignedBadgeIds }),
    );

    return data;
  },
);
