import {DetailsPageAppProps} from '../../../../app/interfaces'
import {getButtonStyle, isHollowButton} from '../../../../../selectors/settings'
import {connect} from '../../../../runtime-context/context'
import {ExpansionButton as Presentation} from './expansion-button'
import {ExpansionButtonOwnProps, ExpansionButtonRuntimeProps} from './interfaces'

export const mapRuntime = ({state}: DetailsPageAppProps): ExpansionButtonRuntimeProps => ({
  isHollowButton: isHollowButton(getButtonStyle(state.component.settings)),
})

export const ExpansionButton = connect<ExpansionButtonOwnProps, ExpansionButtonRuntimeProps>(mapRuntime)(Presentation)
