import * as React from 'react';
import classNames from 'classnames';
import s from './ProductImageOld.scss';
import {IImageDimension} from '../../../../types/productDef';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import {ProductImage as Image} from '@wix/wixstores-client-common-components/dist/es/src/ProductImage/ProductImage';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {IMediaItem} from '../../../../types/app-types';

export interface ProductImageItemProps extends ProvidedGlobalProps {
  mediaItem: IMediaItem;
  imageMode: ImageMode;
  productName: string;
  dimensions: IImageDimension;
  mountedToDOM: boolean;
  isSelected: boolean;
  imageLoaded(): any;
}

export interface ProductImageItemState {
  imageLoaded: boolean;
}

export enum DataHook {
  ProductImageItem = 'product-image-item',
  ProductImage = 'product-image',
}

@withGlobalProps
export class ProductImageOld extends React.Component<ProductImageItemProps, ProductImageItemState> {
  public state = {imageLoaded: false};

  public render(): JSX.Element {
    const {
      imageMode,
      mountedToDOM,
      isSelected,
      mediaItem,
      dimensions,
      productName,
      imageLoaded,
      globals: {
        isSEO,
        experiments: {jpgExperiment},
      },
    } = this.props;

    const imageClass = classNames([
      s.media,
      {
        [s.selected]: isSelected,
      },
    ]);

    return (
      <Image
        mediaItem={mediaItem}
        imageMode={imageMode}
        productName={productName}
        imageLoaded={imageLoaded}
        isSEO={isSEO}
        mountedToDOM={mountedToDOM}
        dimensions={dimensions}
        withSSRDimensions={true}
        className={imageClass}
        altText={mediaItem.altText}
        containerDataHook={DataHook.ProductImageItem}
        imgDataHook={DataHook.ProductImage}
        withJpgExperiment={jpgExperiment}
      />
    );
  }
}
