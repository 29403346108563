import React, { FC } from 'react';
import classNames from 'classnames';

import { InjectedClassNameProps, InjectedTranslateProps } from '../../../types';
import styles from './Blocker.scss';

type BlockedProps = InjectedTranslateProps & InjectedClassNameProps;

const Blocked: FC<BlockedProps> = ({ className, t }) => (
  <span className={classNames(className, styles.container)}>
    {t('MemberRoles.badge.blocked')}
  </span>
);

export default Blocked;
