import React, { FC } from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { Experiments } from '../../../types';
import styles from './PrivateMemberIndicator.scss';

interface PrivateMemberIndicatorProps {
  alignRight?: boolean;
  alignBottom?: boolean;
}

const PrivateMemberIndicator: FC<PrivateMemberIndicatorProps> = ({
  alignRight,
  alignBottom,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  return experiments.get(Experiments.PrivateMemberIndicator) === 'true' ? (
    <div
      className={classNames({
        [styles.indicatorContainer]: true,
        [styles.toTheBottom]: alignBottom === true,
      })}
    >
      <div
        className={classNames({
          [styles.flexbox]: true,
          [styles.toTheRight]: alignRight === true,
        })}
      >
        <div className={styles.privateIndicator}>
          {t('profile-widget.private-member')}
        </div>
      </div>
    </div>
  ) : null;
};

export default PrivateMemberIndicator;
