interface ResolveResponse {
  width: number;
  height: number;
  url: string;
  id: string;
}

export const convertImageFileToBase64 = (file: File) => {
  const img = document.createElement('img');
  const reader = new FileReader();
  let resolveFn: (resolveResponse: ResolveResponse) => void;

  reader.onload = ({ target }) => {
    img.src = target?.result as string;
  };
  img.onload = () => {
    const response = {
      width: img.width,
      height: img.height,
      url: img.src,
      id: file.name,
    };

    resolveFn(response);
  };

  reader.readAsDataURL(file);

  return new Promise<ResolveResponse>((resolve) => {
    resolveFn = resolve;
  });
};
