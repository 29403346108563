import React, { FC } from 'react';
import classNames from 'classnames';
import { Member } from '@wix/members-domain-ts';
import { Tooltip } from 'wix-ui-tpa';

import { InjectedClassNameProps } from '../../../types';
import styles from './MemberName.scss';
import {
  DEFAULT_HIDE_DELAY,
  DEFAULT_SHOW_DELAY,
} from '../../../constants/tooltip';
import { MEMBERS_AREA_PAGE_TITLE_ID } from '../../../constants/common';

export interface MemberNameProps extends InjectedClassNameProps {
  name: string;
  member?: Member;
  tooltipClass?: string;
  goToMemberPage?: Function;
}

const MemberName: FC<MemberNameProps> = ({ name, className, tooltipClass }) => {
  return (
    <Tooltip
      content={name}
      className={tooltipClass}
      showDelay={DEFAULT_SHOW_DELAY}
      hideDelay={DEFAULT_HIDE_DELAY}
      appendTo="window"
    >
      {/* weird syntax for aria-describedBy, but otherwise it doesn't work inside the Tooltip */}
      {/* https://wix.slack.com/archives/CJRU3U97A/p1655795835619339 */}
      <h1
        aria-describedBy={MEMBERS_AREA_PAGE_TITLE_ID}
        className={classNames(className, styles.nameButton)}
      >
        {name}
      </h1>
    </Tooltip>
  );
};

export default MemberName;
