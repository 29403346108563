import { WIX_STORES } from '@wix/app-definition-ids'

import type { Action } from './actions'

type Params = {
  wixSdk: any
  currentItem: any
}
type DataBindingActionImplementation = (params: Params) => Promise<void>

export const actions: Record<Action, DataBindingActionImplementation> = {
  'tpa/addVariantToCart': async ({ wixSdk, currentItem }: Params) => {
    const api = await wixSdk.site.getPublicAPI(WIX_STORES)
    await api.cart.addProducts([
      { productId: currentItem.productId, quantity: 1 },
    ])
  },
  'tpa/addProductToCart': async ({ wixSdk, currentItem }: Params) => {
    const api = await wixSdk.site.getPublicAPI(WIX_STORES)
    await api.cart.addProducts([{ productId: currentItem._id, quantity: 1 }])
  },
  'tpa/quicklyViewProduct': async ({ wixSdk, currentItem }: Params) => {
    const api = await wixSdk.site.getPublicAPI(WIX_STORES)
    await api.product.openQuickView(currentItem._id)
  },
  'tpa/purchasePricingPlan': async ({ wixSdk, currentItem }: Params) => {
    await wixSdk['pricing-plans'].checkout.startOnlinePurchase(currentItem._id)
  },
}
