import {State} from '../types/state'
import {getComponentCategoryIds} from './component'

export const getVeloCategoryId = (state: State) => state.dynamicVeloOverrides.categoryId

export const getDynamicCategoryIds = (state: State) => {
  if (isVeloOverrideUsed(state)) {
    const override = getVeloCategoryId(state)
    return override ? [override] : []
  }

  return getComponentCategoryIds(state.component)
}

export const getDynamicCategoryId = (state: State) => getDynamicCategoryIds(state)?.[0] ?? null

export const isVeloOverrideUsed = (state: State) =>
  Boolean(Object.values(state.dynamicVeloOverrides).find(item => item !== null))
