import {Events} from '../constants';
import {DependantApps} from '../services/DependantApps';
import {doTransaction} from '../transaction';
import {handleAppsManagerActions} from '../../common/appManifest/appManifest';
import {EditorSDK} from '@wix/platform-editor-sdk';

export async function handleOnEvent(
  {eventType, eventPayload}: {eventType: Events; eventPayload: any},
  sdk: EditorSDK,
  dependantApps: DependantApps
) {
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Events.dashboardClosed:
      return doTransaction(sdk, () => dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded());
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
  }
}
