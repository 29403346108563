import {getEventTitle, getFormattedLocation, getTaxConfig} from '@wix/wix-events-commons-statics'
import {getDatesInformation} from '../../../selectors/dates'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {getOrderedTickets} from '../../../selectors/placed-order'
import {OrderSummaryRuntimeProps} from './interfaces'
import {OrderSummary as OrderSummaryPresentation} from './order-summary'

const mapRuntimeToProps = ({state}: DetailsPageAppProps): OrderSummaryRuntimeProps => {
  const {event, tickets, seating} = state

  return {
    eventTitle: getEventTitle(event),
    eventDate: getDatesInformation(state).fullDate,
    eventLocation: getFormattedLocation(event),
    tickets: getOrderedTickets(state, tickets),
    taxConfig: getTaxConfig(event),
    seatingPlan: seating.plan,
  }
}

export const OrderSummary = connect<{}, OrderSummaryRuntimeProps>(mapRuntimeToProps)(OrderSummaryPresentation)
