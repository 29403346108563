import {Dispatch} from 'redux'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'
import {getLanguage, getLocale, getTimezone, isEditor, isMobile, isPreview, isSite} from '../utils/wix-code-api'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'
export const SET_FORM_FACTOR = 'SET_FORM_FACTOR'

export const setBaseEnvironment =
  () =>
  (dispatch: Dispatch, getState: GetCommonState, {wixCodeApi}: CommonStoreExtraArgs) => {
    const state = getState()

    dispatch({
      type: SET_BASE_ENVIRONMENT,
      payload: {
        locale: getLocale(wixCodeApi) || state.siteSettings.locale,
        language: getLanguage(wixCodeApi),
        timezone: getTimezone(wixCodeApi),
        mobile: isMobile(wixCodeApi),
        editor: isEditor(wixCodeApi),
        preview: isPreview(wixCodeApi),
        site: isSite(wixCodeApi),
        homePageUrl: wixCodeApi.location.baseUrl,
      },
    })
  }

export const setFormFactor = (formFactor: string) => ({
  type: SET_FORM_FACTOR,
  payload: formFactor === 'Mobile',
})
