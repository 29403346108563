import React from 'react'
import {SectionNotification as SectionNotificationTPA} from 'wix-ui-tpa'
import {SectionNotificationProps} from './index'

export const SectionNotification = ({text, icon, className, type, size}: SectionNotificationProps) => (
  <SectionNotificationTPA type={type} size={size} className={className}>
    {icon ? <SectionNotificationTPA.Icon icon={icon} /> : null}
    <SectionNotificationTPA.Text>{text}</SectionNotificationTPA.Text>
  </SectionNotificationTPA>
)
