import React, { createContext, FC } from 'react';

import { ControllerProps } from '../../types';

export const WidgetContext = createContext<ControllerProps>(null!);

export const WidgetContextProvider: FC<ControllerProps> = (props) => (
  <WidgetContext.Provider value={props}>
    {props.children}
  </WidgetContext.Provider>
);
