export { USER_INPUT_FILTER_ROLES } from '@wix/wix-data-client-common/src/userInputFilters'
import {
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'

export const RESET_ALL = 'RESET_ALL'

export const USER_INPUT_FILTER_UNIQUE_OPTIONS_LIMIT = 50

export const LIST_TYPE_USER_INPUT_FILTER_ROLES = [
  USER_INPUT_FILTER_DROPDOWN_ROLE,
  USER_INPUT_FILTER_RADIOGROUP_ROLE,
  USER_INPUT_FILTER_CHECKBOX_GROUP_ROLE,
  USER_INPUT_FILTER_SELECTION_TAGS_ROLE,
]

export const DEPRECATED_METHODS_MAP = new Map([['new', 'add']])

export { DATA_BINDING as APP_DEF_ID } from '@wix/app-definition-ids'

export const APP_NAME = 'dbsm-viewer-app'
