import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { Experiment, Field } from '../../types';
import {
  getNonMultilingualFieldLabel,
  getMultilingualFieldLabel,
} from './utils';

export const useFieldLabel = () => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { multilingual } = useEnvironment();
  const isMultilingualEnabled =
    multilingual.isEnabled &&
    experiments.enabled(Experiment.UseLocalizationService);

  const getFieldLabel = (field: Field) => {
    if (isMultilingualEnabled) {
      return getMultilingualFieldLabel(t, field);
    } else {
      return getNonMultilingualFieldLabel(t, field);
    }
  };

  return {
    getFieldLabel,
  };
};
