import type {EditorSDK, PagesPanelTabType, PlatformEvent} from '@wix/platform-editor-sdk'
import {EVENTS_SECTION_ID} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {getSectionPageRef} from './pages-panel'
import {createWithApproval} from './services/concurrent-editing'
import {FlowApi} from './types'

export const EVENT_APP_ACTION_CLICKED = 'appActionClicked'
export const EVENT_MANAGE_EVENTS = 'manageEvents'
export const EVENT_DELETE_EVENTS = 'deleteEvents'

export const APP_ACTION_MANAGE_EVENTS = 'manageEvents'
export const APP_ACTION_CREATE_EVENT = 'createEvent'
export const APP_ACTION_OPEN_EVENT_PAGES = 'openEventPages'
export const APP_ACTION_ADD_EVENT_ELEMENTS = 'addEventElements'

interface HandlerParams<PayloadType = any> {
  sdk: EditorSDK
  appToken: string
  experiments: ExperimentsBag
  payload?: PayloadType
  flowApi: FlowApi
}

interface AppActionPayload {
  actionId: string
}

const appActionHandlers: {[key: string]: (params: HandlerParams<AppActionPayload>) => void} = {
  [APP_ACTION_MANAGE_EVENTS]: async ({sdk, appToken}) => {
    await sdk.editor.openDashboardPanel(appToken, {closeOtherPanels: false, url: '/events'})
    return sdk.tpa.app.refreshApp(appToken)
  },
  [APP_ACTION_CREATE_EVENT]: async ({sdk, appToken}) => {
    await sdk.editor.openDashboardPanel(appToken, {closeOtherPanels: false, url: '/events/new'})
    return sdk.tpa.app.refreshApp(appToken)
  },
  [APP_ACTION_OPEN_EVENT_PAGES]: async ({sdk, appToken: token}) => {
    const pageRef = (await getSectionPageRef(EVENTS_SECTION_ID, sdk, token)) as any
    await sdk.editor.openPagesPanel({token}, {pageRef, initialSettingsTabType: 'PAGE_INFO' as PagesPanelTabType})
  },
  [APP_ACTION_ADD_EVENT_ELEMENTS]: async ({sdk, appToken}) => {
    await sdk.editor.deeplink.show(appToken, {type: 'addPanel', params: [appToken]})
  },
}

const handlers: {[key: string]: (params: HandlerParams) => void} = {
  [EVENT_MANAGE_EVENTS]: async ({sdk, appToken}) => {
    await sdk.editor.openDashboardPanel(appToken, {url: 'events', closeOtherPanels: false})
    return sdk.tpa.app.refreshApp(appToken)
  },
  [EVENT_DELETE_EVENTS]: async ({sdk, appToken, flowApi}) => {
    await createWithApproval(
      sdk,
      appToken,
      false,
      flowApi,
    )(async () => {
      // Deleting main page first causes confirmation popup to show up
      const eventsPages = await sdk.document.pages.getApplicationPages(appToken)
      const essentialPage = eventsPages.find((page: any) => page.tpaPageId === EVENTS_SECTION_ID)
      await sdk.document.pages.remove(appToken, {pageRef: {id: essentialPage.id}})

      // In theory app should already be deleted at this point but it is often not the case
      await sdk.document.tpa.app.delete(appToken)
    })
  },
  [EVENT_APP_ACTION_CLICKED]: async (params: HandlerParams<AppActionPayload>) => {
    const {actionId} = params.payload
    await appActionHandlers?.[actionId]?.(params)
  },
}

export const onEventFactory =
  (getAppToken: () => string, getExperiments: () => ExperimentsBag, getFlowApi: () => FlowApi) =>
  async ({eventType, eventPayload}: PlatformEvent, editorSDK: EditorSDK) => {
    await handlers?.[eventType]?.({
      sdk: editorSDK,
      payload: eventPayload,
      appToken: getAppToken(),
      experiments: getExperiments(),
      flowApi: getFlowApi(),
    })
  }
