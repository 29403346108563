import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { TextFieldTheme } from 'wix-ui-tpa';
import { useSettings } from '@wix/tpa-settings/react';

import { classes, st } from './FieldGroup.st.css';
import { Field, FieldSection, FieldType } from '../../../../types';
import { Text } from '../common/Fields/Text';
import { DatePicker } from '../common/Fields/Date';
import settingsParams from '../../settingsParams';
import { UrlField } from '../common/Fields/Url';
import { NumberField } from '../common/Fields/Number';
import { AddressField } from '../common/Fields/Address';
import {
  BIRTHDAY_FIELD_KEY,
  EMAILS_FIELD_KEY,
  FIRST_NAME_FIELD_KEY,
  LAST_NAME_FIELD_KEY,
  PHONES_FIELD_KEY,
} from '../../../../server/constants';

interface FieldGroupProps {
  fields: (Pick<Field, 'id' | 'type' | 'key'> &
    Partial<Pick<Field, 'section'>>)[];
  section?: FieldSection;
  fieldSelector?: string;
  isSingleColumn?: boolean;
}

interface FieldListProps extends FieldGroupProps {
  fieldTheme: TextFieldTheme;
}

const isJapanese = (language: string) => language === 'ja';
const isNameField = (fieldKey: string) => fieldKey === FIRST_NAME_FIELD_KEY;
const isLastNameField = (fieldKey: string) => fieldKey === LAST_NAME_FIELD_KEY;
const sortFieldsForJapanese = (fields: FieldGroupProps['fields']) =>
  fields.sort((a, b) =>
    isNameField(b.key) && isLastNameField(a.key) ? -1 : 1,
  );

export const FieldList: FC<FieldListProps> = ({
  fields,
  fieldTheme,
  fieldSelector,
  section,
}) => {
  return (
    <>
      {fields.reduce<JSX.Element[]>((acc, field, fieldIndexInForm) => {
        if (section && field.section !== section) {
          return acc;
        }

        const { type, id, key } = field;
        const formikFieldSelector = `fields.${
          fieldSelector ?? ''
        }[${fieldIndexInForm}]`;
        const props = { key: id, theme: fieldTheme, formikFieldSelector };

        let formElement: JSX.Element | undefined;
        if (type === FieldType.TEXT) {
          formElement = <Text {...props} />;
        } else if (key === BIRTHDAY_FIELD_KEY) {
          formElement = <DatePicker {...props} allowFutureDates={false} />;
        } else if (key === EMAILS_FIELD_KEY) {
          formElement = <Text {...props} type="email" />;
        } else if (key === PHONES_FIELD_KEY) {
          formElement = <Text {...props} type="tel" />;
        } else if (type === FieldType.DATE) {
          formElement = <DatePicker {...props} />;
        } else if (type === FieldType.NUMBER) {
          formElement = <NumberField {...props} />;
        } else if (type === FieldType.ADDRESS) {
          formElement = <AddressField {...props} />;
        } else if ([FieldType.URL, FieldType.SOCIAL].includes(type)) {
          formElement = <UrlField {...props} />;
        }

        return formElement ? [...acc, formElement] : acc;
      }, [])}
    </>
  );
};

export const FieldGroup: FC<FieldGroupProps> = ({
  fields,
  section,
  fieldSelector,
  isSingleColumn = false,
}) => {
  const settings = useSettings();
  const fieldTheme = settings.get(
    settingsParams.inputFieldsTheme,
  ) as TextFieldTheme;
  const { isMobile, language } = useEnvironment();

  if (isJapanese(language)) {
    sortFieldsForJapanese(fields);
  }

  return (
    <div
      className={st(classes.root, {
        singleColumn: isMobile || isSingleColumn,
      })}
    >
      <FieldList
        fields={fields}
        fieldTheme={fieldTheme}
        section={section}
        fieldSelector={fieldSelector}
      />
    </div>
  );
};
