import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';

import {withGlobalPropsProvider} from '../providers/globalPropsProvider';
import {getRuntimeStyleParams, getRuntimeStyleOverrides} from './styleParamsService';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';

export const bootstrapAppBase = (
  Component: React.ComponentType,
  withStylesFunc: typeof withStyles,
  {cssPath}: {cssPath?: string[]}
) => {
  return withDefaultStyleParams(
    withStylesFunc(
      withStrictMode(withGlobalPropsProvider(Component)),
      /* istanbul ignore next: todo: test */ cssPath ? {cssPath, strictMode: true} : {}
    ),
    ({
      host: {
        style: {styleParams},
      },
      isMobile,
      isResponsive,
    }) => {
      return {
        defaults: getRuntimeStyleParams(styleParams, {isResponsive, isMobile}),
        overrides: getRuntimeStyleOverrides({isMobile, isResponsive}),
      };
    }
  );
};
