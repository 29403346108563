import React, { FC, useRef, useState } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Dialog,
  Text,
  RadioButton,
  RadioButtonTheme,
  RadioButtonGroup,
} from 'wix-ui-tpa';

import { classes, st } from './FieldPrivacyModal.st.css';
import {
  Field,
  FieldPrivacy,
  FieldType,
  ModalType,
} from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { useBi, useFieldLabel } from '../../../../../hooks';
import RadioButtonContent from './RadioButtonContent';

type Props = {
  field: Field;
  onChange(newPrivacy: FieldPrivacy): void;
};

const modalCloseDelay = 100;

export const FieldPrivacyModal: FC<ModalType<Props>> = ({
  onClose,
  isOpen,
  additionalProps,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { getFieldLabel } = useFieldLabel();
  const biLogger = useBi();
  const preventAutoSelect = useRef(false);

  const { field } = additionalProps;
  const initialIsFieldPrivate = field.privacy === FieldPrivacy.PRIVATE;

  const [isFieldPrivate, setIsFieldPrivate] = useState(initialIsFieldPrivate);

  const selectedOption = isFieldPrivate
    ? FieldPrivacy.PRIVATE.toString()
    : FieldPrivacy.PUBLIC.toString();
  const onChange = (newPrivacy: FieldPrivacy) => {
    if (preventAutoSelect.current) {
      return;
    }
    const newIsFieldPrivate = newPrivacy === FieldPrivacy.PRIVATE;

    additionalProps.onChange(newPrivacy);
    setIsFieldPrivate(newIsFieldPrivate);
    biLogger.fieldPrivacyChanged(field.label, newPrivacy, field.type);
    setTimeout(() => onClose(), modalCloseDelay);
  };

  const subfieldsLabels =
    field.type === FieldType.ADDRESS
      ? field.value
          .map((subfield: Field) => getFieldLabel(subfield).trim())
          .join(', ')
      : '';

  const title = `${field.label} ${t('app.widget.modals.field-privacy.title')}`;
  const subtitle = subfieldsLabels
    ? `${t('app.widget.modals.field-privacy.applies-to')}: ${subfieldsLabels}`
    : undefined;

  const TITLE_ID = 'field-privacy-modal-title';
  const SUBTITLE_ID = 'field-privacy-modal-subtitle';

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={st(classes.root, { mobile: isMobile })}
      wiredToSiteColors={false}
      data-hook={DataHook.FieldPrivacyModal}
      aria-labelledby={TITLE_ID}
      aria-describedby={SUBTITLE_ID}
    >
      <div
        onKeyDown={() => {
          preventAutoSelect.current = true;
        }}
        onKeyUp={() => {
          preventAutoSelect.current = false;
        }}
      >
        <Text className={classes.title} id={TITLE_ID}>
          {title}
        </Text>
        <Text className={classes.subtitle} id={SUBTITLE_ID}>
          {subtitle}
        </Text>
        <div className={classes.boxSelectionContainer}>
          <RadioButtonGroup
            name={title}
            withSpacing
            theme={RadioButtonTheme.Box}
            value={selectedOption}
            onChange={onChange}
            className={classes.radioButtonGroup}
          >
            <RadioButton
              value={FieldPrivacy.PRIVATE.toString()}
              checked={!isFieldPrivate}
              theme={RadioButtonTheme.Box}
              className={classes.radioButton}
              aria-label={`${t('app.widget.modals.field-privacy.private')} ${t(
                'app.widget.modals.field-privacy.private.sub-label',
              )}`}
            >
              <RadioButtonContent
                label={t('app.widget.modals.field-privacy.private')}
                subLabel={t(
                  'app.widget.modals.field-privacy.private.sub-label',
                )}
                fieldPrivacy={FieldPrivacy.PRIVATE}
              />
            </RadioButton>
            <RadioButton
              value={FieldPrivacy.PUBLIC.toString()}
              checked={isFieldPrivate}
              theme={RadioButtonTheme.Box}
              className={classes.radioButton}
              aria-label={`${t('app.widget.modals.field-privacy.public')} ${t(
                'app.widget.modals.field-privacy.public.sub-label',
              )}`}
            >
              <RadioButtonContent
                label={t('app.widget.modals.field-privacy.public')}
                subLabel={t('app.widget.modals.field-privacy.public.sub-label')}
                fieldPrivacy={FieldPrivacy.PUBLIC}
              />
            </RadioButton>
          </RadioButtonGroup>
        </div>
      </div>
    </Dialog>
  );
};
