import {SuspensionStatus as Status} from '@wix/ambassador-membership-api/types'
import {isRsvp, isWithoutRegistration, PAID_PLANS_APP_DEF_ID} from '@wix/wix-events-commons-statics'
import {PlanInfo, PlanList} from '@wix/events-types'
import {TFunction, IWixAPI} from '@wix/yoshi-flow-editor'
import {State} from '../types'
import {isMembershipOffersVisibilityEnabled} from './settings'

export const isPaidPlansEnabled = (state: State) => state.paidPlansEnabled

export const getBestPurchasedPlan = (state: State): PlanInfo => state.paidPlans.purchasedPlanList?.[0]

export const getPurchasedPlans = (state: State): PlanInfo[] => state.paidPlans.purchasedPlanList

export const getSelectedPaidPlan = ({paidPlans: {purchasedPlanList, selectedPaidPlanOrderId}}: State) =>
  purchasedPlanList.find(plan => plan.planOrderId === selectedPaidPlanOrderId)

export const isPaidPlansVisible = (state: State) =>
  isMembershipOffersVisibilityEnabled(state.component.settings) &&
  !isRsvp(state.event) &&
  Boolean(getPurchasablePlansWithValidityText(state).length) &&
  !isWithoutRegistration(state.event)

export const showPaidPlansPicker = (state: State) => getPlans(state).length && !isRsvp(state.event)

export const planHasRemainingCredits = (plan: PlanInfo) => !!plan?.remainingCredits

export const allPlansPurchased = (state: State) => getNotPurchasedPlans(state).length === 0

export const getNotPurchasedPlans = (state: State) => getPlans(state).filter(plan => !plan.purchased)

export const getBestNonExpiredPlan = (plans: PlanList) => plans.find(planHasRemainingCredits)

export const getPaidPlansSectionUrl = async (wixCodeApi: IWixAPI) => {
  const siteStructure = await wixCodeApi.site.getSiteStructure()
  return siteStructure.pages.find((page: any) => page.applicationId === PAID_PLANS_APP_DEF_ID).url || ''
}

export const getPlans = (state: State): PlanInfo[] => state.paidPlans.planList

export const getPurchasablePlans = (state: State) =>
  getPlans(state).filter(plan => !plan.purchased || (plan.purchased && !plan.remainingCredits))

export const getPurchasablePlansWithValidityText = (state: State) =>
  getPlans(state).filter(plan => plan.status === Status.ACTIVE && (plan.visible || plan.purchased))

export const getNotPurchasedPlanIds = (state: State) => getPurchasablePlans(state).map(plan => plan.id)

export const getPaidPlanPromoDiscount = (state: State) => Number(getBestPlan(state)?.discount?.percentOffRate)

export const getBestPlan = (state: State): PlanInfo => state.paidPlans.planList?.[0]

export const getPurchasedPlanByOrderId = (state: State, planOrderId: string) =>
  getPurchasedPlans(state).find(plan => plan.planOrderId === planOrderId)

export const hasPurchasedPlans = (state: State) => Boolean(getPurchasedPlans(state).length)

export const getPlanBenefitText = (planInfo: PlanInfo, t: TFunction, useRemainingCredits?: boolean) => {
  const percent = Number(planInfo?.discount?.percentOffRate ?? '0')
  const amount = (useRemainingCredits ? planInfo?.remainingCredits : planInfo?.credits) ?? 0
  let translationKey = 'membership_discount'

  if (amount === 0) {
    translationKey = 'membership_discount_finished'
  } else if (amount === 1) {
    translationKey = 'membership_discount_singular'
  }

  return t(translationKey, {percent, amount})
}

export const planVisible = (plan: PlanInfo) => plan.purchased || (plan.visible && plan.status === Status.ACTIVE)

export const getSortedPlanList = (planList: PlanList): PlanList =>
  [...planList].sort((a, b) => {
    const aDiscount = Number(a.discount?.percentOffRate ?? 0)
    const bDiscount = Number(b.discount?.percentOffRate ?? 0)

    return bDiscount - aDiscount
  })
