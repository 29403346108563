import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Text } from 'wix-ui-tpa';

import { classes } from './LoginEmail.st.css';
import { useMember } from '../../../../../../contexts/widget';

export const LoginEmail: FC = () => {
  const { t } = useTranslation();
  const { loginEmail } = useMember();

  return (
    <div className={classes.root}>
      <Text className={classes.title} tagName="div">
        {t('app.widget.login-email.title')}:
      </Text>

      <Text className={classes.title} tagName="div">
        {loginEmail}
      </Text>

      <Text className={classes.subtitle} tagName="div">
        {t('app.widget.login-email.subtitle')}
      </Text>
    </div>
  );
};
