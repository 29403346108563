import {createAction} from '@reduxjs/toolkit'
import {EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import {getFullLocale, isEditor, isMobile} from '../../../../commons/selectors/environment'
import {isDemo, isTemplate} from '../../../../commons/selectors/instance'
import {isCalendarLayout, getRecurringFilter} from '../selectors/settings'
import {isCalendarPageLoaded} from '../selectors/calendar-layout'
import {getRealEventsCount} from '../selectors/events'
import {CalendarLoading} from '../types/state'
import {createAsyncAction} from '../services/redux-toolkit'
import {LoadCalendarEventsResponse, LoadEventsResponse} from '../utils/api'
import {getDynamicCategoryIds} from '../selectors/velo-overrides'
import {getComponentStatusFilter, isManualFilterSelected, getFilterTypeFromSettings} from '../selectors/component'
import {resetCalendar} from './calendar-layout'

export const setFilterType = createAction<EVENT_FILTER_TYPE>('SET_FILTER_TYPE')

export const reloadEvents = createAsyncAction('RELOAD_EVENTS', (_, {getState, dispatch}) =>
  isCalendarLayout(getState().component) ? dispatch(resetCalendar(true)) : dispatch(loadEventsPage(0)),
)

export const loadEventsPage = createAsyncAction<LoadEventsResponse, number | void>(
  'LOAD_EVENTS_PAGE',
  (offset: number, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()
    const {membersEnabled, paidPlansEnabled} = state
    offset = offset ?? getRealEventsCount(state)
    const categoryId = getDynamicCategoryIds(state)
    const recurringFilter = getRecurringFilter(state)

    const filterTypeFromSettings = getFilterTypeFromSettings(state, flowAPI)
    const filter = getComponentStatusFilter(state, flowAPI)

    return serverApi.loadEvents({
      offset,
      filter,
      byEventId: isManualFilterSelected(state, flowAPI),
      members: membersEnabled,
      paidPlans: paidPlansEnabled,
      locale: getFullLocale(state),
      categoryId,
      recurringFilter,
      filterType: filterTypeFromSettings,
    })
  },
)

interface LoadCalendarEventsParams {
  referenceDate?: string
  origin?: CalendarLoading
  fullReset?: boolean
}

export const loadCalendarEvents = createAsyncAction<LoadCalendarEventsResponse, LoadCalendarEventsParams>(
  'LOAD_CALENDAR_EVENTS',
  ({fullReset, referenceDate}, {getState, extra: {serverApi, flowAPI}}) => {
    const state = getState()

    if (!fullReset && referenceDate && isCalendarPageLoaded(state.calendarLayout, referenceDate)) {
      return
    }

    const {membersEnabled, paidPlansEnabled} = state
    const categoryId = getDynamicCategoryIds(state)
    const showcase = isDemo(state) || isTemplate(state) || isEditor(state) || isMobile(state)

    const filterTypeFromSettings = getFilterTypeFromSettings(state, flowAPI)
    const filter = getComponentStatusFilter(state, flowAPI)

    return serverApi.loadCalendarEvents({
      referenceDate,
      filter,
      byEventId: isManualFilterSelected(state, flowAPI),
      members: membersEnabled,
      paidPlans: paidPlansEnabled,
      locale: getFullLocale(state),
      categoryId,
      showcase,
      filterType: filterTypeFromSettings,
    })
  },
)
