import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {OrderSummary as OrderSummaryPresentation} from './order-summary'

export interface OrderSummaryProps extends OrderSummaryOwnProps, WithTranslation {}

interface OrderSummaryOwnProps {
  invoice: wix.events.ticketing.Invoice
}

export const OrderSummary = withTranslation()(OrderSummaryPresentation)
