import React, { FC } from 'react';
import { IconButton, IconButtonSkins, Text } from 'wix-ui-tpa';

import { classes } from './FieldLabel.st.css';
import { Popover } from '../Popover';
import { InfoIcon } from '../icons';

type Props = {
  text: string;
  htmlFor: string;
  isFieldRequired: boolean;
  infoTooltipText?: string;
};

export const FieldLabel: FC<Props> = ({
  text,
  isFieldRequired,
  infoTooltipText,
  htmlFor,
}) => (
  <label htmlFor={htmlFor} className={classes.root}>
    <Text className={classes.label}>
      {text} {isFieldRequired && '*'}
      {infoTooltipText ? (
        <div className={classes.popover}>
          <Popover className={classes.popover} popoverContent={infoTooltipText}>
            <IconButton
              className={classes.iconButton}
              skin={IconButtonSkins.Full}
              icon={<InfoIcon />}
            />
          </Popover>
        </div>
      ) : null}
    </Text>
  </label>
);
