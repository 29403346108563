import React, {ComponentType} from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {getStyleParamsWithDefaultsFunc} from './getStyleParamsWithDefaultsFunc';
import {withGlobalsProvider, initContext, Strategy} from './globalPropsContext';
import {withExperiment} from './withExperiment';
import {IGallerySantaProps} from './types/galleryTypes';

export interface IGetWrappedComponentOptions {
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
  withStyleStrategy: typeof withStyles;
  withExperimentAttribute?: string;
  sliderGallery?: boolean;
}

export function getWrappedComponent({
  Component,
  globalPropsStrategy,
  withStyleStrategy,
  withExperimentAttribute,
  sliderGallery,
}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  const componentWithGlobals = withGlobalsProvider(Component, sliderGallery);

  let componentWithOldStyleParams: ComponentType<IGallerySantaProps>;
  componentWithOldStyleParams = withStyleStrategy(componentWithGlobals, {
    strictMode: true,
  });

  componentWithOldStyleParams = withDefaultStyleParams(componentWithOldStyleParams, ({host}) =>
    getStyleParamsWithDefaultsFunc(host)
  );

  return withExperiment(componentWithGlobals, componentWithOldStyleParams, withExperimentAttribute);
}
