import React, { FC } from 'react';
import { useEnvironment, WidgetProps } from '@wix/yoshi-flow-editor';
import { TPAComponentsProvider } from 'wix-ui-tpa';

import { ModalsContextProvider } from '../../../contexts/modals';
import { Root } from './Root';
import { ControllerProps } from '../../../types';
import { WidgetContextProvider } from '../../../contexts/widget';

const Widget: FC<WidgetProps<ControllerProps | undefined>> = (
  controllerProps,
) => {
  const { isMobile, isRTL } = useEnvironment();

  if (!controllerProps.member) {
    return null;
  }

  return (
    <WidgetContextProvider {...controllerProps}>
      <ModalsContextProvider>
        <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
          <Root />
        </TPAComponentsProvider>
      </ModalsContextProvider>
    </WidgetContextProvider>
  );
};

export default Widget;
