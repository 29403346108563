type GetShouldShowPrivacyIconOptions = {
  index: number | undefined;
  isSocial: boolean;
  isPublicProfile: boolean;
  isPrivacyEditable: boolean;
  isGeneralSettingsEnabled: boolean;
};

export const getShouldShowPrivacyIcon = ({
  index,
  isSocial,
  isPublicProfile,
  isPrivacyEditable,
  isGeneralSettingsEnabled,
}: GetShouldShowPrivacyIconOptions) => {
  return isGeneralSettingsEnabled
    ? isPublicProfile && isPrivacyEditable && !index
    : isSocial && isPublicProfile && isPrivacyEditable && !index;
};
