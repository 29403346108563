/* istanbul ignore file: todo(eran): not running image tests on this file yet */

import React from 'react';
import classNames from 'classnames';
import {ImageLoadingBehaviorOptions, ImageResizeOptions, WowImage} from 'wix-ui-tpa';
import * as Icons from '@wix/wixstores-client-common-components/dist/src/icons/dist';
import {HoverType, MediaFrameMediaType, ProductType} from '../../../../types/galleryTypes';
import s from './ImageUiTpaWrapper.scss';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {classes} from './ImageUiTpaWrapper.st.css';

export enum ImageUiTpaWrapperDataHook {
  Wrapper = 'ImageUiTpaWrapperDataHook.Wrapper',
  Media = 'ImageUiTpaWrapperDataHook.Media',
  DefaultImage = 'ImageUiTpaWrapperDataHook.DefaultImage',
}

export interface ImageUiTpaWrapperProps extends IGalleryGlobalProps {
  product: any;
  wrapperClassName?: string;
  imageClassName?: string;
  mediaItemIndex?: number;
  resize?: ImageResizeOptions;
  containerAspectRatio: number;
  hoverType: HoverType;
}
export const getWowImageDataHook = (index: number) => `${ImageUiTpaWrapperDataHook.Media}_${index}`;
export const getImageWrapperDataHook = (index: number) => `${ImageUiTpaWrapperDataHook.Wrapper}_${index}`;

const iconsByFileType = (product) => {
  const fileType = product?.digitalProductFileItems[0]?.fileType;
  const size = '120';

  const icons = {
    [MediaFrameMediaType.SECURE_DOCUMENT]: <Icons.DocumentDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_PICTURE]: <Icons.PictureDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_VIDEO]: <Icons.VideoDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_MUSIC]: <Icons.MusicDigitalProductDefaultImage size={size} />,
    [MediaFrameMediaType.SECURE_ARCHIVE]: <Icons.DocumentDigitalProductDefaultImage size={size} />,
  };

  return icons[fileType] || icons[MediaFrameMediaType.SECURE_DOCUMENT];
};

const isDigitalProduct = (product) => product.productType === ProductType.DIGITAL;

const defaultImage = (product) => {
  if (isDigitalProduct(product)) {
    return iconsByFileType(product);
  } else {
    return <Icons.PhysicalProductDefaultImage size={'120'} />;
  }
};

export const ImageUiTpaWrapper = ({
  product,
  wrapperClassName,
  mediaItemIndex,
  imageClassName,
  resize,
  containerAspectRatio,
  globals: {isSEO},
}: ImageUiTpaWrapperProps) => {
  const mediaItem = product.media.length > mediaItemIndex && product.media[mediaItemIndex];
  const alt = mediaItem.altText || product.name;
  const isContain = resize === ImageResizeOptions.contain;

  return mediaItem ? (
    <div className={classNames(wrapperClassName, s.imageContainer)} data-hook={getImageWrapperDataHook(mediaItemIndex)}>
      <WowImage
        data-hook={getWowImageDataHook(mediaItemIndex)}
        src={mediaItem.url}
        className={classNames(classes.root, imageClassName)}
        stretchImage={!isContain}
        sourceWidth={mediaItem.width}
        sourceHeight={mediaItem.height}
        alt={alt}
        fluid={true}
        loadingBehavior={ImageLoadingBehaviorOptions.none}
        resize={resize}
        shouldUseLQIP={true}
        containerAspectRatio={containerAspectRatio}
        forceImageContain={isContain}
        isSEOBot={isSEO}
      />
    </div>
  ) : (
    <div
      data-hook={ImageUiTpaWrapperDataHook.DefaultImage}
      className={s.defaultImage}
      style={{width: mediaItem.width, height: mediaItem.height}}>
      {defaultImage(product)}
    </div>
  );
};
