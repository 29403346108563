import {EventsList, Tabs} from '../../../../commons/enums'
import {DetailedEvent, MemberPageState} from '../types/state'
import {isDemoMode, isNoEventsMode} from './view'

export const shouldLoadMore = (state: MemberPageState) => {
  const eventsKey = state.tab === Tabs.PAST ? EventsList.PAST : EventsList.UPCOMING
  return state.events[eventsKey].length < state.events.total[eventsKey] && !state.loading
}

export const hasNoEvents = (state: MemberPageState): boolean => isNoEventsMode(state) || !getEventList(state).length

export const getEventList = (state: MemberPageState): DetailedEvent[] => {
  const eventsToGet = state.tab === Tabs.PAST ? EventsList.PAST : EventsList.UPCOMING

  return isDemoMode(state) && state.events[eventsToGet].length === 0
    ? state.demoEvents[eventsToGet]
    : state.events[eventsToGet]
}
