import React from 'react';
import s from '../../CartItem.scss';
import {CartItemDataHook} from '../../CartItem';
import {ICartItem} from '../../../../../../../../types/app.types';

export const Price = ({item}: {item: ICartItem}) => {
  const {formattedComparePrice, formattedPrice, formattedPriceBeforeDiscount} = item.convertedPrices;

  const crossedOutPrice = formattedPriceBeforeDiscount || formattedComparePrice;
  return (
    <div className={s.prices}>
      {crossedOutPrice && (
        <span data-hook={CartItemDataHook.ComparePrice} className={s.comparePrice}>
          {crossedOutPrice}
        </span>
      )}
      {formattedPrice && (
        <span data-hook={CartItemDataHook.Price} className={s.price}>
          {formattedPrice}
        </span>
      )}
    </div>
  );
};
