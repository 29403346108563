import classNames from 'classnames'
import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {isRegistrationClosed, isWaitlistEnabled} from '@wix/wix-events-commons-statics'
import sc from '../../classes.scss'
import {LinkToPage} from '../../link-to-page'
import {useSettings} from '../../../../hooks/settings'
import settingsParams from '../../../../settingsParams'
import s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  event,
  roundedButton,
  hollowButton,
  width,
  height,
  borderRadius,
  onImage,
  roundedBorderRadius,
  navigateToPage,
  isMobile,
  responsiveButton,
  hidden,
  hasUrl,
  rsvpLabelVisible,
  settings,
}: RsvpButtonProps) => {
  const eventClosed = isRegistrationClosed(event)
  const waitlist = isWaitlistEnabled(event)
  const {get} = useSettings(settings)
  const text =
    eventClosed && !waitlist ? get(settingsParams.registrationClosedButtonText) : get(settingsParams.oneButton)
  const title = rsvpLabelVisible ? get(settingsParams.RSVPTitle) : null

  const style = {
    minWidth: responsiveButton ? '100%' : width,
    minHeight: height,
    borderRadius: roundedButton ? roundedBorderRadius : borderRadius,
  }

  const className = hollowButton ? s.hollowButton : s.fullButton
  const onImageClassName = hollowButton ? s.onImageHollowButton : s.onImageFullButton

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigateToPage()
  }

  const buttonClassNames = classNames(s.button, sc.textLineHeight, className, {
    [onImageClassName]: onImage,
    [s.link]: hasUrl,
  })

  return (
    <div
      id={DH.rsvpButton}
      className={classNames(s.container, {[s.mobile]: isMobile, [s.responsive]: responsiveButton, [s.hidden]: hidden})}
    >
      {title && (
        <div className={classNames(s.title, {[s.onImageTitle]: onImage})} data-hook="ev-rsvp-button-title">
          {title}
        </div>
      )}
      {hasUrl && !hidden ? (
        <LinkToPage event={event} dataHook="ev-rsvp-button" className={buttonClassNames} style={style} allowExternal>
          {text}
        </LinkToPage>
      ) : (
        <button style={style} data-hook="ev-rsvp-button" className={buttonClassNames} onClick={onClick}>
          {text}
        </button>
      )}
    </div>
  )
}
