import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa'
import {classes} from './event-text.st.css'
import type {EventTextProps} from '.'

export const EventText: React.FC<EventTextProps> = ({extraClass, faded, large, children, dataHook}) => (
  <Text
    data-hook={dataHook}
    children={children}
    typography={TextTypography.runningText}
    className={classNames(
      classes.root,
      {[classes.faded]: faded, [classes.large]: large},
      extraClass ? extraClass : null,
    )}
  />
)
