import {getTimeZoneId} from '@wix/wix-events-commons-statics'
import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {getFullLocale} from '../../../../../commons/selectors/environment'
import {internalNavigate} from '../../actions/navigation'
import {getEventOrders} from '../../selectors/event'
import {DetailedEvent, MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {MobileOrderList as MobileOrderListPresentation} from './mobile-order-list'

export interface MobileOrderListProps extends MobileOrderListRuntimeProps, WithTranslation {}

interface MobileOrderListRuntimeProps {
  event: DetailedEvent
  orders: wix.events.ticketing.Order[]
  locale: string
  timezone: string
  internalNavigate: typeof internalNavigate
}

const mapRuntime = ({state, actions}: MemberPageContext): MobileOrderListRuntimeProps => ({
  event: state.navigation.routeParams.event,
  orders: getEventOrders(state.navigation.routeParams.event),
  locale: getFullLocale(state),
  timezone: getTimeZoneId(state.navigation.routeParams.event.event),
  internalNavigate: actions.internalNavigate,
})

export const MobileOrderList = connect<{}, MobileOrderListRuntimeProps>(mapRuntime)(
  withTranslation()(MobileOrderListPresentation),
)
