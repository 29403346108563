import _ from 'lodash';
import { Dispatch, createStore, Reducer, applyMiddleware, Middleware } from 'redux';
import { AppState } from './createStore';
import { showLogger, logger } from './loggerMiddleware';

const UPDATE_STORE = 'update_store';

export function setupStore(initialState: AppState, controllerDispatch: Dispatch) {
  const store = createStore(widgetRootReducer, createStoreEnhancer(controllerDispatch));
  const updateStore = (appState: AppState) => store.dispatch({ type: UPDATE_STORE, appState });
  updateStore(initialState);

  return {
    store,
    updateStore,
  };
}

function createStoreEnhancer(controllerDispatch: Dispatch) {
  const dispatchMiddleware: Middleware = () => (next) => (action) => {
    if (action.type !== UPDATE_STORE) {
      controllerDispatch(action);
    }

    return next(action);
  };

  const middlewares = [dispatchMiddleware];

  if (showLogger) {
    middlewares.push(logger('widget store'));
  }

  return applyMiddleware(...middlewares);
}

const widgetRootReducer: Reducer = (widgetState = { stateVersion: {} }, action): AppState => {
  if (action.type === UPDATE_STORE) {
    const controllerState = action.appState;

    if (isEditorXPreviewMode(controllerState)) {
      return keepCompClassName(controllerState, widgetState);
    }

    return getNextStateWithVersioningOptimization(controllerState, widgetState);
  }

  return widgetState;
};

function getNextStateWithVersioningOptimization(controllerState: AppState, widgetState: AppState) {
  let nextState = { ...widgetState };

  // Updating only if the controller version is newer - otherwise we want to keep the
  // current reference to avoid rerenders.
  for (const sliceKey of Object.keys(controllerState.stateVersion)) {
    const controllerVersion = controllerState.stateVersion[sliceKey];
    const widgetVersion = widgetState.stateVersion[sliceKey] ?? -1;

    if (controllerVersion > widgetVersion) {
      nextState = _.setWith(nextState, sliceKey, _.get(controllerState, sliceKey), _.clone);
      nextState.stateVersion = { ...nextState.stateVersion, [sliceKey]: controllerVersion };
    }
  }

  return nextState;
}

function isEditorXPreviewMode({ platformParams: { isEditorX, viewMode } }: AppState) {
  return isEditorX && viewMode === 'Preview';
}

/*
  Since the class name comes from the widget, the controller's initial state dpesn't have it.
  After the initialization, the controller have it but the widget doesn't because it takes the state from the controller
  That's why we need to double fallback
*/
function keepCompClassName(controllerState: AppState, widgetState: AppState): AppState {
  return {
    ...widgetState,
    ...controllerState,
    platformParams: {
      ...controllerState.platformParams,
      compClassName: controllerState.platformParams.compClassName || widgetState.platformParams?.compClassName,
    },
  };
}
