import * as React from 'react';
import {classes} from './pagination.st.css';
import s from '../ProductList.scss';
import {Pagination as PaginationUI} from 'wix-ui-tpa';
import {PaginationTypeName} from '../../../../types/galleryTypes';

export interface PaginationProps {
  handlePagination(page: number): void;
  currentPage: number;
  totalPages: number;
  paginationMode: PaginationTypeName;
  showFirstLastNavButtons: boolean;
  linkForAllPages?: string[];
  isPaginationForSeoEnabled?: boolean;
}

export const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const {
    currentPage,
    paginationMode,
    showFirstLastNavButtons,
    totalPages,
    handlePagination,
    linkForAllPages,
    isPaginationForSeoEnabled,
  } = props;

  const getHref = (i) => {
    if (!isPaginationForSeoEnabled || !linkForAllPages?.length) {
      return undefined;
    }
    return linkForAllPages[i - 1];
  };

  return (
    <div data-hook="product-list-pagination" className={s.pagination}>
      <PaginationUI
        className={classes.root}
        currentPage={currentPage}
        data-hook={`product-list-tpa-pagination`}
        onChange={({event, page}) => {
          event.stopPropagation();
          event.preventDefault();
          handlePagination(page);
        }}
        paginationMode={paginationMode}
        showFirstLastNavButtons={showFirstLastNavButtons}
        showFirstPage
        showLastPage
        totalPages={totalPages}
        pageUrl={getHref}
      />
    </div>
  );
};
