import {getEventTitle, hasSeatingPlan, isTicketed} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isAboutSectionVisible, isMapVisible, isSecondRegistrationButtonVisible} from '../../selectors/event'
import {isMembersVisibleWithGuests} from '../../selectors/members'
import {isMoreDatesButtonVisible, isMoreDatesLabelVisible} from '../../selectors/occurrences'
import {isPaidPlansVisible} from '../../selectors/paid-plans'
import {getContentAlignment, isScheduleVisible, isSocialShareVisible} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {isGroupActivityVisible} from '../../selectors/groups'
import {EventDetails as Presentation} from './event-details'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntimeToProps = ({
  state,
  actions: {handleRSVP, detailsPageLoaded, openSeatingPlan},
}: DetailsPageAppProps): EventDetailsRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const paidPlansVisible = isPaidPlansVisible(state)
  const groupVisible = isGroupActivityVisible(state)
  const membersVisible = isMembersVisibleWithGuests(state)
  const membersDividerVisible =
    membersVisible &&
    (isAboutSectionVisible(state) || isSecondRegistrationButtonVisible(state) || !paidPlansVisible || groupVisible)
  const groupsDividerVisible = groupVisible && !isAboutSectionVisible(state) && !membersDividerVisible
  const ticketedEvent = isTicketed(event)
  const scheduleVisible = isScheduleVisible(state)

  return {
    eventTitle: getEventTitle(event),
    componentSettings,
    contentAlignment: getContentAlignment(componentSettings),
    ticketedEvent,
    membersVisible,
    membersDividerVisible,
    mapVisible: isMapVisible(state),
    aboutSectionVisible: isAboutSectionVisible(state),
    showPaidPlans: paidPlansVisible,
    handleRSVP,
    detailsPageLoaded,
    openSeatingPlan,
    hasTickets: ticketedEvent && Boolean(state.tickets.length),
    socialShareVisible: isSocialShareVisible(componentSettings),
    scheduleVisible,
    moreDatesVisible: isMoreDatesLabelVisible(state),
    moreDatesButtonVisible: isMoreDatesButtonVisible(state),
    groupVisible,
    groupsDividerVisible,
    withSeatingPlan: hasSeatingPlan(state.event),
  }
}

export const EventDetails = withTranslation()(
  connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntimeToProps)(Presentation),
)

export * from './interfaces'
