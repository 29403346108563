import type {PageDates} from '@wix/events-types'

export const UPDATE_DATES = 'UPDATE_DATES'

export const updateDates =
  ({events, common}: PageDates) =>
  dispatch =>
    dispatch({
      type: UPDATE_DATES,
      payload: {
        events,
        common,
      },
    })
