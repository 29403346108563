import {TFunction} from '@wix/yoshi-flow-editor'
import {isSocialShareVisible} from '../../../../selectors/list-settings'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {Share as SharePresentation} from './share'

export interface ShareProps {
  t: TFunction
  event: wix.events.Event
}
export const Share = renderIfVisible<ShareProps>(SharePresentation, isSocialShareVisible)
