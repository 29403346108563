import React from 'react';
import {Dropdown, DropdownOptionProps, FloatingDropdown} from 'wix-ui-tpa';
import {classes, st} from './Sort.st.css';
import {classes as floatingClasses} from './FloatingSort.st.css';
import s from './Sort.scss';
import {sortOptions} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {ISortingOption} from '../../../types/galleryTypes';

export enum DataHook {
  SelectedIOptionIcon = 'selected-option-icon',
  SortDropdown = 'sort-dropdown',
  FloatingSortDropdown = 'sort-floating-dropdown',
}

interface SortProps {
  isFloatingView?: boolean;
}

class SortComp extends React.Component<SortProps & IGalleryGlobalProps & IProvidedTranslationProps> {
  private readonly getOptions = (sortingOptions: ISortingOption[]) => {
    return sortingOptions.map((o) => ({
      isDisabled: false,
      isSelectable: true,
      id: o.id,
      value: this.getOptionText(o),
    }));
  };

  private readonly getOptionText = (o) => {
    return this.props.t(o.titleKey);
  };

  private readonly onSelect = (option: DropdownOptionProps) => {
    const sortingOption = sortOptions.find((o) => o.id === option.id);
    this.props.globals.sortProducts(sortingOption);
  };

  private readonly onExpandedChange = (isExpanded) => {
    if (!isExpanded) {
      return;
    }

    this.props.globals.sendSortClickBiEvent();
  };

  public render = () => {
    const {selectedSort, sortingOptions, sortingOptionsWithoutDefault} = this.props.globals;

    const {isFloatingView = false} = this.props;

    return isFloatingView ? (
      <div className={s.FloatingSelector}>
        <FloatingDropdown
          //todo: add new translations for label
          label={'Sort by:'}
          value={selectedSort.id}
          onExpandedChange={this.onExpandedChange}
          data-hook={DataHook.FloatingSortDropdown}
          className={st(floatingClasses.sort, s.selector)}
          onChange={this.onSelect}
          placement={'bottom'}
          options={this.getOptions(sortingOptionsWithoutDefault)}
          displayBlock
        />
      </div>
    ) : (
      <Dropdown
        onExpandedChange={this.onExpandedChange}
        data-hook={DataHook.SortDropdown}
        className={st(classes.sort, s.selector)}
        initialSelectedId={selectedSort.id}
        onChange={this.onSelect}
        placement={'bottom'}
        options={this.getOptions(sortingOptions)}
      />
    );
  };
}

export const Sort = withGlobals(withTranslations()(SortComp));
