import { FieldOrigin, FieldType, Field, PublicInfoFieldId } from '../../types';

interface BiProfileSaveData {
  titleChanged: boolean;
  nameChanged: boolean;
  systemFieldsChanged: string;
  customFieldsChanged: string;
  contactsFieldsChanges: string;
  socialFieldsChanged: string;
}

const getChangedData = (
  id: Field['id'],
  type: FieldType,
  label: Field['label'],
  biData: Partial<BiProfileSaveData>,
  fieldOrigin: FieldOrigin,
) => {
  const isFieldOriginCustom = fieldOrigin === FieldOrigin.CUSTOM;

  if (id === PublicInfoFieldId.DISPLAY_NAME) {
    return { nameChanged: true };
  }

  if (id === PublicInfoFieldId.TITLE) {
    return { titleChanged: true };
  }

  if (fieldOrigin === FieldOrigin.SYSTEM) {
    return {
      systemFieldsChanged: biData.systemFieldsChanged
        ? `${biData.systemFieldsChanged}, ${label}`
        : label,
    };
  }

  if (fieldOrigin === FieldOrigin.CONTACT) {
    return {
      contactsFieldsChanges: biData.contactsFieldsChanges
        ? `${biData.contactsFieldsChanges}, ${label}`
        : label,
    };
  }

  if (isFieldOriginCustom && type !== FieldType.SOCIAL) {
    return {
      customFieldsChanged: biData.customFieldsChanged
        ? `${biData.customFieldsChanged}, ${label}`
        : label,
    };
  }

  if (isFieldOriginCustom && type === FieldType.SOCIAL) {
    return {
      socialFieldsChanged: biData.socialFieldsChanged
        ? `${biData.socialFieldsChanged}, ${label}`
        : label,
    };
  }
};

export const getBiProfileSaveData = (changedFields: Field[] = []) =>
  changedFields.reduce<Partial<BiProfileSaveData>>(
    (biProfileSaveData, field) => {
      const { id, fieldOrigin, type, label } = field;
      return {
        ...biProfileSaveData,
        ...getChangedData(id, type, label, biProfileSaveData, fieldOrigin),
      };
    },
    {},
  );
