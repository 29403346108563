import classNames from 'classnames'
import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import {handleKeyPressEnterOrSpace} from '@wix/panda-js-utils'
import s from './expand-icon.scss'
import {ExpandIconProps} from './interfaces'

export const ExpandIcon = ({expanded, right, onClick, extraClass, mobile, href, dataHook, t}: ExpandIconProps) => (
  <a
    href={href}
    onClick={href ? null : onClick}
    onKeyPress={href ? null : handleKeyPressEnterOrSpace(onClick)}
    tabIndex={0}
    className={s.root}
    aria-label={t('a11y.expandEvent')}
    data-hook={dataHook}
  >
    <ChevronDown
      size={mobile ? '14px' : '16px'}
      viewBox="8 7 9 10"
      className={classNames(s.icon, {[s.up]: expanded, [s.right]: right}, extraClass ? extraClass : '')}
    />
  </a>
)
