import {bindActionCreators} from 'redux'
import {resetFilter, setFilter, setLocation, toggleTag} from './filter'
import {checkMembersAreaInstallation} from './installed-apps'
import {loadCurrentUser, logout, promptLogin} from './member'
import {
  closeModal,
  toggleFiltersModal,
  toggleLoginDrawer,
  togglePersonalAgendaModal,
  toggleScheduleItemModal,
} from './modals'
import {navigateToEventDetails} from './navigate'
import {createBookmark, deleteBookmark, getBookmarks, readDescriptionClicked, viewPersonalAgenda} from './schedule'

export const getExportedActions = store =>
  bindActionCreators(
    {
      toggleTag,
      setFilter,
      setLocation,
      toggleScheduleItemModal,
      toggleFiltersModal,
      togglePersonalAgendaModal,
      resetFilter,
      readDescriptionClicked,
      navigate: navigateToEventDetails,
      checkMembersAreaInstallation,
      promptLogin,
      logout,
      loadCurrentUser,
      getBookmarks,
      createBookmark,
      deleteBookmark,
      toggleLoginDrawer,
      viewPersonalAgenda,
      closeModal,
    },
    store.dispatch,
  )
