import {AnyAction} from 'redux'
import {PageDates} from '@wix/events-types'
import {UPDATE_DATES} from '../actions/dates'

export const dates = (state: PageDates = {} as PageDates, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_DATES:
      return {
        ...state,
        events: {
          ...state.events,
          ...action.payload.events,
        },
        common: {
          ...state.common,
          ...action.payload.common,
        },
      }
    default:
      return state
  }
}
