import React from 'react';
import {Button} from 'wix-ui-tpa';
import {classes} from './CheckoutButton.st.css';
import classnames from 'classnames';
import {useControllerProps} from '../../../ControllerContext';
import settingsParams from '../../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';

export enum CheckoutButtonDataHook {
  button = 'CheckoutButtonDataHook.button',
}

export interface CheckoutButtonProps {
  disabled?: boolean;
}

export function CheckoutButton({disabled}: CheckoutButtonProps) {
  const {get: getSettings} = useSettings();
  const accessibilityEnabled = useControllerProps().host.accessibilityEnabled;
  const {navigateToCheckout} = useControllerProps().navigationStore;

  return (
    <Button
      className={classnames(classes.checkoutButton)}
      data-hook={CheckoutButtonDataHook.button}
      disabled={disabled}
      onClick={() => navigateToCheckout({accessibilityEnabled})}
      upgrade>
      <span className={classes.checkoutButtonLabel}>{getSettings(settingsParams.CART_BUTTON_TEXT)}</span>
    </Button>
  );
}
