import {isFirstTimeCompleted} from '../../../../commons/selectors/site-settings'
import {WidgetSettingsState} from '../reducers'
import {getEvents} from './events'

interface GeneralFieldsParams {
  state: WidgetSettingsState
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const getGeneralFields = ({state, multiEventWidget, calendar, recurringFilter}: GeneralFieldsParams) => {
  const eventCount = getEvents({state, multiEventWidget, calendar, recurringFilter}).length

  return {
    is_demo_data: isFirstTimeCompleted(state.siteSettings),
    is_first_event: eventCount <= 1,
    total_events_count: eventCount,
  }
}
