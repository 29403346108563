import { DATA_BINDING, WIX_CODE } from '@wix/app-definition-ids'

export const GFPP_ACTION_IDS = {
  SETTINGS_ACTION: 'settings',
  MANAGE_CONTENT_ACTION: 'manage-content',
}
export const LOCALE_FOLDER = 'locale/data-binding'

const CM_SERVER_URL = '/_api/cm-server'

export const CLOUD_DATA_URL = '/_api/cloud-data'

export const PUBLISHING_PLUGIN_ID = 'publishing'

export const PUBLISHING_MODE = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
}

export const DEFAULT_PUBLISHING_MODE = PUBLISHING_MODE.DRAFT

export const DB_PANEL_BASENAME = `${CM_SERVER_URL}/dbsm-editor-app`

export const APP_DEF_ID = DATA_BINDING

export const APP_NAME = 'dbsm-editor-app'

export const LOCALE_FALLBACK_URL = `https://static.parastorage.com/services/dbsm-editor-app/1.329.0/${LOCALE_FOLDER}`

export const EXPERIMENTS_HOST = ''

export const EXPERIMENTS_SCOPE = WIX_CODE

export const AUTO_CMS_EDITOR_PANEL_BASENAME = `${CM_SERVER_URL}/auto-cms`

export const FORMAT_TYPES = {
  DATETIME: 'datetime',
}
