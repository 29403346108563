import {getWrappedComponent} from '../../../appConfig';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {SliderGallery} from '../../../sliderGallery/components/SliderGallery/SliderGallery';
import {sliderGlobalPropsStrategy} from '../../../sliderGallery/sliderGlobalStrategy';
import {USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME} from '../../../constants';

export default getWrappedComponent({
  Component: SliderGallery,
  withStyleStrategy: withStyles,
  withExperimentAttribute: USE_YOSHI_STYLE_PARAMS_STANDARD_ATTRIBUTE_NAME,
  globalPropsStrategy: sliderGlobalPropsStrategy,
  sliderGallery: true,
});
