import {isDateTbd} from '@wix/wix-events-commons-statics'
import {isMobile, isSite} from '../../../../../../commons/selectors/environment'
import {isListLayout, getComponentConfig} from '../../../selectors/settings'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {
  getDateOnImageSize,
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isNarrow,
  isSquareImage,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {DesktopItem as DesktopItemPresentation} from './desktop-item'
import {ItemOwnProps, ItemStateProps} from './interfaces'
import {MobileItem as MobileItemPresentation} from './mobile-item'

const mapRuntime = ({state, host: {dimensions}, isRTL}: AppProps, {event}: ItemOwnProps): ItemStateProps => {
  const ribbon = getRibbonData(state, event)

  return {
    showRibbon: ribbon.visible,
    showImage: isImageVisible(state),
    showDate: isDateVisible(state),
    showMembers: isMembersVisible(state, event),
    opened: isItemOpened(state, event.id),
    listShowFullDate: isFullDateVisible(state),
    listShowLocation: isLocationVisible(state),
    listShowDescription: isDescriptionVisible(state),
    showVenue: isVenueVisible(state, dimensions) && !ribbon.status,
    additionalComponentsHidden: isAdditionalComponentsHidden(state, event.id),
    listLayout: isListLayout(getComponentConfig(state)),
    squareImage: isSquareImage(state, isMobile(state) || isNarrow(state, dimensions)),
    mobile: isMobile(state),
    rtl: isRTL,
    dateSize: getDateOnImageSize(state),
    isSite: isSite(state),
    scheduleTbd: isDateTbd(event),
  }
}

export const DesktopItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(DesktopItemPresentation)

export const MobileItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(MobileItemPresentation)

export * from './interfaces'
