import { createReducer } from '../common/reducerUtils';
import {
  setAddressInputValue,
  setDeliveryAddressField,
  setErrorVisibility,
  setFieldError,
  setAddressInputError,
  setSelectedAddress,
  toggleAllErrors,
  initAddressForm,
  validateForm,
  setTpaEstimateInlineError,
} from './addressForm.actions';
import { EMPTY_ADDRESS } from '../../core/constants';
import {
  SetAddressInputErrorPayload,
  SetAddressInputValuePayload,
  SetDeliveryAddressFieldPayload,
  SetErrorVisibilityPayload,
  SetSelectedAddressPayload,
  SetTpaEstimateInlineErrorPayload,
  ToggleAllErrorsPayload,
  ValidateFormPayload,
} from './addressForm.actions.types';
import { ActionHandlers, Address, AddressFormFields, ValidateAddressReason } from '@wix/restaurants-client-logic';
import _, { noop } from 'lodash';
import { InlineError } from '../../components/MainPage/components/AddressInformation/AddressInformation.helper';

export type DeliveryFormField =
  | 'addressInput'
  | 'apt'
  | 'timingOption'
  | 'addressLine2'
  | 'contactlessDineInInputLabel'
  | 'curbsideAdditionalInfo'
  | 'location';

interface AddressInformationFormState {
  errorsVisibility: Record<DeliveryFormField, boolean>;
  addressInputError: ValidateAddressReason | undefined;
  selectedAddressOption: Address;
  addressInputValue: string;
  fieldErrors: {
    apt: boolean;
  };
  isFormValid: boolean;
  tpaEstimateInlineError: InlineError | undefined;
}

const initialState: AddressInformationFormState = {
  addressInputError: undefined,
  errorsVisibility: {
    apt: false,
    addressInput: false,
    timingOption: false,
    addressLine2: false,
    contactlessDineInInputLabel: false,
    location: false,
    curbsideAdditionalInfo: false,
  },
  selectedAddressOption: EMPTY_ADDRESS,
  addressInputValue: '',
  tpaEstimateInlineError: undefined,
  fieldErrors: {
    apt: false,
  },
  isFormValid: false,
};

const handlers: ActionHandlers<AddressInformationFormState> = {
  [toggleAllErrors.toString()]: (state, { value }: ToggleAllErrorsPayload) => {
    return {
      ...state,
      errorsVisibility: {
        apt: value,
        addressInput: value,
        timingOption: value,
        addressLine2: value,
        location: value,
        contactlessDineInInputLabel: value,
        curbsideAdditionalInfo: value,
      },
    };
  },
  [initAddressForm.toString()]: () => {
    return initialState;
  },
  [setAddressInputValue.toString()]: (state, payload: SetAddressInputValuePayload) => {
    return {
      ...state,
      addressInputValue: payload.value,
      addressInputError: { type: 'invalid-address' },
      errorsVisibility: { ...state.errorsVisibility, addressInput: false },
      tpaEstimateInlineError: undefined,
      selectedAddressOption: {
        ...EMPTY_ADDRESS,
        apt: state.selectedAddressOption.apt,
        floor: state.selectedAddressOption.floor,
        entrance: state.selectedAddressOption.entrance,
        addressLine2: state.selectedAddressOption.addressLine2,
        label: state.selectedAddressOption.label,
      },
    };
  },
  [setErrorVisibility.toString()]: (state, payload: SetErrorVisibilityPayload) => {
    const newErrorVisibility = _.cloneDeep(state.errorsVisibility);
    newErrorVisibility[payload.error] = payload.value;
    return {
      ...state,
      errorsVisibility: newErrorVisibility,
    };
  },
  [setAddressInputError.toString()]: (state, payload: SetAddressInputErrorPayload) => {
    return {
      ...state,
      addressInputError: payload.validateAddressReason,
    };
  },
  [setFieldError.toString()]: (state, payload: SetErrorVisibilityPayload) => {
    return {
      ...state,
      fieldErrors: {
        ...state.fieldErrors,
        [payload.error]: payload.value,
      },
    };
  },
  [setSelectedAddress.toString()]: (state, payload: SetSelectedAddressPayload) => {
    const newAddress: Address = { ...payload.address };

    if (newAddress.number === '') {
      delete newAddress.number;
    }

    const currentAddress = state.selectedAddressOption;
    const addressFieldsToKeep: (keyof AddressFormFields | 'addressLine2' | 'label')[] = [
      'apt',
      'floor',
      'entrance',
      'addressLine2',
      'label',
    ];

    if (!payload.shouldNotKeepFields) {
      addressFieldsToKeep.forEach((field) => {
        payload.address[field] === undefined ? (newAddress[field] = currentAddress[field]) : noop();
      });
    }

    return {
      ...state,
      selectedAddressOption: newAddress,
      tpaEstimateInlineError: undefined,
    };
  },
  [setDeliveryAddressField.toString()]: (state, payload: SetDeliveryAddressFieldPayload) => {
    const newAddress = { ..._.cloneDeep(state.selectedAddressOption), [payload.addressField]: payload.value };

    return {
      ...state,
      selectedAddressOption: newAddress,
      tpaEstimateInlineError: undefined,
    };
  },
  [setTpaEstimateInlineError.toString()]: (state, payload: SetTpaEstimateInlineErrorPayload) => {
    return {
      ...state,
      tpaEstimateInlineError: payload.value,
    };
  },
  [validateForm.toString()]: (state, payload: ValidateFormPayload) => {
    const errors = state.errorsVisibility;
    const isMultiLocation = payload.isMultiLocation;

    switch (payload.dispatchType) {
      case 'delivery':
        return {
          ...state,
          isFormValid: !(
            errors.addressInput ||
            errors.timingOption ||
            state.tpaEstimateInlineError ||
            (isMultiLocation ? errors.location : errors.addressLine2 || errors.apt)
          ),
        };
      case 'dine-in':
        return {
          ...state,
          isFormValid: !(errors.contactlessDineInInputLabel || errors.timingOption),
        };
      case 'takeout':
        return {
          ...state,
          isFormValid: !(
            errors.timingOption ||
            errors.curbsideAdditionalInfo ||
            (isMultiLocation ? errors.location : false)
          ),
        };
      default:
        return {
          ...state,
          isFormValid: false,
        };
    }
  },
};

const reducer = createReducer<AddressInformationFormState>(initialState, handlers);

export default reducer;
