import classNames from 'classnames'
import React from 'react'
import {BUTTONS_STYLE} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import sc from '../../classes.scss'
import {Spinner} from '../../spinner'
import {useSettings} from '../../../../hooks/settings'
import settingsParams from '../../../../settingsParams'
import s from './today-button.scss'
import {TodayButtonProps} from '.'

export const TodayButton = ({onClick, dataHook, isMobile, loading, todayButtonStyle, settings}: TodayButtonProps) => {
  const {t} = useTranslation()
  const {get} = useSettings(settings)
  const todayButtonText = get(settingsParams.todayButtonText)

  const getStyleClassNames = () => ({
    [s.full]: [BUTTONS_STYLE.FULL, BUTTONS_STYLE.FULL_ROUNDED].includes(todayButtonStyle),
    [s.rounded]: [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(todayButtonStyle),
  })

  return (
    <div className={classNames(s.containerr, {[s.mobile]: isMobile})}>
      {loading ? <Spinner className={s.loader} diameter={20} calendarButton /> : null}
      <button
        data-hook={dataHook}
        onClick={() => onClick()}
        type="button"
        className={classNames(s.button, sc.ellipsis, getStyleClassNames(), {
          [s.mobile]: isMobile,
          [s.loading]: loading,
        })}
      >
        {todayButtonText || t('layout.calendar.monthly.controls.today')}
      </button>
    </div>
  )
}
