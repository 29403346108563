import {SeatingPlan, Type} from '@wix/ambassador-seating-v1-seating-plan/types'

export const getPlaceInfoForOrderSummary = (seatId: string, seatingPlan: SeatingPlan) => {
  const [sectorId, elementId] = seatId.split('-')
  const section = seatingPlan.sections.find(item => item.id === Number(sectorId))
  const element = section.elements.find(item => item.id === Number(elementId))
  const place = element.places.find(item => item.id === seatId)

  return {
    section,
    element,
    place,
    area: place.elementType === Type.AREA,
    table: [Type.TABLE, Type.ROUND_TABLE].includes(place.elementType),
  }
}
