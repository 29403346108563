export default class WarmupCache {
  #warmupData
  constructor(warmupData) {
    this.#warmupData = warmupData
  }

  getSchemas() {
    return this.#warmupData.get('schemas')
  }

  setSchemas(data) {
    this.#warmupData.set('schemas', data)
  }

  getDataStore() {
    return this.#warmupData.get('dataStore')
  }

  setDataStore(data) {
    this.#warmupData.set('dataStore', data)
  }
}
