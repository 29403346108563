import {withTranslation} from '@wix/yoshi-flow-editor'
import {getContentAlignment} from '../../../../../../selectors/settings'
import {getComponentSettings} from '../../../../../../selectors/component'
import {DetailsPageAppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/context'
import {PricingOptionsPriceOwnProps, PricingOptionsPriceRuntimeProps} from './interfaces'
import {PricingOptionPrice as Presentation} from './pricing-option-price'

export const mapRuntime = ({state}: DetailsPageAppProps): PricingOptionsPriceRuntimeProps => {
  const settings = getComponentSettings(state)

  return {
    contentAlignment: getContentAlignment(settings),
  }
}

export const PricingOptionPrice = withTranslation()(
  connect<PricingOptionsPriceOwnProps, PricingOptionsPriceRuntimeProps>(mapRuntime)(Presentation),
)
export * from './interfaces'
