import {isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {State} from '../types'
import {isGroupVisibilityEnabled} from './settings'
import {getComponentSettings} from './component'
import {isOrderSuccess, isThankYouMessageYes} from './navigation'

export const isGroupsInstalled = (state: State) => state.groups.installed

export const isGroupActivityVisible = (state: State) =>
  hasGroup(state) && isGroupVisibilityEnabled(getComponentSettings(state)) && !isWithoutRegistration(state.event)

export const showGroupCard = (state: State) => hasGroup(state) && (isOrderSuccess(state) || isThankYouMessageYes(state))

export const hasGroup = (state: State) => !!state.groups.group

export const getUpdatesCount = (state: State) => state.groups.updates

export const getMembersCount = (state: State) => state.groups.group.membersCount

export const getGroupTitle = (state: State) => state.groups.group.title

export const getGroupId = (state: State) => state.groups.group.id

export const getGroupSlug = (state: State) => state.groups.group.slug

export const getGroupImage = (state: State): wix.common.Image => {
  const logo = state.groups.group.details?.logo
  if (logo) {
    return {
      id: logo.mediaId,
      width: logo.width,
      height: logo.height,
    }
  }
}
