/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface MinusProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const Minus: React.FC<MinusProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M12.159 7.2h-8.319c-0.442 0-0.48 0.358-0.48 0.8s0.038 0.8 0.48 0.8h8.319c0.442 0 0.481-0.358 0.481-0.8s-0.038-0.8-0.481-0.8z" />
  </svg>
);
Minus.displayName = 'Minus';
/* tslint:enable */
/* eslint-enable */
