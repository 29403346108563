import {updateDates} from '../../../../commons/actions/dates'
import {getComponentData} from '../actions/component'
import {GET_EVENTS} from '../actions/events'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case getComponentData.fulfilled.toString():
    case GET_EVENTS.SUCCESS: {
      store.dispatch(updateDates(action.payload.dates))
      break
    }
    default:
      break
  }
  next(action)
}
