/* eslint-disable react/button-has-type */
import React from 'react';
import s from './LoadMoreButton.scss';
import classNames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

interface LoadMoreButtonProps {
  onClick: any;
  isLoadNext: boolean;
}

class LoadMoreButtonComp extends React.Component<
  LoadMoreButtonProps & IGalleryGlobalProps & IProvidedTranslationProps
> {
  public render() {
    const {isLoadNext} = this.props;
    return (
      <button
        data-hook={isLoadNext ? 'load-more-button' : 'load-previous-button'}
        className={classNames(s.loadMore, {[s.isMobile]: this.props.globals.shouldShowMobile})}
        onClick={this.props.onClick}>
        {isLoadNext ? this.props.t('loadMoreButtonText') : this.props.t('loadPreviousButtonText')}
      </button>
    );
  }
}

export const LoadMoreButton = withGlobals(withTranslations()(LoadMoreButtonComp));
