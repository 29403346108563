import {TFunction} from '@wix/yoshi-flow-editor'
import {openMonthlyCalendarEvent, closeMonthlyCalendarPopup} from '../../../../../../actions/calendar-layout'
import {getDayMonthText, getEventsForDay, getSelectedDate} from '../../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventList as MonthlyCalendarEventListPresentation} from './monthly-calendar-event-list'

export interface MonthlyCalendarEventListOwnProps {
  t: TFunction
}

export interface MonthlyCalendarEventListRuntimeProps {
  dateText: string
  events: ExtendedEvent[]
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MonthlyCalendarEventListProps
  extends MonthlyCalendarEventListRuntimeProps,
    MonthlyCalendarEventListOwnProps {}

const mapRuntime = (context: AppProps): MonthlyCalendarEventListRuntimeProps => {
  const selectedDay = getSelectedDate(context.state)
  return {
    events: getEventsForDay(context.state, selectedDay),
    dateText: getDayMonthText(context.state, selectedDay),
    openMonthlyCalendarEvent: (eventId: string) => context.actions.openMonthlyCalendarEvent(eventId),
    closeMonthlyCalendarPopup: () => context.actions.closeMonthlyCalendarPopup(),
  }
}

export const MonthlyCalendarEventList = connect<MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps>(
  mapRuntime,
)(MonthlyCalendarEventListPresentation)
