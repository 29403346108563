import s from '../../GalleryApp/GalleryApp.scss';
import {Sort} from '../../Sort/Sort';
import React from 'react';
import classNames from 'classnames';

export const SortInLayout = ({isFloatingView = false}: {isFloatingView?: boolean}) => {
  return (
    <div className={classNames({[s.sortRow]: !isFloatingView}, {[s.sortRowFloatView]: isFloatingView})}>
      <Sort isFloatingView={isFloatingView} />
    </div>
  );
};
