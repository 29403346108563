import React from 'react';
import styles from './CartSubtotal.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { useTranslation } from '@wix/yoshi-flow-editor';

export interface CartSubtotal {
  subtotal?: string;
  taxes?: string;
  deliveryFee?: string;
  tip?: string;
  total?: string;
  showTotal?: boolean;
  isEstimatedDeliveryFee?: boolean;
  minOrderPrice?: string;
}

const CartSubtotal: React.FC<CartSubtotal> = ({
  subtotal,
  taxes,
  deliveryFee,
  tip,
  total,
  showTotal,
  isEstimatedDeliveryFee,
  minOrderPrice,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={minOrderPrice ? styles.rowMinPrice : styles.row}>
        <Text typography="p2-m" className={styles.title}>
          {t('cart_summary_subtotal')}
        </Text>
        <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalSubtotal}>
          {subtotal}
        </Text>
      </div>
      {Boolean(minOrderPrice) && (
        <div className={styles.row}>
          <Text data-hook={dataHooks.cartSubtotalMinOrder} typography="p2-s-secondary">
            {t('Order_Online_MinimumOrder_Amount_Label', { amount: minOrderPrice })}
          </Text>
        </div>
      )}
      {taxes && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('cart_summary_tax')}
          </Text>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTax}>
            {taxes}
          </Text>
        </div>
      )}

      {(deliveryFee || isEstimatedDeliveryFee) && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('checkout_main_order_delivery_fee')}
          </Text>
          <Text typography="p2-m" className={styles.price}>
            {isEstimatedDeliveryFee ? (
              <Text data-hook={dataHooks.cartSubtotalDeliveryFeeFromText} typography="p2-s-secondary">
                {t('checkout_main_order_delivery_fee_varies_label')}
              </Text>
            ) : (
              <span data-hook={dataHooks.cartSubtotalDeliveryFee}>{deliveryFee}</span>
            )}
          </Text>
        </div>
      )}

      {tip && (
        <div className={styles.row}>
          <Text typography="p2-m" className={styles.title}>
            {t('cart_summary_tip')}
          </Text>
          <Text typography="p2-m" className={styles.price} data-hook={dataHooks.cartSubtotalTip}>
            {tip}
          </Text>
        </div>
      )}

      {showTotal && (
        <div className={`${styles.row} ${styles.total}`}>
          <Text typography="p2-l" className={styles.title}>
            {t('cart_summary_total')}
          </Text>
          <Text typography="p2-l" className={styles.price} data-hook={dataHooks.cartSubtotalTotal}>
            {total}
          </Text>
        </div>
      )}
    </div>
  );
};

CartSubtotal.displayName = 'CartSubtotal';

export default CartSubtotal;
