import React, { FC } from 'react';
import classNames from 'classnames';

import Button, { ButtonClickHandler } from '../Button';
import ChatIcon, { ChatIconProps } from './ChatIcon';
import styles from './ChatButton.scss';

interface ChatButtonProps extends ChatIconProps {
  primaryButtonClass: string;
  secondaryButtonClass: string;
  type: 'text' | 'icon' | 'animated';
  text: string;
  isFollowing: boolean;
  isDisabled?: boolean;
  onClick: ButtonClickHandler;
}

const ChatButton: FC<ChatButtonProps> = ({
  className,
  primaryButtonClass,
  secondaryButtonClass,
  type,
  size,
  text,
  isFollowing,
  isDisabled,
  onClick,
}) => {
  const isText = type === 'text';
  const isAnimated = type === 'animated';
  const isAnimatedFollowing = isAnimated && isFollowing;

  const buttonClasses = [styles.button, className];

  const chatButtonClassName =
    isAnimatedFollowing || isText
      ? classNames(...buttonClasses, styles.textButton, primaryButtonClass)
      : classNames(...buttonClasses, styles.iconButton, secondaryButtonClass);

  const textClassName = classNames(
    styles.text,
    isAnimated ? styles.animated : '',
  );

  return (
    <Button
      className={chatButtonClassName}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <div className={textClassName}>
        <div className={styles.verticalAlign}>{text}</div>
      </div>
      <ChatIcon className={styles.icon} size={size} />
    </Button>
  );
};

export default ChatButton;
