const dateFormats = {
  SHORT_DATE: 'SHORT_DATE',
  MEDIUM_DATE: 'MEDIUM_DATE',
  LONG_DATE: 'LONG_DATE',
  FULL_DATE: 'FULL_DATE',
  SHORT_DATE_TIME: 'SHORT_DATE_TIME',
  LONG_DATE_TIME: 'LONG_DATE_TIME',
  FULL_DATE_TIME: 'FULL_DATE_TIME',
  MEDIUM_TIME_12: 'MEDIUM_TIME_12',
  MEDIUM_TIME_24: 'MEDIUM_TIME_24',
  LONG_TIME_12: 'LONG_TIME_12',
  LONG_TIME_24: 'LONG_TIME_24',
  HOUR_ONLY: 'HOUR_ONLY',
  MINUTE_ONLY: 'MINUTE_ONLY',
  YEAR_ONLY: 'YEAR_ONLY',
  MONTH_ONLY: 'MONTH_ONLY',
  SHORT_MONTH_ONLY: 'SHORT_MONTH_ONLY',
  DAY_ONLY: 'DAY_ONLY',
}

const setOfDateFormatsWithTime = new Set([
  dateFormats.SHORT_DATE_TIME,
  dateFormats.LONG_DATE_TIME,
  dateFormats.FULL_DATE_TIME,
  dateFormats.MEDIUM_TIME_12,
  dateFormats.MEDIUM_TIME_24,
  dateFormats.LONG_TIME_12,
  dateFormats.LONG_TIME_24,
  dateFormats.HOUR_ONLY,
  dateFormats.MINUTE_ONLY,
])

const dataFormattingOptions = {
  [dateFormats.SHORT_DATE]: {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  },
  [dateFormats.MEDIUM_DATE]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [dateFormats.LONG_DATE]: { day: 'numeric', month: 'long', year: 'numeric' },
  [dateFormats.FULL_DATE]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
  },
  [dateFormats.SHORT_DATE_TIME]: {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  [dateFormats.LONG_DATE_TIME]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [dateFormats.FULL_DATE_TIME]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    weekday: 'long',
    timeZoneName: 'short',
  },
  [dateFormats.MEDIUM_TIME_12]: {
    minute: 'numeric',
    hour: 'numeric',
    hour12: true,
  },
  [dateFormats.MEDIUM_TIME_24]: {
    minute: 'numeric',
    hour: 'numeric',
    hour12: false,
  },
  [dateFormats.LONG_TIME_12]: {
    minute: 'numeric',
    hour: 'numeric',
    second: 'numeric',
    hour12: true,
  },
  [dateFormats.LONG_TIME_24]: {
    minute: 'numeric',
    hour: 'numeric',
    second: 'numeric',
    hour12: false,
  },
  [dateFormats.HOUR_ONLY]: { hour: 'numeric' },
  [dateFormats.MINUTE_ONLY]: { minute: 'numeric' },
  [dateFormats.YEAR_ONLY]: { year: 'numeric' },
  [dateFormats.MONTH_ONLY]: { month: 'long' },
  [dateFormats.SHORT_MONTH_ONLY]: { month: 'short' },
  [dateFormats.DAY_ONLY]: { weekday: 'long' },
}

module.exports = ({ locale, timeZone } = {}) => {
  if (!locale) {
    throw new Error('A "locale" parameter is required for wixFormatting')
  }

  return {
    formatDateTime(
      date,
      format,
      { locale: localeOverride, timeZone: timeZoneOverride } = {},
    ) {
      const options = {
        ...dataFormattingOptions[format],
        timeZone: timeZoneOverride || timeZone,
      }
      return new Intl.DateTimeFormat(localeOverride || locale, options).format(
        date,
      )
    },
    dateFormats,
  }
}

module.exports.dateFormats = dateFormats
module.exports.setOfDateFormatsWithTime = setOfDateFormatsWithTime
