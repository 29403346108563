import {createReducer} from '@reduxjs/toolkit'
import {IUser} from '@wix/yoshi-flow-editor'
import {logout, promptLogin, loadCurrentUser} from '../actions/member'

export interface MemberState {
  user: Partial<IUser>
  imageUrl: string
}

const initialState: MemberState = {
  user: null,
  imageUrl: null,
}

export const member = createReducer(initialState, builder => {
  builder
    .addCase(promptLogin.fulfilled, (state, {payload: userInfo}) => {
      if (userInfo) {
        const {user, imageUrl} = userInfo
        state.user = user
        state.imageUrl = imageUrl
      }
    })
    .addCase(logout.fulfilled, state => {
      state.user = null
      state.imageUrl = null
    })
    .addCase(loadCurrentUser.fulfilled, (state, {payload: userInfo}) => {
      if (userInfo) {
        const {user, imageUrl} = userInfo
        state.user = user
        state.imageUrl = imageUrl
      } else {
        state.user = null
        state.imageUrl = null
      }
    })
})
