import {getEventId, isNoResponseEnabled} from '@wix/wix-events-commons-statics'
import {changeResponse} from '../../../actions/rsvp'
import {getMemberRsvpId} from '../../../selectors/member-rsvp'
import {shouldNavigateToForm} from '../../../selectors/rsvp'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {ConfirmRsvp as Presentation} from './confirm-rsvp'
import {ConfirmRsvpOwnProps, ConfirmRsvpRuntimeProps} from './interfaces'

const mapRuntimeToProps = ({
  state,
  actions: {closeNativeModal, updateRsvpStatus, deleteRsvp, navigateToForm},
}: DetailsPageAppProps): ConfirmRsvpRuntimeProps => ({
  closeModal: closeNativeModal,
  updateRsvpStatus,
  rsvpId: getMemberRsvpId(state),
  noResponseEnabled: isNoResponseEnabled(state.event),
  eventId: getEventId(state.event),
  deleteRsvp,
  shouldNavigateToForm: shouldNavigateToForm(state),
  navigateToForm,
  changeResponse,
})

export const ConfirmRsvp = connect<ConfirmRsvpOwnProps, ConfirmRsvpRuntimeProps>(mapRuntimeToProps)(Presentation)
