import {State} from '../types'
import {getInvoice} from './placed-order'

export const isCouponsAccepted = (state: State) => state.checkoutOptions.options.acceptCoupons

export const isUpgradeNeededForCheckout = (state: State) => {
  const {
    grandTotal: {amount},
  } = getInvoice(state)

  return !state.checkoutOptions.options.premiumServices && Number(amount) > 0
}
