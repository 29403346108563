import {isMobile} from '../../../../../../commons/selectors/environment'
import {MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {ShareButton as ShareButtonPresentation} from './share-button'
import {ShareButtonOwnProps, ShareButtonRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  shareEvent: actions.shareEvent,
})

export const ShareButton = connect<ShareButtonOwnProps, ShareButtonRuntimeProps>(mapRuntime)(ShareButtonPresentation)
export * from './interfaces'
