import React from 'react'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {hasImage} from '@wix/wix-events-commons-statics'
import {EventImage} from '../../../event-image'
import s from './image.scss'
import {ImageProps} from '.'

export const Image = ({event, containerWidth = '100%', squareImage = false}: ImageProps) => (
  <div
    data-hook={hasImage(event) ? DH.listImage : DH.listImagePlaceholder}
    className={classNames(squareImage ? s.squareRatio : s.wideRatio)}
    style={{
      width: containerWidth,
    }}
  >
    <EventImage event={event} backgroundFallback />
  </div>
)
