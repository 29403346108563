import { PagingMode, Schema } from '@wix/wix-data-schema-types'

interface Params {
  schema?: Schema
  cursorPagingPreferred?: boolean
}

export default ({ schema, cursorPagingPreferred }: Params) => {
  const collectionSupportsOffsets = schema?.pagingMode?.includes(
    PagingMode.Offset,
  )
  const collectionSupportsCursors = schema?.pagingMode?.includes(
    PagingMode.Cursor,
  )
  const collectionSupportsOnlyCursors =
    schema?.pagingMode?.length === 1 && collectionSupportsCursors
  const collectionSupportsBothPagingModes =
    collectionSupportsOffsets && collectionSupportsCursors

  const pagingMode =
    collectionSupportsOnlyCursors ||
    (collectionSupportsBothPagingModes && cursorPagingPreferred)
      ? PagingMode.Cursor
      : PagingMode.Offset

  return pagingMode
}
