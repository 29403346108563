import React, {useMemo} from 'react';
import s from './ProductsCounter.scss';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {withGlobals} from '../../../globalPropsContext';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export enum ProductsCounterDataHook {
  Counter = 'ProductsCounterDataHook.Counter',
}

export const ProductsCounter: React.FunctionComponent<IGalleryGlobalProps> = withGlobals(
  withTranslations()(({globals, t}: IGalleryGlobalProps & IProvidedTranslationProps) => {
    const {totalProducts} = globals;

    const numberToShow = useMemo(() => {
      return totalProducts === 1
        ? t('productsCounterSingular')
        : t('productsCounterPlural', {numProducts: totalProducts});
    }, [t, totalProducts]);

    return (
      <div className={s.counter} data-hook={ProductsCounterDataHook.Counter}>
        {numberToShow}
      </div>
    );
  })
);
